import Event from "@/class/Event";

export default [
  {
    path: '/test/vueMap',
    name: 'home',
    component: () => import('@/component/mapV2/hotelMapV2.vue'),
    props: (route) => {
      return {
        event: new Event({
          "name": "San Francisco event name",
          "icon": "#",
          "dateStart": "2020-12-09",
          "dateEnd": "2020-12-11",
          "venue": {
            "address": "San Francisco City",
            "lat": 37.7923897,
            "lon": -122.4104443,
            "city": {
              "name": "San Francisco"
            },
          },

          "hotels": [
            {
              "name": "Сан-Франциско",
              "url": "#",
              "price": "523",
              "currency": "USD",
              "lat": "37.7745295",
              "lng": "-122.4154155"
            }
          ],
          poi:[
            {
              "place": "Сан-Франциско",
              "type": "cool",
              "url": "#",
              "description": "desc",
              "lat": "37.7649295",
              "lng": "-122.4294155"
            }
          ],
        })
      }
    }
  },
]
