import i18next from 'i18next'
import intervalPlural from 'i18next-intervalplural-postprocessor';


let files = {}
files.en = require.context('@lang/en/', true, /(^.*)(json$)/)
files.ru = require.context('@lang/ru/', true, /(^.*)json$/)
files.de = require.context('@lang/de/', true, /(^.*)json$/)
files.client = {
  ru: require.context('@clientLang/ru/', true, /(^.*)json$/)
}

let _languages = ['en', 'ru', 'de'];
let translate = {};

let i, lang, len, temp;

for (i = 0, len = _languages.length; i < len; i++) {
  lang = _languages[i];
  temp = {};
  translate[lang] = {};
  files[lang].keys().forEach(function(key) {
    temp[key.slice(2).split('.').slice(0, -1).join('.')] = files[lang](key)
  });
  if (files.client[lang])
    files.client[lang].keys().forEach(key => {
      let index = key.slice(2).split('.').slice(0, -1).join('.')
      if (temp[index]) temp[index] = {...temp[index], ...files.client[lang](key)}
      else temp[index] = files.client[lang](key)
    })
  translate[lang] = temp
}


let namespace = require('./namespace');

i18next.use(intervalPlural).init({
  ns: namespace,
  defaultNS: 'common',
  debug: false,
  keySeparator: false,
  fallbackLng: Language.getName(),
  saveMissing: true,
  resources: translate
})

export default i18next;
