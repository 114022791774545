import isEmpty from 'lodash/isEmpty';
// custom
const getKey = function (obj) {

  // is empty
  if (isEmpty(obj) ){
    return '';
  }

  /*
  * 1 градус по экватору равен примерно 111 км.
  * 3 знака = 0,110метров * 1,41 ~= 150метров
  **/
  return obj.adults
     + '_' + obj.children.length
     + '_' + parseFloat(obj.from.lat).toFixed(3) + '_' + parseFloat(obj.from.lon).toFixed(3)
     + '_' + parseFloat(obj.to.lat).toFixed(3) + '_' + parseFloat(obj.to.lon).toFixed(3)
     + '_' + obj.start_date + ' '+ obj.end_date
    // + '_' + obj.currency
    // + '_' + obj.language;
};

/////////////////////////////////
const getStoreStructure = function () {
  return {
    loadStatus: "CREATED", // LOAD, SUCCESS, ERROR, CREATED???, ABORT???
    request   : null, // TODO: use request
    data      : null
  };
};

import Vue from 'vue';
import sendRequest from "@/function/global/sendRequest";

import prepare from "@/function/flight/flightPrepare";

let flightListMergeId = {};
let flightListMerge = [];


export default  {
  state: {
    flightList: {},
  },
  getters: {
    flightList_getLoadStatus(state) {
      return function(search){
        let value = state['flightList'][  getKey(search.request) ];
        if(!value) {
          value = getStoreStructure(search);
        }
        return value.loadStatus;
      };
    },
    flightList_getData(state) {
      return function(search){
        let value = state['flightList'][  getKey(search.request) ];
        if(!value) {
          value = getStoreStructure(search);
        }
        return value.data;
      };
    },
  },
  mutations: {
    flightList_setLoadStatus (state, {key, loadStatus}) {
      state['flightList'][ key ].loadStatus = loadStatus;
    },
    flightList_setData (state, {key, data}) {
      if(!state['flightList'][ key ].data ){
        state['flightList'][ key ].data       = data;
      } else {
        state['flightList'][ key ].data = state['flightList'][ key ].data.concat(data)
      }
    }
  },
  actions: {
    flightList_clearAll({ state, getters, commit, dispatch }, search) {
      flightListMergeId  = {};
      flightListMerge    = [];
      state.flightList   = {};
    },
    flightList_abort({ state, getters, commit, dispatch }, search) {
      // TODO
    },
    /*
    search : {
      request: {
        from: {
          lat         : 1,
          lon         : 1,
        },
        to: {
          lat         : 1,
          lon         : 1,
        },
        adults      : 1,
        start_date  : "2018-07-22",
        end_date    : "2018-07-24",
        children    : []
      }
    }
    **/
    flightList_load({ state, getters, commit, dispatch }, search) {

      return new Promise((resolve, reject) => {

        let key = getKey(search.request);

        // TODO: fix
        // if(state['flightList'][ key ].loadStatus === 'LOAD'){
        //   return;
        // }

        Vue.set(state['flightList'], key, getStoreStructure());

        commit('flightList_setLoadStatus', {key: key, loadStatus: 'LOAD'});

        sendRequest('WhiteLabel::FlightListGet', search.request)
          .then((data) => {

            // if (data.error !== 'ERROR_NOT') {
            //   throw new Error('flight error');
            // }
            let flightList = prepare(data.flight_list, search);

            commit('flightList_setData'      , {key: key, data: flightList});
            commit('flightList_setLoadStatus', {key: key, loadStatus: 'SUCCESS'});

            resolve(flightList);
          })
          .catch(e => {
            commit('flightList_setData'      , {key: key, data: null});
            commit('flightList_setLoadStatus', {key: key, loadStatus: 'ERROR'});
            console.warn("ERROR", e);
            reject();
          });
      })

    }
  }
}
