import Vue from 'vue';

import '@/core/init/setting.js';   // global unit
import '@/core/init/const.js';   // global unit
import store from '@/core/init/store';

import '@/core/init/unit.js';   // global unit
import '@/core/init/moment.js'; // global moment
import '@/core/init/share.js'; // global share

import App from '@/core/init/layout.vue';
import router from '@/core/router';

// disable service worker
// import '@/core/init/registerServiceWorker';

import '@/core/init/mapLeafletFix.js'; // global map
import '@/core/init/mapLeafletJs.js'; // global map

import vuetify from '@/plugins/vuetify' // path to vuetify export


import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents);

import Notifications from 'vue-notification'
// Use Notify
Vue.use(Notifications)

import VCalendar from 'v-calendar';
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
  // ...,                // ...other defaults
});

// X3
Vue.config.productionTip = false;


// localization
let i18next;

if (CONST.language.localTranslate) {
  i18next = require('@/core/lang/local').default;
} else {
  i18next = require('@/core/lang/locize').default;
}
//

import VueI18Next from '@panter/vue-i18next'

Vue.use(VueI18Next);
let i18n = new VueI18Next(i18next);

// Directives
import vClickOutside from 'v-click-outside';
// https://github.com/ndelvalle/v-click-outside
Vue.use(vClickOutside);

import attachDirectives from '@/function/global/directivesInit'
attachDirectives(Vue)

// Add tooltip
// https://github.com/Akryum/v-tooltip
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'
Vue.directive('tooltip', VTooltip)
Vue.directive('close-popover', VClosePopover)
Vue.component('v-popover', VPopover)


// import VueAnalytics from 'vue-analytics'
// Vue.use(VueAnalytics, {
//   id: 'UA-115740172-1',
//   checkDuplicatedScript: true,
//   debug: {
//     // enabled: true, // default value
//     // trace: true, // default value
//     // sendHitTask: true // default value
//   }
// })

// https://stackoverflow.com/questions/3420004/access-parent-url-from-iframe
let domain = (window.location != window.parent.location)
  ? document.referrer
  : document.location.href;
function getHost(url) {
  var a = document.createElement('a');
  a.href = url;
  return a.hostname;
}
domain = getHost(domain);

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent }  from "firebase/analytics";
import { getFirestore, doc, setDoc, collection} from "firebase/firestore/lite"; 

global.Analytic = (function(){
  
  const firebaseConfig = {
    apiKey: "AIzaSyCtHET46WP6nkvO5-j69VqNwLoDa_ZCs_o",
    authDomain: "funtam-widget.firebaseapp.com",
    projectId: "funtam-widget",
    storageBucket: "funtam-widget.appspot.com",
    messagingSenderId: "1000631014372",
    appId: "1:1000631014372:web:4b62c73b8205d1d1f7adf1",
    measurementId: "G-YG7VRE9L0Y"
  };
  
  window.dataLayer = window.dataLayer || [];
  function gtag(){window.dataLayer.push(arguments);}

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const dbFirestore = getFirestore(app);

  var sendGtag = (name, data = {}) => {}
  
  global.gtag_user_id = 0;
  global.gtag_session_id = 0;
  gtag('get', 'G-YG7VRE9L0Y', 'client_id', (u_id) => { global.gtag_user_id = u_id});
  gtag('get', 'G-YG7VRE9L0Y', 'session_id',  (s_id) => { global.gtag_session_id = s_id});

  const removeUndefinedValuesFromObject = (obj) => {
    var clean_object = {};
    Object.keys(obj).forEach((key) => {
        if (obj[key] === undefined || typeof obj[key] === 'function' || obj[key] === null){ return; }
        
        if(typeof obj[key] === 'object' || Array.isArray(obj[key])){
          clean_object[key] = removeUndefinedValuesFromObject(obj[key]);
        } else {
          clean_object[key] = obj[key];
        }
    });
    return clean_object;
  };

  const sendFirestore = (doc_type = 'custom _event', data = {value: 1}) => {

    let log_document = {
      user_id: global.gtag_user_id,
      session_id: global.gtag_session_id,
      payload: removeUndefinedValuesFromObject(data),
      doc_type: doc_type,
      created_at: new Date(),
      timestamp: Date.now()
    };
    /*if(doc_type === 'custom _event') {
      console.trace();
    }*/
    setDoc(doc(collection(dbFirestore, "widget")), log_document)
    .then(result => {})
    .catch(error => {});
  }

  var logFirebaseEvent = (name, data = {}) => {
    sendFirestore(name, data);
    logEvent(analytics, name, data);
  };

  const eventsList = {
    'common': {
      'load': 'Load',
      'click': 'Click'
    },
    'filter' : {
      'filter_panel_open': 'Filter panel open',
      'filter_content_type_change': 'Filter conten type change',
      'filter_category_change': 'Filter category change',
      'filter_venye_category_change': 'Filter venue category change',
      filter_price_cange: 'Filter price change',
      filter_apply: 'Filter apply',
      filter_clear: 'Filter clear',
      filter_close: 'Filter close',
      quick_filter_date_open: 'Quick filter date open',
      quick_filter_date_select: 'Quick filter date select',
      quick_filter_date_apply: 'Quick filter date apply',
    },
    list: {
      list_scroll: 'List scroll',
      list_card_open: 'List card open',
      list_recommended_card_open: 'List recommended card open',
      list_transition: 'List transition',
      list_select_tab: 'List select tab',
    },
    map: {
      map_interaction: 'Map interaction',
      map_popup_open: 'Map popup open',
      map_direction_show: 'Map direction show',
      map_card_open: 'Map card open',
      map_popup_transition: 'Map popup transition',
      map_search_focus: 'Map search focus',
      map_search_options: 'Map search options',
      map_search_option_select: 'Map search option select',
      map_open_poi_popup: 'Map open POI popup',
      map_open_hotel_popup: 'Map open hotel popup',
      map_hotel_popup_transition: 'Map hotel popup transition',
      map_poi_popup_transition: 'Map hotel popup transition',
    },
    card: {
      card_image_scroll: 'Card image scroll',
      card_description_open: 'Card description open',
      card_direction_show: 'Card direction show',
      card_list_transition: 'Card list transition',
      card_map_transition: 'Card map transition',
      card_gettransfer_transition: 'Card gettransfer transition',
      card_minimap_open: 'Card minimap open',
    },
    mobiles: {
      mobile_open_pulse: 'Mobile open pulse',
      mobile_open_map: 'Mobile open map',
      mobile_app_promo_open: 'Mobile app promo open',
    },
    app: {
      mobile_app_download_popup: 'Mobile app download popup',
      mobile_app_download: 'Mobile app download',
      mobile_app_continue_in_browser: 'Mobile app continue in browser',
      mobile_app_overlay_click: 'Mobile app overlay click',
      desktop_download_app: 'Desktop download app',
    },
    links:{
      main_site_open: 'Main site open',
      custom_url_open: 'Custom URL open',
      share_app_download: 'Share app download',
      share_browser_continue: 'Share browser continue',
      button_share_click: 'Button share click'
    }
  };

  const cardOpenFromOptions = {
    map: "map",
    list: "list",
    share: "share"
  }

  logFirebaseEvent(eventsList.common.load);

  return {
    sendFirestoreData: sendFirestore, 
    logFirebaseEvent: logFirebaseEvent,
    send: sendGtag,
    eventsList: eventsList,
    cardOpenFromOptions: cardOpenFromOptions
  }

})();
Vue.use(Analytic);

Vue.directive('log', {

  inserted: function (el, binding, vnode) {
    switch (true) {
      case binding.modifiers.click:
        el.addEventListener('click', ()=>{
          //Analytic.logFirebaseEvent(Analytic.eventsList.common.click,{'name': binding.value.name, 'data': binding.value.data});
          //Analytic.send( binding.value.name, binding.value.data);
          }, false
        );
        break;
    }
  }
});


global.Vue = Vue;
global.VueApp = new Vue({
  store,
  router,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app');

