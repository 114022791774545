<template>
  <nav class="offer-nav offer-nav--column" :class="navMenuClass">
    <ul class="offer-nav__list">
      <!--<li class="offer-nav__item" v-if="isEnable('logo')"  v-log.click="{name: 'Logo'}">
        <a class="offer-nav__box" @click="reload">
          <i v-if="eventLogo" class="offer-nav__icon offer-nav__icon--event" :style="{'background-image': `url('${eventLogo}')`}"></i>
          <i v-else class="offer-nav__icon offer-nav__icon--iw"></i>
        </a>
      </li>-->
      <li class="offer-nav__item" v-if="isEnable('hotels')" v-log.click="{name: 'Service Hotel'}">
        <router-link class="offer-nav__box" :to="{ path: '/offer/map-hotel', query: $router.firstQuery}">
          <svg-hotel class="offer-nav__icon offer-nav__icon--hotel"/>
          {{ $t('map:Lodging') }}
        </router-link>
      </li>
      <li class="offer-nav__item" v-if="isEnable('packages')" v-log.click="{name: 'Service Packages'}">
        <router-link class="offer-nav__box" :to="{ path: '/offer/package-and-map-hotel', query: $router.firstQuery}">
          <svg-package class="offer-nav__icon offer-nav__icon--package"/>
          {{ $t('map:Packages') }}
        </router-link>
      </li>
      <li class="offer-nav__item" v-if="isEnable('events')"  v-log.click="{name: 'Service Events'}">
        <router-link class="offer-nav__box" :to="{ path: '/offer/poi', query: $router.firstQuery}">
          <svg-ticket class="offer-nav__icon offer-nav__icon--ticket"/>
          {{ $t('map:Events') }}
        </router-link>
      </li>
      <li class="offer-nav__item" v-if="isEnable('packagesNotMap')" v-log.click="{name: 'Service Packages (not map)'}">
        <router-link class="offer-nav__box" :to="{ path: '/offer/package', query: $router.firstQuery}" >
          <svg-package class="offer-nav__icon offer-nav__icon--package"/>
          {{ $t('map:Packages') }}
        </router-link>
      </li>
      <li class="offer-nav__item" v-if="false && isEnable('tickets')"  v-log.click="{name: 'Service Tickets'}">
        <div class="offer-nav__box">
          <svg-ticket class="offer-nav__icon offer-nav__icon--ticket"/>
          {{ $t('map:Tickets') }}
        </div>
      </li>
      <li class="offer-nav__item" v-if="isEnable('transfer')"  v-log.click="{name: 'Service Transfer'}">
        <a class="offer-nav__box" href="https://.gettransfer.com/en" target="_blank">
          <svg-transfer class="offer-nav__icon offer-nav__icon--transfer"/>
          {{ $t('map:Transfer') }}
        </a>
      </li>
      <li class="offer-nav__item" v-if="isEnable('visa2')" v-log.click="{name: 'Service Visa'}">
        <a class="offer-nav__box" href="https://partners..com/visa-service/" target="_blank">
          <svg-visa class="offer-nav__icon offer-nav__icon--visa"/>
          {{ $t('map:Visa') }}
        </a>
      </li>
      <li class="offer-nav__item" v-if="isEnable('insurance2')"  v-log.click="{name: 'Service Insurance'}">
        <a class="offer-nav__box" href="https://partners..com/insurance/" target="_blank">
          <svg-umbrella class="offer-nav__icon offer-nav__icon--insurance"/>
          {{ $t('map:Insurance') }}
        </a>
      </li>
      <li class="offer-nav__item offer-nav__item--rental-cars" v-if="isEnable('rentCar')"  v-log.click="{name: 'Service RentCar'}">
        <a class="offer-nav__box offer-nav__box--rental-cars" href="https://www.rentalcars.com/?affiliateCode=" target="_blank">
          <svg-car class="offer-nav__icon offer-nav__icon--rental-cars"/>
          {{ $t('map:Rent a car') }}
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
  import svgHotel from './icons/hotel';
  import svgPackage from './icons/package';
  import svgTicket from './icons/ticket';
  import svgTransfer from './icons/transfer';
  import svgVisa from './icons/visa';
  import svgUmbrella from './icons/umbrella';
  import svgCar from './icons/car';

  export default {
    props: {
      packageLink: String,
    },
    components: {
      svgHotel,
      svgPackage,
      svgTicket,
      svgTransfer,
      svgVisa,
      svgUmbrella,
      svgCar,
    },
    methods: {
      reload() {
        this.$router.go();
      },
      isEnable(type) {
        if (!this.widgetSettings || !this.widgetSettings.navMenuMod) {
          return true;
        }
        if (this.widgetSettings.navMenuMod === 'disable') {
          return false;
        }

        return this.widgetSettings.navMenu.find((i) =>
          i.type === type
        );
      }
    },
    computed: {
      widgetSettings() {
        return this.$store.getters.widgetSettings;
      },
      navMenuClass(){
        let mode = 'default';
        if( this.widgetSettings && this.widgetSettings.navMenuMod){
          mode = this.widgetSettings.navMenuMod;
        }
        return 'offer-nav-' + mode;
      },
      eventLogo() {
        return this.$store.getters.widgetSetting('logo');
      }
    }
  };
</script>

<style lang="scss" scoped>
.offer-nav {
  display: flex;
  z-index: 10000;

  color: var(--font-color-nav-menu-text);
  /deep/ svg path{
    fill: var(--font-color-nav-menu-icon);
  }


  &.offer-nav-disable{
    display: none !important;
  }

  &__list {
    display: flex;

    margin: 0;
    padding: 0;

    list-style: none;

    // border: 1px solid #DADCE0;
  }

  &__item {
    padding: 0;


    .router-link-active {
      color: var(--font-color-nav-menu-text-active);
      /deep/ svg path{
        fill: var(--font-color-nav-menu-icon-active);
      }
    }


  }

  &__box {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    text-align: center;

    color: unset;

    &:hover {
      cursor: pointer;

      color: var(--font-color-nav-menu-text-hover);
      /deep/ svg path{
        fill: var(--font-color-nav-menu-icon-hover);
      }
    }
  }

  &__icon {
    margin-bottom: 5px;

    font-size: 20px;

    &--iw {
      width: 20px;
      height: 20px;

      border-radius: 50%;
      background: linear-gradient(224.51deg, #FF7536 12.56%, #FF7337 36.35%, #F0188B 85.05%);
    }
    &--event {
      width: 100%;
      height: 100%;

      background-size: 90% auto;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
}


/*.offer-nav--column {*/
.grid-md, .grid-lg {
  .offer-nav {
    &__list {
      flex-direction: column;
      overflow-y: auto;
      /* Hide scrollbar */
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }

    }

    &__box {
      width: 70px;
      height: 70px;
      min-height: 70px;

      border-bottom: 1px solid #DADCE0;

      font-size: 12px;
    }
  }
}

/*.offer-nav--row {*/
.grid-sm {
  .offer-nav {
    &__list {
      overflow-x: auto;
      overflow-y: auto;
      flex-direction: row;
    }

    &__item {
      border-bottom: unset;
      /*border-right: 1px solid #DADCE0;*/
    }

    &__box {
      width: 60px;
      height: 60px;
      min-height: 60px;

      font-size: 11px;
    }

    &__icon--rental-cars {
      width: 20px;
      height: 20px;

      background-size: 160% 160%;
    }
  }
}

.offer-nav-fix-logo-only .offer-nav {
  display: none !important;
}
</style>
