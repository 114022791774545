export default function (distanceName) {

  var _distanceInfo = {
    METRE: {
      name    : "METRE",
      fullName: 'metre',
      course  : 1,
    },
    KM: {
      name    : "KM",
      fullName: 'kilometer',
      course  : 0.001,
    },
    MI: {
      name    : "MI",
      fullName: 'mile',
      course  : 0.0006215,
    }
  };

  var _distanceEnum = {METRE: 'METRE', KM: 'KM', MI: 'MI'};
  // for(var key in _distanceInfo){
  //   _distanceEnum[key] = key;
  // }

  var _currentDistance = {
    name: distanceName
  }

  return {
    Enum: _distanceEnum,
    getDistance: function (distance, distanceUnit, newUnit) {
      newUnit = newUnit || _currentDistance.name;
      var dm = distance / _distanceInfo[distanceUnit].course * _distanceInfo[newUnit].course;
      return dm;
    },
    getName: function () {
      return _distanceInfo[_currentDistance.name].name;
    },
    set: function (distanceName) {
      _currentDistance.name = distanceName;
    }
  }
}