export default {  
  "AED": {
    symbol: "د.إ",
    format: (price) => 'د.إ' +  price
  },
  "AFN": {
    symbol: "؋",
    format: ""
  },
  "ALL": {
    symbol: "L",
    format: ""
  },
  "AMD": {
    symbol: "֏",
    format: ""
  },
  "ANG": {
    symbol: "ƒ",
    format: ""
  },
  "AOA": {
    symbol: "Kz",
    format: ""
  },
  "ARS": {
    symbol: "$",
    format: ""
  },
  "AUD": {
    symbol: "$",
    format: ""
  },
  "AWG": {
    symbol: "ƒ",
    format: ""
  },
  "AZN": {
    symbol: "₼",
    format: ""
  },
  "BAM": {
    symbol: "KM",
    format: ""
  },
  "BBD": {
    symbol: "$",
    format: ""
  },
  "BDT": {
    symbol: "৳",
    format: ""
  },
  "BGN": {
    symbol: "лв",
    format: ""
  },
  "BHD": {
    symbol: ".د.ب",
    format: ""
  },
  "BIF": {
    symbol: "FBu",
    format: ""
  },
  "BMD": {
    symbol: "$",
    format: ""
  },
  "BND": {
    symbol: "$",
    format: ""
  },
  "BOB": {
    symbol: "$b",
    format: ""
  },
  "BRL": {
    symbol: "R$",
    format: ""
  },
  "BSD": {
    symbol: "$",
    format: ""
  },
  // "BTC": {
  //   symbol: "฿",
  //   format: ""
  // },
  "BTN": {
    symbol: "Nu.",
    format: ""
  },
  "BWP": {
    symbol: "P",
    format: ""
  },
  "BYR": {
    symbol: "Br",
    format: ""
  },
  "BYN": {
    symbol: "Br",
    format: ""
  },
  "BZD": {
    symbol: "BZ$",
    format: ""
  },
  "CAD": {
    symbol: "$",
    format: ""
  },
  "CDF": {
    symbol: "FC",
    format: ""
  },
  "CHF": {
    symbol: "CHF",
    format: ""
  },
  "CLP": {
    symbol: "$",
    format: ""
  },
  "CNY": {
    symbol: "¥",
    format: ""
  },
  "COP": {
    symbol: "$",
    format: ""
  },
  "CRC": {
    symbol: "₡",
    format: ""
  },
  // "CUC": {
  //   symbol: "$",
  //   format: ""
  // },
  "CUP": {
    symbol: "₱",
    format: ""
  },
  "CVE": {
    symbol: "$",
    format: ""
  },
  "CZK": {
    symbol: "Kč",
    format: ""
  },
  "DJF": {
    symbol: "Fdj",
    format: ""
  },
  "DKK": {
    symbol: "kr",
    format: ""
  },
  "DOP": {
    symbol: "RD$",
    format: ""
  },
  "DZD": {
    symbol: "دج",
    format: ""
  },
  "EEK": {
    symbol: "kr",
    format: ""
  },
  "EGP": {
    symbol: "£",
    format: ""
  },
  // "ERN": {
  //   symbol: "Nfk",
  //   format: ""
  // },
  "ETB": {
    symbol: "Br",
    format: ""
  },
  // "ETH": {
  //   symbol: "Ξ",
  //   format: ""
  // },
  "EUR": {
    symbol: "€",
    format: ""
  },
  "FJD": {
    symbol: "$",
    format: ""
  },
  // "FKP": {
  //   symbol: "£",
  //   format: ""
  // },
  "GBP": {
    symbol: "£",
    format: ""
  },
  "GEL": {
    symbol: "GEL",
    format: ""
  },
  // "GGP": {
  //   symbol: "£",
  //   format: ""
  // },
  "GHC": {
    symbol: "₵",
    format: ""
  },
  "GHS": {
    symbol: "GH₵",
    format: ""
  },
  // "GIP": {
  //   symbol: "£",
  //   format: ""
  // },
  "GMD": {
    symbol: "D",
    format: ""
  },
  "GNF": {
    symbol: "FG",
    format: ""
  },
  "GTQ": {
    symbol: "Q",
    format: ""
  },
  "GYD": {
    symbol: "$",
    format: ""
  },
  "HKD": {
    symbol: "$",
    format: ""
  },
  "HNL": {
    symbol: "L",
    format: ""
  },
  "HRK": {
    symbol: "kn",
    format: ""
  },
  "HTG": {
    symbol: "G",
    format: ""
  },
  "HUF": {
    symbol: "Ft",
    format: ""
  },
  "IDR": {
    symbol: "Rp",
    format: ""
  },
  "ILS": {
    symbol: "₪",
    format: ""
  },
  // "IMP": {
  //   symbol: "£",
  //   format: ""
  // },
  "INR": {
    symbol: "₹",
    format: ""
  },
  "IQD": {
    symbol: "ع.د",
    format: ""
  },
  "IRR": {
    symbol: "﷼",
    format: ""
  },
  "ISK": {
    symbol: "kr",
    format: ""
  },
  // "JEP": {
  //   symbol: "£",
  //   format: ""
  // },
  "JMD": {
    symbol: "J$",
    format: ""
  },
  "JOD": {
    symbol: "JD",
    format: ""
  },
  "JPY": {
    symbol: "¥",
    format: ""
  },
  "KES": {
    symbol: "KSh",
    format: ""
  },
  "KGS": {
    symbol: "лв",
    format: ""
  },
  "KHR": {
    symbol: "៛",
    format: ""
  },
  "KMF": {
    symbol: "CF",
    format: ""
  },
  // "KPW": {
  //   symbol: "₩",
  //   format: ""
  // },
  "KRW": {
    symbol: "₩",
    format: ""
  },
  "KWD": {
    symbol: "KD",
    format: ""
  },
  "KYD": {
    symbol: "$",
    format: ""
  },
  "KZT": {
    symbol: "лв",
    format: ""
  },
  "LAK": {
    symbol: "₭",
    format: ""
  },
  "LBP": {
    symbol: "£",
    format: ""
  },
  "LKR": {
    symbol: "₨",
    format: ""
  },
  "LRD": {
    symbol: "$",
    format: ""
  },
  "LSL": {
    symbol: "M",
    format: ""
  },
  // "LTC": {
  //   symbol: "Ł",
  //   format: ""
  // },
  "LTL": {
    symbol: "Lt",
    format: ""
  },
  "LVL": {
    symbol: "Ls",
    format: ""
  },
  "LYD": {
    symbol: "LD",
    format: ""
  },
  "MAD": {
    symbol: "MAD",
    format: ""
  },
  "MDL": {
    symbol: "lei",
    format: ""
  },
  "MGA": {
    symbol: "Ar",
    format: ""
  },
  "MKD": {
    symbol: "ден",
    format: ""
  },
  "MMK": {
    symbol: "K",
    format: ""
  },
  "MNT": {
    symbol: "₮",
    format: ""
  },
  "MOP": {
    symbol: "MOP$",
    format: ""
  },
  "MRO": {
    symbol: "UM",
    format: ""
  },
  // "MRU": {
  //   symbol: "UM",
  //   format: ""
  // },
  "MUR": {
    symbol: "₨",
    format: ""
  },
  "MVR": {
    symbol: "Rf",
    format: ""
  },
  "MWK": {
    symbol: "MK",
    format: ""
  },
  "MXN": {
    symbol: "$",
    format: ""
  },
  "MYR": {
    symbol: "RM",
    format: ""
  },
  "MZN": {
    symbol: "MT",
    format: ""
  },
  "NAD": {
    symbol: "$",
    format: ""
  },
  "NGN": {
    symbol: "₦",
    format: ""
  },
  "NIO": {
    symbol: "C$",
    format: ""
  },
  "NOK": {
    symbol: "kr",
    format: ""
  },
  "NPR": {
    symbol: "₨",
    format: ""
  },
  "NZD": {
    symbol: "$",
    format: ""
  },
  "OMR": {
    symbol: "﷼",
    format: ""
  },
  "PAB": {
    symbol: "B/.",
    format: ""
  },
  "PEN": {
    symbol: "S/.",
    format: ""
  },
  "PGK": {
    symbol: "K",
    format: ""
  },
  "PHP": {
    symbol: "₱",
    format: ""
  },
  "PKR": {
    symbol: "₨",
    format: ""
  },
  "PLN": {
    symbol: "zł",
    format: ""
  },
  "PYG": {
    symbol: "Gs",
    format: ""
  },
  "QAR": {
    symbol: "﷼",
    format: ""
  },
  // "RMB": {
  //   symbol: "￥",
  //   format: ""
  // },
  "RON": {
    symbol: "lei",
    format: ""
  },
  "RSD": {
    symbol: "Дин.",
    format: ""
  },
  "RUB": {
    symbol: "₽",
    format: price => Number(price).toLocaleString() + ' ₽'
  },
  "RWF": {
    symbol: "R₣",
    format: ""
  },
  "SAR": {
    symbol: "﷼",
    format: ""
  },
  "SBD": {
    symbol: "$",
    format: ""
  },
  "SCR": {
    symbol: "₨",
    format: ""
  },
  "SDG": {
    symbol: "ج.س.",
    format: ""
  },
  "SEK": {
    symbol: "kr",
    format: ""
  },
  "SGD": {
    symbol: "$",
    format: ""
  },
  // "SHP": {
  //   symbol: "£",
  //   format: ""
  // },
  "SLL": {
    symbol: "Le",
    format: ""
  },
  "SOS": {
    symbol: "S",
    format: ""
  },
  "SRD": {
    symbol: "$",
    format: ""
  },
  "SSP": {
    symbol: "£",
    format: ""
  },
  "STD": {
    symbol: "Db",
    format: ""
  },
  // "STN": {
  //   symbol: "Db",
  //   format: ""
  // },
  "SVC": {
    symbol: "$",
    format: ""
  },
  "SYP": {
    symbol: "£",
    format: ""
  },
  "SZL": {
    symbol: "E",
    format: ""
  },
  "THB": {
    symbol: "฿",
    format: ""
  },
  "TJS": {
    symbol: "SM",
    format: ""
  },
  // "TMT": {
  //   symbol: "T",
  //   format: ""
  // },
  "TND": {
    symbol: "د.ت",
    format: ""
  },
  "TOP": {
    symbol: "T$",
    format: ""
  },
  "TRL": {
    symbol: "₤",
    format: ""
  },
  "TRY": {
    symbol: "₺",
    format: ""
  },
  "TTD": {
    symbol: "TT$",
    format: ""
  },
  // "TVD": {
  //   symbol: "$",
  //   format: ""
  // },
  "TWD": {
    symbol: "NT$",
    format: ""
  },
  "TZS": {
    symbol: "TSh",
    format: ""
  },
  "UAH": {
    symbol: "₴",
    format: ""
  },
  "UGX": {
    symbol: "USh",
    format: ""
  },
  "USD": {
    symbol: "$",
    format: ""
  },
  "UYU": {
    symbol: "$U",
    format: ""
  },
  "UZS": {
    symbol: "лв",
    format: ""
  },
  "VEF": {
    symbol: "Bs",
    format: ""
  },
  "VND": {
    symbol: "₫",
    format: ""
  },
  "VUV": {
    symbol: "VT",
    format: ""
  },
  "WST": {
    symbol: "WS$",
    format: ""
  },
  "XAF": {
    symbol: "FCFA",
    format: ""
  },
  // "XBT": {
  //   symbol: "Ƀ",
  //   format: ""
  // },
  "XCD": {
    symbol: "$",
    format: ""
  },
  "XOF": {
    symbol: "CFA",
    format: ""
  },
  "XPF": {
    symbol: "₣",
    format: ""
  },
  "YER": {
    symbol: "﷼",
    format: ""
  },
  "ZAR": {
    symbol: "R",
    format: ""
  },
  // "ZWD": {
  //   symbol: "Z$",
  //   format: ""
  // },
}
