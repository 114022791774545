
let language = window.navigator ? (window.navigator.language ||
  window.navigator.systemLanguage ||
  window.navigator.userLanguage) : '';
language = language.substr(0, 2).toLowerCase();

let gets = (() => {
  let a = window.location.search;
  let b = new Object();
  a = a.substring(1).split("&");
  for (let i = 0; i < a.length; i++) {
    let c = a[i].split("=");
    b[c[0]] = c[1];
  }
  return b;
})();


let widgetSetting = gets.widgetSettings ? JSON.parse( decodeURIComponent(gets.widgetSettings) ) : {};

//
let languageCurrency = {
  'en' : 'USD',
  'ru' : 'RUB',
}

let siteSetting = {
  language: localStorage.getItem('language') || widgetSetting.language || language || 'en',
  currency: '',
};

// detect currency
siteSetting.currency = localStorage.getItem('currency') || widgetSetting.currency || languageCurrency[siteSetting.language] || 'USD';

global.siteSetting = siteSetting;
