export const getwidgetSettingsDefault = () => ({
  navMenuMod: {
    categories: ['general', 'navMenu'],
    value: 'default',
  },
  navMenu: {
    categories: ['navMenu'],
    value: [
      {type: 'logo'},
      {type: 'hotels'},
      {type: 'packages'},
      // {type: 'packagesNotMap'},
      // {type: 'tickets'},
      {type: 'transfer'},
      {type: 'visa'},
      {type: 'insurance'},
      {type: 'rentCar'},
    ],
  },
  logo: {
    categories: ['general'],
    value: '',
  },
  brandLogo: {
    value: '',
  },
  fontColor: {
    value: '',
  },
  bgColor: {
    value: '',
  },
  startPage: {
    value: 'offer_mapHotel',
  },
  clearHotelFilter: {
    value: false,
  },
  mapZoom: {
    value: 14,
  },
  language: {
    value: '',
  },
  currency: {
    value: '',
  },
  disableYandex: {
    value: false
  },
  disableIntercom: {
    value: false
  },
  extraOptionGetTransfer: {
    value: true
  }
});

export default getwidgetSettingsDefault;
