<template>
  <div style="width: 100%; height: 100%;">
  <!--<div class="iw-head-banner">
    <div class="iw-head-table">

    
    <div class="iw-head-row">
        <div class="iw-head-cell-icon">
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.0038 29.9728C23.273 29.9728 29.9766 23.2692 29.9766 15C29.9766 6.73076 23.273 0.0272217 15.0038 0.0272217C6.73455 0.0272217 0.0310059 6.73076 0.0310059 15C0.0310059 23.2692 6.73455 29.9728 15.0038 29.9728Z" fill="url(#paint0_linear)"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.91057 12.5812V18.7047C8.91057 19.2424 8.4769 19.6761 7.9218 19.6761C7.34935 19.6761 6.91568 19.2424 6.91568 18.7047V12.5812C6.91568 12.0435 7.34935 11.6098 7.9218 11.6098C8.4769 11.6098 8.91057 12.0435 8.91057 12.5812ZM7.95649 7.75882C8.52894 7.75882 9.10138 8.20984 9.10138 8.92106C9.10138 9.71901 8.52894 10.17 7.95649 10.17C7.27997 10.17 6.70752 9.71901 6.70752 8.92106C6.70752 8.20984 7.27997 7.75882 7.95649 7.75882ZM19.2878 15.9639C19.4093 16.3108 19.496 16.6751 19.5827 17.0394C19.6521 16.6751 19.7215 16.2935 19.8256 15.9118L20.797 12.3904C20.9184 11.9394 21.248 11.6098 21.7337 11.6098C22.3062 11.6098 22.6878 11.9741 22.6878 12.4772C22.6878 12.5986 22.6531 12.72 22.6184 12.8588L20.7623 18.8088C20.6062 19.3292 20.1899 19.6761 19.6348 19.6761C19.0623 19.6761 18.646 19.3639 18.4378 18.8261L17.0674 15.2353C16.946 14.9404 16.894 14.6108 16.8072 14.3159C16.7378 14.6629 16.6511 14.9578 16.5297 15.27L15.1766 18.8261C14.9685 19.3812 14.5695 19.6761 14.0491 19.6761C13.5287 19.6761 13.1297 19.3812 12.9389 18.8088L11.0481 12.9802C11.0134 12.8588 10.996 12.7547 10.996 12.6333C10.996 12.1129 11.343 11.6098 12.0195 11.6098C12.4532 11.6098 12.8175 11.818 12.9736 12.3731L14.0317 15.9812C14.1358 16.3628 14.1879 16.6577 14.2572 17.022C14.344 16.6924 14.396 16.4149 14.5521 15.9812L15.7664 12.4945C15.9572 11.9394 16.3389 11.6098 16.894 11.6098C17.4491 11.6098 17.848 11.9394 18.0389 12.4945L19.2878 15.9639Z" fill="white"/>
            <defs>
            <linearGradient id="paint0_linear" x1="23.6646" y1="3.23545" x2="7.24134" y2="27.8446" gradientUnits="userSpaceOnUse">
            <stop stop-color="#F0188B"/>
            <stop offset="1" stop-color="#FF7536"/>
            </linearGradient>
            </defs>
            </svg>
        </div>
        <div class="iw-head-cell-text">
            <span class="iw-head-event-name" v-if="getEventName">{{ getEventName }} </span>
            <span class="iw-head-text iw-head-text-booking" v-show="elementVisible">{{ $t('map:Book your hotel through Booking.com, near the event, by the best price.') }}</span>
        </div>
        </div>
    </div>
  </div>-->
    <div class="iw3-table iw3-table-fs">
      <offer-navigation
          v-if="false"
        class="iw3-table-row iw3-header"
        packageLink="#"
      />
      <div class="iw3-table-row main">
        <div class="iw3-table-col" style="position: relative; width: 100%;height: 100%;">
          <div class="iw3-table-col-fix">
            <router-view/>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>


<script>
  import offerNavigation      from "@/component/offer/navigation/offerNavigation";
  import eventLoad            from "@/page/logic/eventLoader/eventLoadByWidget";
  
  export default {
    mixins: [
      eventLoad
    ],
    components: {
      offerNavigation,
    },
    name: 'region-content',
    async created() {
      await this.load();
      setTimeout(() => this.elementVisible = true, 5000)  
    },
    data() {
      return {
          currentEvent: {},
          elementVisible: false
      }
    },
    computed: {
      getEventName() {
        return this.currentEvent && this.currentEvent.name || '';
      }
    },
    methods: {
      load(){
        return this.eventLoad().then((event) => {
          // TODO: необходимо пофиксить старые долгие запросы
          this.currentEvent = event;
        });
      },
    }
  };
</script>



<style lang="scss" scoped>

  .iw3-table-col-fix{
    width: 100%;
    height: 100%;
  }


  .grid-sm {

    .iw-head-banner {
      position: absolute;
      left: 0;
      width: 100%;
      top: 0px;
      height: 70px;
      background: #ffffff;
      border-bottom: 1px solid #DFE0E4;
      box-sizing: border-box;

      .iw-head-table{
        display: table; table-layout: fixed; width: 100%;
        height: 100%;
      }

      .iw-head-row {
        display: table-row;
        width: 100%;
      }
      .iw-head-cell-icon {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        width: 70px;
      }
      .iw-head-cell-text {
        display: table-cell;
        vertical-align: middle;
        
      }
      .iw-head-event-name{
          display: block;
          margin-bottom: 5px;
        font-family: Helvetica;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: #4C4C4C;
      }
      .iw-head-text {
        font-family: Helvetica;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;

        color: #000000;
      }
    }
    .iw3-table {
      display: table;
      table-layout: fixed;
      width: 100%;
      height: 100%;
      /*padding-top: 70px;*/
    }
    .iw3-table-fs{
      width: 100%;
      height: 100%;
    }
    .iw3-table-row{
      display: table-row;
    }
    .iw3-table-col{
      display: table-cell;
    }
    .iw3-table-col-fix{
      position: absolute;
      left:0px;
      right:0px;
      top:0px;
      bottom:0px;
      width: auto;
      height: auto;
    }

    .iw3-header {
      margin-bottom: 7px;
      height: 60px;
      background: #fff;
    }
    .main {
      height: 100%;
      position: relative;
    }

    #filter {
      padding: 7px 5px 0;
    }
    .footer {
      height: 60px;
      background: #1AD871;
    }
  }
  // .offer-map__second-navigation {
  //   position: absolute;
  //   bottom: 20px;
  //   right: 10px;
  //   z-index: 1000;
  // }

  // .offer-map__popup {
  //   position: absolute;
  //   z-index: 1000;
  //   top: calc(50% - 100px);
  //   left: calc(50% - 100px);
  // }

  .offer-map__loading-bar {
    position: absolute;
    z-index: 1000;
  }
  .offer-nav-fix-disable .iw3-table {
    padding-left: 0px !important;
  }

  .offer-nav-fix-logo-only .iw3-table {
    padding-left: 0px !important;
  }

  .grid-md,
  .grid-lg {
    .iw3-table{
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      //padding-left: 70px;
      /*padding-top: 70px;*/
    }

    .iw3-header {
      position: absolute;
      left: 0;
      width: 70px;
      top: 70px;
      bottom: 0;
      background: #fff;
      border-right: 1px solid #DADCE0;

    }
    .main {height: 100%;  position: relative; height: 100%;}
    .footer {display: none; }
    .iw-head-banner {
      position: absolute;
      left: 0;
      width: 100%;
      top: 0px;
      height: 70px;
      background: #ffffff;
      border-bottom: 1px solid #DFE0E4;
      box-sizing: border-box;
      
      .iw-head-table{
        display: table; table-layout: fixed; width: 100%;
        height: 100%;
      }

      .iw-head-row {
        display: table-row;
        width: 100%;
      }
      .iw-head-cell-icon {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        width: 70px;
      }
      .iw-head-cell-text {
        display: table-cell;
        vertical-align: middle;
        padding-left: 20px;        
      }
      .iw-head-event-name{
          display: block;
          margin-bottom: 5px;
        font-family: Helvetica;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: #4C4C4C;
      }
      .iw-head-text {
          font-family: Helvetica;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 18px;

          color: #000000;
      }
    }
  }


  // это работает как глобальный класс
  .theme-default {
    .main{color: yellow; background: #2c3e50;} // это приватный класс
  }
</style>
