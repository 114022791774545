
import {Loader, LoaderOptions} from 'google-maps';
let myLoaderOption = {
  language: Language.getName(),
  libraries: ['places'],
}

const loader = new Loader(CONST.GOOGLE_MAP_KEY, myLoaderOption);

let mapInit = new Promise(function(mapResolve) {

  loader.load().then(function (g) {
    global.google = g;

    require('leaflet.gridlayer.googlemutant/Leaflet.GoogleMutant.js');
    mapResolve(true);
  });

});

global.mapInit = mapInit;

