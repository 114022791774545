const calculateDistance = (latA, lonA, latB, lonB) => {
  if (!latA || !lonA || !latB || !lonB) {
    return false;
  }

  var EARTH_RADIUS = 6372795;
  var M_PI = Math.PI / 180;

  // перевести координаты в радианы
  var lat1 = parseFloat(latA) * M_PI;
  var lat2 = parseFloat(latB) * M_PI;
  var long1 = parseFloat(lonA) * M_PI;
  var long2 = parseFloat(lonB) * M_PI;

  // косинусы и синусы широт и разницы долгот
  var cl1 = Math.cos(lat1);
  var cl2 = Math.cos(lat2);
  var sl1 = Math.sin(lat1);
  var sl2 = Math.sin(lat2);
  var delta = long2 - long1;
  var cdelta = Math.cos(delta);
  var sdelta = Math.sin(delta);

  // вычисления длины большого круга
  var y = Math.sqrt(Math.pow(cl2 * sdelta, 2) + Math.pow(cl1 * sl2 - sl1 * cl2 * cdelta, 2));
  var x = sl1 * sl2 + cl1 * cl2 * cdelta;

  //
  var ad = Math.atan2(y, x);
  var dist = ad * EARTH_RADIUS;

  return parseInt(dist);
};

export default calculateDistance;
