
import sendRequest from "@/function/global/sendRequest";
import prepare from "@/function/venue/venuePrepare";

export default  {
  state: {
    venueList: {
      loadStatus: "CREATED", // LOAD, SUCCESS, ERROR, CREATED???, ABORT???
      request   : null, // TODO: use request
      data      : [],
    },
  },
  getters: {
    venueList_getLoadStatus(state) {
      return () => state.venueList.loadStatus;
    },
    venueList_getData(state) {
      return () => state.venueList.data;
    },
  },
  mutations: {
    venueList_setLoadStatus (state, loadStatus) {
      state.venueList.loadStatus = loadStatus;
    },
    venueList_setData (state, data) {
      state.venueList.data       = data;
    }
  },
  actions: {
    venueList_load({ state, getters, commit, dispatch }, search) {

      commit('venueList_setLoadStatus', 'LOAD');

      return new Promise((resolve, reject) => {

        sendRequest('EventListOnMap', {
          startDate     : search.request.startDate.format('YYYY-MM-DD'),
          endDate       : search.request.endDate.format('YYYY-MM-DD'),
          latitudeNorth : search.request.latitudeNorth ,
          longitudeWest : search.request.longitudeWest ,
          latitudeSouth : search.request.latitudeSouth ,
          longitudeEast : search.request.longitudeEast ,
        })
          .then((data) => {
            //
            // for(let i = 0; i< data.hotel_list.length; i++){
            //   data.hotel_list[i].provider = 'booking';
            //   // data.hotel_list[i].id = 'booking_fix_id__' + i;
            //   // data.hotel_list[i].kind = 'hotel';
            //
            //   // if(data.hotel_list[i].images.length === 0){}
            //   data.hotel_list[i].images = [{
            //     url: '#'
            //   }];
            // }
            let venueList = prepare(data.data, search.prepare);

            commit('venueList_setData'      , venueList);
            commit('venueList_setLoadStatus', 'SUCCESS');
          })
          .catch(e => {
            // commit('venueList_setData'      , []);
            // commit('venueList_setLoadStatus', 'ERROR');
            console.warn("ERROR", e);
          });


      })

    }
  }
}
