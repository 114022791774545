
/*
 * supplement:
 * - travelerCount
 * - hotelCount
 * - nightsInDest
 * - event
 *   - venue_latitude
 *   - venue_longitude
 *   - eventCity
 *     - lat
 *     - lon
 */

import _HotelRating from "./hotelRating";
import calculateTheDistance from "./calculateDistance"

export default function(hotel, supplement){

  var _h = hotel;

  _h.travelers_count  = supplement.travelerCount;
  _h.total_hotel      = supplement.hotelCount;
  _h.nightsInDest     = supplement.nightsInDest;

  _h.eventLocation    = null;
  _h.distanceToEvent  = null;
  _h.distanceToCenter = null;

  if(supplement.event){

    _h.eventLocation = {
      lat: parseFloat(supplement.event.venue.lat),
      lng: parseFloat(supplement.event.venue.lon),
    }

    _h.distanceToEvent = calculateTheDistance (
      _h.latitude,
      _h.longitude,
      supplement.event.venue.lat,
      supplement.event.venue.lon
    );

    if(supplement.event.eventCity){
      _h.distanceToCenter = calculateTheDistance(
        _h.latitude,
        _h.longitude,
        supplement.event.city.lat,
        supplement.event.city.lon
      );
    }
  }
  // max distance fix
  // if(_h.distanceToEvent > 50000) { _h.distanceToEvent = 50000; }

  const currency = Currency.getName();

  var minHotelRoomPrice = _h.rooms[0].price[currency] || 99999999;
  var minHotelRoomBreakfastPrice = 99999999;
  var roomBreakfastInclude = false;
  var roomWithMinimalPrice = _h.rooms[0];

  for (var roomId = 0; roomId < _h.rooms.length; roomId++) {
    // Выборка комнаты с минимальной ценой
    if (minHotelRoomPrice > _h.rooms[roomId].price[currency]) {
      minHotelRoomPrice = _h.rooms[roomId].price[currency];
      roomWithMinimalPrice = _h.rooms[roomId];
    }

    if(_h.rooms[roomId].breakfast_included && minHotelRoomBreakfastPrice > _h.rooms[roomId].price[currency]){
      minHotelRoomBreakfastPrice = _h.rooms[roomId].price[currency];
      roomBreakfastInclude = true;
    }

    // _hotel.hotel_list[i].rooms[roomId].breakfastIsIncluded = HotelHelper.breakfastIsIncluded(_hotel.hotel_list[i].rooms[roomId]);
  }
  _h.minHotelRoomPrice          = minHotelRoomPrice;
  _h.minHotelRoomBreakfastPrice = minHotelRoomBreakfastPrice;
  _h.roomBreakfastInclude       = roomBreakfastInclude;
  _h.roomWithMinimalPrice       = roomWithMinimalPrice;

  // TODO: sort in module and add sort cache
  // _h.rooms.sort(HotelHelper.sortHotelRoomByPrice);

  _h._isHostel = false;

  if (_h.kind.toLowerCase() == 'hostel') {
    _h._isHostel = true;
  }
  var string = _h.name.toLowerCase();
  if (string.indexOf('hostel') !== -1) {
    _h._isHostel = true;
  }

  _h._rating = {
    economy   : _HotelRating.economy(_h),
    optimal   : _HotelRating.optimal(_h),
    superior  : _HotelRating.superior(_h)
  }

  _h.direction = {}; // для реактивности
  return _h;
}
