// custom
const getKey = function ({ offerId }) {
  return offerId;
};

/////////////////////////////////
const getStoreStructure = function () {
  return {
    loadStatus: "CREATED", // LOAD, SUCCESS, ERROR, CREATED???, ABORT???
    request   : null, // TODO: use request
    data      : null
  };
};

import Vue from 'vue';
import sendRequest from "@/function/global/sendRequest";

import prepare from "@/function/event/eventPrepare";

export default  {
  state: {
    event: {},
  },
  getters: {
    event_getLoadStatus(state) {
      return function(search){
        let value = state['event'][  getKey(search) ];
        if(!value) {
          value = getStoreStructure(search);
        }
        return value.loadStatus;
      };
    },
    event_getData(state) {
      return function(search){
        let value = state['event'][  getKey(search) ];
        if(!value) {
          value = getStoreStructure(search);
        }
        return value.data;
      };
    },
  },
  mutations: {
    // setMutation (state, {search, loadStatus, data}) {
    //   let key = getStoreStructure(search);
    //
    //   state['event'][ key ].loadStatus = loadStatus;
    //   state['event'][ key ].data       = data;
    // },
    event_setLoadStatus (state, {key, loadStatus}) {
      state['event'][ key ].loadStatus = loadStatus;
    },
    event_setData (state, {key, data}) {
      state['event'][ key ].data       = data;
    }
  },
  actions: {
    event_abort({ state, getters, commit, dispatch }, search) {
      // TODO
    },
    event_load({ state, getters, commit, dispatch }, search) {

      let key = getKey(search);

      // TODO: fix
      // if(state['event'][ key ].loadStatus === 'LOAD'){
      //   return;
      // }

      Vue.set(state['event'], key, getStoreStructure());

      commit('event_setLoadStatus', {key: key, loadStatus: 'LOAD'});

      // TODO fix method and data
      return sendRequest('OfferGet', search)
        .then((data) => {

          let event = prepare(data);

          commit('event_setData'      , {key: key, data: event});
          commit('event_setLoadStatus', {key: key, loadStatus: 'SUCCESS'});
          return event;
        })
        .catch(e => {
          commit('event_setData'      , {key: key, data: null});
          commit('event_setLoadStatus', {key: key, loadStatus: 'ERROR'});
          console.warn("ERROR", e);
        });

    }
  }
}
