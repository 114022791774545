
import economy from './hotelRatingEconomy';
import optimal from './hotelRatingOptimal';
import superior from './hotelRatingSuperior';

export default {
  economy : economy,
  optimal : optimal,
  superior: superior,
}
