export default class City {

  _getDefaultCityStructure() {
    return {
      id: -100,
      name: '',
      country_code: '',
      population: 0,
      lat: 0,
      lon: 0,
    }
  }

  constructor(cityObj) {
    let defaultCity = this._getDefaultCityStructure();
    for(let key in defaultCity) {
      this[key] = defaultCity[key];
    }

    // если есть обьект эвента, то инициализируем только сущестующие поля
    cityObj && this.setByObject(cityObj)
  }


  setByObject(cityObj){
    let defaultCity = this._getDefaultCityStructure();
    for(let key in defaultCity) {
      // if(cityObj.hasOwnProperty(key)){
      if( Object.getOwnPropertyDescriptor(cityObj, key) ){
        this[key] = cityObj[key];
      }
    }
  }



}
