
/**
 * перелет самый дешевый до 1-й пересадки
 *
 *
 * ============================= Правило от 2019-12-18
 * Самый дешевый из прямых с багажом
 *
 * Предлагать 1 пересадку когда других рейсов нет
 * Пересадка не более 5 часов
 *
 * Без “ тяжелых перелетов” длительные ночные пересадки более 3-х часов в период 22:00-06:00
 *
 * Вылет с 10:00 Прилет до 20:00 Если нет полетов то ближайший к этим параметрам
 *
 */

// новый вариант на сложении
export default function(flight, option) {
  // rating detail
  let rdd = {};

  option = option || {};
  option.comfortDepartureTime = option.comfortDepartureTime || {
    start : 36000, // 10:00
    end   : 72000, // 20:00
  };
  option.comfortReturnTime = option.comfortReturnTime || {
    start : 36000, // 10:00
    end   : 72000, // 20:00
  };

  let comfort = 0;
  let fakePrice = flight.price.EUR;

  // количество пересадок туда
  switch (true) {
    case flight.transplantCount.departure === 0:  comfort +=  10010 / 2 ; rdd.departureTransplantCount = 10010 / 2; break;
    case flight.transplantCount.departure === 1:  comfort +=  9009  / 2 ; rdd.departureTransplantCount = 9009  / 2;break;
    case flight.transplantCount.departure === 2:  comfort +=  8007  / 2 ; rdd.departureTransplantCount = 8007  / 2;break;
    case flight.transplantCount.departure === 3:  comfort +=  7004  / 2 ; rdd.departureTransplantCount = 7004  / 2;break;
    default:                                      comfort +=  6000  / 2 ; rdd.departureTransplantCount = 6000  / 2;break;
  }

  // количество пересадок обратно
  switch (true) {
    case flight.transplantCount.return === 0: comfort +=  10010 / 2 ; rdd.returnTransplantCount = 10010 / 2; break;
    case flight.transplantCount.return === 1: comfort +=  9009  / 2 ; rdd.returnTransplantCount = 9009  / 2; break;
    case flight.transplantCount.return === 2: comfort +=  8007  / 2 ; rdd.returnTransplantCount = 8007  / 2; break;
    case flight.transplantCount.return === 3: comfort +=  7004  / 2 ; rdd.returnTransplantCount = 7004  / 2; break;
    default:                                  comfort +=  6000  / 2 ; rdd.returnTransplantCount = 6000  / 2; break;
  }

  // Ночной
  rdd.night_flight = 0;
  if (flight.night_flight) { comfort -= 4020; rdd.night_flight = -4020; }

  // наличие багажа
  if (!flight.baggage.piece) { fakePrice += 100; rdd.baggage = 'NOT BAGGAGE +100EUR'; } else { rdd.baggage = 'BAGGAGE INCLUDE';}

  // поиск перелетов оптимальных по дате прилета за день до начала мероприятия
  if (flight.optimalDateArrival) { comfort += 20040; rdd.optimalDateArrival = 20040} else { rdd.optimalDateArrival = 'FALSE';}
  // поиск перелетов оптимальных по дате улета за день до после окончания мероприятия
  if (flight.optimalDateReturn) { comfort += 10050; rdd.optimalDateReturn = 10050} else { rdd.optimalDateReturn = 'FALSE';}

  // время пересадок
  switch (true) {
    case (flight.maxLayoutTime > 21600):  comfort -= 600; rdd.maxLayoutTime='больше 6 часов пересадка -600'; break; // больше 6 часов пересадка
    case (flight.maxLayoutTime > 18000):  comfort -= 500; rdd.maxLayoutTime='больше 5 часов пересадка -500'; break; // больше 5 часов пересадка
    case (flight.maxLayoutTime > 14400):  comfort -= 400; rdd.maxLayoutTime='больше 4 часов пересадка -400'; break; // больше 4 часов пересадка
    case (flight.maxLayoutTime > 10800):  comfort -= 300; rdd.maxLayoutTime='больше 3 часов пересадка -300'; break; // больше 3 часов пересадка
    case (flight.maxLayoutTime >  7200):  comfort -= 200; rdd.maxLayoutTime='больше 2 часов пересадка -200'; break; // больше 2 часов пересадка
    default:                              comfort -=   0; rdd.maxLayoutTime='пересадка до 2 часов       -0'; break;
  }


  // Время вылета
  // динамическое окно поездки
  let _h = flight.departure_flight.dTimeSecondSinceDay;
  let _k = 0;
  if(_h < option.comfortDepartureTime.start){
    _k = parseInt((option.comfortDepartureTime.start - _h) / 3600 ) + 1;
  } else if ( _h > option.comfortDepartureTime.end) {
    _k = parseInt((_h - option.comfortDepartureTime.end) / 3600 ) + 1;
  }
  comfort += 100 * (24 - _k) / 24 / 2;
  rdd.comfortDepartureTime = 100 * (24 - _k) / 24 / 2;

  _h = flight.return_flight.dTimeSecondSinceDay;
  _k = 0;
  if(_h < option.comfortReturnTime.start){
    _k = parseInt((option.comfortReturnTime.start - _h) / 3600 ) + 1;
  } else if ( _h > option.comfortReturnTime.end) {
    _k = parseInt((_h - option.comfortReturnTime.end) / 3600 ) + 1;
  }
  comfort += 100 * (24 - _k) / 24 / 2;
  rdd.comfortReturnTime = 100 * (24 - _k) / 24 / 2;

  // статическое окно поездки
  // Время вылета
  // switch (true) {
  //   case (_h > 23):  comfort += 50 / 2; break;
  //   case (_h > 22):  comfort += 60 / 2; break;
  //   case (_h > 21):  comfort += 70 / 2; break;
  //   case (_h > 20):  comfort += 80 / 2; break;
  //   case (_h <  6):  comfort += 40 / 2; break;
  //   case (_h <  7):  comfort += 50 / 2; break;
  //   case (_h <  8):  comfort += 60 / 2; break;
  //   case (_h <  9):  comfort += 70 / 2; break;
  //   case (_h < 10):  comfort += 80 / 2; break;
  //   default:         comfort += 90 / 2; break; // c 10:00 Прилет до 20:00
  // }
  //
  // // Время вылета
  // _h = flight.return_flight.dTimeSecondSinceDay/3600;
  // switch (true) {
  //   case (_h > 23):  comfort += 50 / 2; break;
  //   case (_h > 22):  comfort += 60 / 2; break;
  //   case (_h > 21):  comfort += 70 / 2; break;
  //   case (_h > 20):  comfort += 80 / 2; break;
  //   case (_h <  6):  comfort += 40 / 2; break;
  //   case (_h <  7):  comfort += 50 / 2; break;
  //   case (_h <  8):  comfort += 60 / 2; break;
  //   case (_h <  9):  comfort += 70 / 2; break;
  //   case (_h < 10):  comfort += 80 / 2; break;
  //   default:         comfort += 90 / 2; break; // c 10:00 Прилет до 20:00
  // }

  // полет одной компанией ?
  if (flight.airlineCount > 1) {
    fakePrice += 10;
    comfort -= 100;

    rdd.oneAirline = 'полет разными компаниями: fakePrice += 10; comfort -= 100';
  } else {
    rdd.oneAirline = 'полет одной компанией';
  }

  let comfortPerMoney = comfort + (100 - (fakePrice / 100) ) * 0.01;
  rdd.fakePrice = fakePrice;
  flight._optimal_rdd = rdd;


  return parseFloat(comfortPerMoney);
}

/*
export default function(flight) {

  var comfort = 100000;

  if (flight.night_flight) { comfort *= 0.001; }

  // количество пересадок туда
  switch (true) {
    case flight.transplantCount.departure <= 1: comfort *=   55; break;
    case flight.transplantCount.departure <= 2: comfort *=   21; break;
    case flight.transplantCount.departure <= 3: comfort *=    8; break;
    case flight.transplantCount.departure <= 4: comfort *=    3; break;
    default:                                    comfort *=    1; break;
  }

  // количество пересадок обратно
  switch (true) {
    case flight.transplantCount.return <= 1: comfort *=   55; break;
    case flight.transplantCount.return <= 2: comfort *=   21; break;
    case flight.transplantCount.return <= 3: comfort *=    8; break;
    case flight.transplantCount.return <= 4: comfort *=    3; break;
    default:                                 comfort *=    1; break;
  }

  // наличие багажа
  switch (true) {
    case flight.baggage.total == true: comfort *= 2; break;
    default:                           comfort *= 1; break;
  }

  // поиск перелетов оптимальных по дате прилета за день до начала мероприятия
  switch (true) {
    case flight.optimalDateArrival: comfort *=   2; break;
    default:                        comfort *=   0.000001; break;
  }
  // поиск перелетов оптимальных по дате улета за день до после окончания мероприятия
  switch (true) {
    case flight.optimalDateReturn: comfort *=   2; break;
    default:                        comfort *=   0.000001; break;
  }

  switch (true) {
    case (flight.maxLayoutTime > 18000):  comfort *=   0.3; break; // больше 5 часов пересадка
    default:                              comfort *=     1; break;
  }

  switch (true) {
    case (flight.departure_flight.dTimeSecondSinceDay > 36000):  comfort *=    1.1; break;
    case (flight.departure_flight.dTimeSecondSinceDay > 30000):  comfort *=   1.05; break;
    case (flight.departure_flight.dTimeSecondSinceDay > 25000):  comfort *=      1; break;
    default:                                                 comfort *=   0.95; break;
  }

  var comfortPerMoney = comfort / flight.price.EUR;

  return parseFloat(comfortPerMoney);
};
*/