import sendRequest from "@/function/global/sendRequest";

const getCityByCoordinates = ({ latitude, longitude }) => {
  return new Promise((resolve, reject) => {
    sendRequest('ClosestCityByGeoPointGet', { latitude, longitude })
      .then(response => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
        throw(new Error('getCityByCoordinates', err));
      });
  });
};

export default getCityByCoordinates;
