
import hotelPrepareItem from "../hotel/hotelPrepareItem";

export default function(hotelList, supplement){

  for(var i = 0; i < hotelList.length; i++) {
    hotelPrepareItem(hotelList[i], supplement);
  }

  return hotelList;
}