let airlineInfo = {
  '00': {lcc: "1", name: "Anadolujet", code: "00", icon: "https://images.kiwi.com/airlines/32/00.png"},
  '0B': {lcc: "1", name: "Blue Air", code: "0B", icon: "https://images.kiwi.com/airlines/32/0B.png"},
  '0D': {lcc: "0", name: "Darwin Airline", code: "0D", icon: "https://images.kiwi.com/airlines/32/0D.png"},
  '0V': {lcc: "0", name: "VASCO", code: "0V", icon: "https://images.kiwi.com/airlines/32/0V.png"},
  '0X': {lcc: "0", name: "Copenhagen Express", code: "0X", icon: "https://images.kiwi.com/airlines/32/0X.png"},
  '11': {lcc: "0", name: "TUIfly (X3)", code: "11", icon: "https://images.kiwi.com/airlines/32/11.png"},
  '12': {lcc: "0", name: "12 North", code: "12", icon: "https://images.kiwi.com/airlines/32/12.png"},
  '1B': {lcc: "0", name: "Abacus International", code: "1B", icon: "https://images.kiwi.com/airlines/32/1B.png"},
  '1C': {lcc: "0", name: "OneChina", code: "1C", icon: "https://images.kiwi.com/airlines/32/1C.png"},
  '1D': {lcc: "1", name: "Aerolinea de Antioquia", code: "1D", icon: "https://images.kiwi.com/airlines/32/1D.png"},
  '1F': {lcc: "0", name: "CB Airways UK ( Interliging Flights )", code: "1F", icon: "https://images.kiwi.com/airlines/32/1F.png"},
  '1I': {lcc: "0", name: "NetJets", code: "1I", icon: "https://images.kiwi.com/airlines/32/1I.png"},
  '1T': {lcc: "0", name: "Hitit Bilgisayar Hizmetleri", code: "1T", icon: "https://images.kiwi.com/airlines/32/1T.png"},
  '1X': {lcc: "0", name: "Branson Air Express", code: "1X", icon: "https://images.kiwi.com/airlines/32/1X.png"},
  '20': {lcc: "0", name: "Air Salone", code: "20", icon: "https://images.kiwi.com/airlines/32/20.png"},
  '24': {lcc: "0", name: "Euro Jet", code: "24", icon: "https://images.kiwi.com/airlines/32/24.png"},
  '2A': {lcc: "0", name: "Deutsche Bahn", code: "2A", icon: "https://images.kiwi.com/airlines/32/2A.png"},
  '2B': {lcc: "0", name: "AlbaWings", code: "2B", icon: "https://images.kiwi.com/airlines/32/2B.png"},
  '2C': {lcc: "0", name: "SNCF", code: "2C", icon: "https://images.kiwi.com/airlines/32/2C.png"},
  '2D': {lcc: "0", name: "Aero VIP (2D)", code: "2D", icon: "https://images.kiwi.com/airlines/32/2D.png"},
  '2F': {lcc: "0", name: "Frontier Flying Service", code: "2F", icon: "https://images.kiwi.com/airlines/32/2F.png"},
  '2G': {lcc: "1", name: "Angara airlines", code: "2G", icon: "https://images.kiwi.com/airlines/32/2G.png"},
  '2I': {lcc: "1", name: "Star Peru", code: "2I", icon: "https://images.kiwi.com/airlines/32/2I.png"},
  '2J': {lcc: "0", name: "Air Burkina", code: "2J", icon: "https://images.kiwi.com/airlines/32/2J.png"},
  '2K': {lcc: "0", name: "Aerolineas Galapagos (Aerogal)", code: "2K", icon: "https://images.kiwi.com/airlines/32/2K.png"},
  '2L': {lcc: "0", name: "Helvetic Airways", code: "2L", icon: "https://images.kiwi.com/airlines/32/2L.png"},
  '2M': {lcc: "0", name: "Maya Island Air", code: "2M", icon: "https://images.kiwi.com/airlines/32/2M.png"},
  '2N': {lcc: "1", name: "NextJet", code: "2N", icon: "https://images.kiwi.com/airlines/32/2N.png"},
  '2O': {lcc: "0", name: "Island Air Kodiak", code: "2O", icon: "https://images.kiwi.com/airlines/32/2O.png"},
  '2P': {lcc: "1", name: "PAL Express", code: "2P", icon: "https://images.kiwi.com/airlines/32/2P.png"},
  '2Q': {lcc: "0", name: "Air Cargo Carriers", code: "2Q", icon: "https://images.kiwi.com/airlines/32/2Q.png"},
  '2T': {lcc: "0", name: "TruJet", code: "2T", icon: "https://images.kiwi.com/airlines/32/2T.png"},
  '2U': {lcc: "0", name: "Air Guinee Express", code: "2U", icon: "https://images.kiwi.com/airlines/32/2U.png"},
  '2W': {lcc: "0", name: "Welcome Air", code: "2W", icon: "https://images.kiwi.com/airlines/32/2W.png"},
  '2Z': {lcc: "1", name: "Passaredo", code: "2Z", icon: "https://images.kiwi.com/airlines/32/2Z.png"},
  '3B': {lcc: "0", name: "Binter Cabo Verde", code: "3B", icon: "https://images.kiwi.com/airlines/32/3B.png"},
  '3C': {lcc: "None", name: "Air Chathams Limited 3C", code: "3C", icon: "https://images.kiwi.com/airlines/32/3C.png"},
  '3E': {lcc: "0", name: "Air Choice One", code: "3E", icon: "https://images.kiwi.com/airlines/32/3E.png"},
  '3F': {lcc: "0", name: "Fly Colombia ( Interliging Flights )", code: "3F", icon: "https://images.kiwi.com/airlines/32/3F.png"},
  '3G': {lcc: "0", name: "Atlant-Soyuz Airlines", code: "3G", icon: "https://images.kiwi.com/airlines/32/3G.png"},
  '3H': {lcc: "1", name: "AirInuit", code: "3H", icon: "https://images.kiwi.com/airlines/32/3H.png"},
  '3I': {lcc: "0", name: "Air Comet Chile", code: "3I", icon: "https://images.kiwi.com/airlines/32/3I.png"},
  '3J': {lcc: "0", name: "Jubba Airways", code: "3J", icon: "https://images.kiwi.com/airlines/32/3J.png"},
  '3K': {lcc: "0", name: "Jetstar Asia Airways", code: "3K", icon: "https://images.kiwi.com/airlines/32/3K.png"},
  '3L': {lcc: "1", name: "Intersky", code: "3L", icon: "https://images.kiwi.com/airlines/32/3L.png"},
  '3M': {lcc: "1", name: "Silver Airways", code: "3M", icon: "https://images.kiwi.com/airlines/32/3M.png"},
  '3O': {lcc: "0", name: "Air Arabia Maroc", code: "3O", icon: "https://images.kiwi.com/airlines/32/3O.png"},
  '3P': {lcc: "0", name: "Tiara Air", code: "3P", icon: "https://images.kiwi.com/airlines/32/3P.png"},
  '3Q': {lcc: "0", name: "Yunnan Airlines", code: "3Q", icon: "https://images.kiwi.com/airlines/32/3Q.png"},
  '3R': {lcc: "0", name: "Moskovia Airlines", code: "3R", icon: "https://images.kiwi.com/airlines/32/3R.png"},
  '3S': {lcc: "0", name: "Air Antilles Express", code: "3S", icon: "https://images.kiwi.com/airlines/32/3S.png"},
  '3T': {lcc: "0", name: "Turan Air", code: "3T", icon: "https://images.kiwi.com/airlines/32/3T.png"},
  '3U': {lcc: "0", name: "Sichuan Airlines", code: "3U", icon: "https://images.kiwi.com/airlines/32/3U.png"},
  '47': {lcc: "0", name: "88", code: "47", icon: "https://images.kiwi.com/airlines/32/47.png"},
  '4A': {lcc: "0", name: "Air Kiribati", code: "4A", icon: "https://images.kiwi.com/airlines/32/4A.png"},
  '4B': {lcc: "0", name: "BoutiqueAir", code: "4B", icon: "https://images.kiwi.com/airlines/32/4B.png"},
  '4C': {lcc: "0", name: "LATAM Colombia", code: "4C", icon: "https://images.kiwi.com/airlines/32/4C.png"},
  '4D': {lcc: "0", name: "Air Sinai", code: "4D", icon: "https://images.kiwi.com/airlines/32/4D.png"},
  '4G': {lcc: "0", name: "Gazpromavia", code: "4G", icon: "https://images.kiwi.com/airlines/32/4G.png"},
  '4H': {lcc: "0", name: "United Airways", code: "4H", icon: "https://images.kiwi.com/airlines/32/4H.png"},
  '4K': {lcc: "0", name: "Askari Aviation", code: "4K", icon: "https://images.kiwi.com/airlines/32/4K.png"},
  '4L': {lcc: "0", name: "Euroline", code: "4L", icon: "https://images.kiwi.com/airlines/32/4L.png"},
  '4M': {lcc: "0", name: "LATAM Argentina", code: "4M", icon: "https://images.kiwi.com/airlines/32/4M.png"},
  '4N': {lcc: "0", name: "Air North", code: "4N", icon: "https://images.kiwi.com/airlines/32/4N.png"},
  '4O': {lcc: "1", name: "Interjet", code: "4O", icon: "https://images.kiwi.com/airlines/32/4O.png"},
  '4P': {lcc: "0", name: "Regional sky", code: "4P", icon: "https://images.kiwi.com/airlines/32/4P.png"},
  '4Q': {lcc: "0", name: "Safi Airlines", code: "4Q", icon: "https://images.kiwi.com/airlines/32/4Q.png"},
  '4R': {lcc: "0", name: "Renfe", code: "4R", icon: "https://images.kiwi.com/airlines/32/4R.png"},
  '4T': {lcc: "0", name: "Belair", code: "4T", icon: "https://images.kiwi.com/airlines/32/4T.png"},
  '4U': {lcc: "1", name: "germanwings", code: "4U", icon: "https://images.kiwi.com/airlines/32/4U.png"},
  '4W': {lcc: "0", name: "Allied Air", code: "4W", icon: "https://images.kiwi.com/airlines/32/4W.png"},
  '4X': {lcc: "0", name: "Red Jet Mexico", code: "4X", icon: "https://images.kiwi.com/airlines/32/4X.png"},
  '4Z': {lcc: "0", name: "Airlink (SAA)", code: "4Z", icon: "https://images.kiwi.com/airlines/32/4Z.png"},
  '5C': {lcc: "1", name: "Nature Air", code: "5C", icon: "https://images.kiwi.com/airlines/32/5C.png"},
  '5D': {lcc: "0", name: "Aerolitoral", code: "5D", icon: "https://images.kiwi.com/airlines/32/5D.png"},
  '5E': {lcc: "0", name: "SGA Airlines", code: "5E", icon: "https://images.kiwi.com/airlines/32/5E.png"},
  '5F': {lcc: "1", name: "Fly One", code: "5F", icon: "https://images.kiwi.com/airlines/32/5F.png"},
  '5G': {lcc: "0", name: "Skyservice Airlines", code: "5G", icon: "https://images.kiwi.com/airlines/32/5G.png"},
  '5H': {lcc: "1", name: "Fly540", code: "5H", icon: "https://images.kiwi.com/airlines/32/5H.png"},
  '5J': {lcc: "1", name: "Cebu Pacific", code: "5J", icon: "https://images.kiwi.com/airlines/32/5J.png"},
  '5K': {lcc: "0", name: "Hi Fly (5K)", code: "5K", icon: "https://images.kiwi.com/airlines/32/5K.png"},
  '5L': {lcc: "0", name: "Aerosur", code: "5L", icon: "https://images.kiwi.com/airlines/32/5L.png"},
  '5M': {lcc: "0", name: "Sibaviatrans", code: "5M", icon: "https://images.kiwi.com/airlines/32/5M.png"},
  '5N': {lcc: "1", name: "NordAvia", code: "5N", icon: "https://images.kiwi.com/airlines/32/5N.png"},
  '5O': {lcc: "0", name: "ASL Airlines France", code: "5O", icon: "https://images.kiwi.com/airlines/32/5O.png"},
  '5P': {lcc: "0", name: "Small Planet Airlines", code: "5P", icon: "https://images.kiwi.com/airlines/32/5P.png"},
  '5Q': {lcc: "0", name: "BQB Lineas Aereas", code: "5Q", icon: "https://images.kiwi.com/airlines/32/5Q.png"},
  '5T': {lcc: "0", name: "Canadian North", code: "5T", icon: "https://images.kiwi.com/airlines/32/5T.png"},
  '5U': {lcc: "0", name: "Transportes A/u00e9reos Guatemaltecos", code: "5U", icon: "https://images.kiwi.com/airlines/32/5U.png"},
  '5W': {lcc: "0", name: "WESTBahn", code: "5W", icon: "https://images.kiwi.com/airlines/32/5W.png"},
  '5Y': {lcc: "0", name: "Atlas Air", code: "5Y", icon: "https://images.kiwi.com/airlines/32/5Y.png"},
  '5Z': {lcc: "1", name: "Cem Air", code: "5Z", icon: "https://images.kiwi.com/airlines/32/5Z.png"},
  '69': {lcc: "0", name: "Royal European Airlines", code: "69", icon: "https://images.kiwi.com/airlines/32/69.png"},
  '6A': {lcc: "0", name: "Consorcio Aviaxsa", code: "6A", icon: "https://images.kiwi.com/airlines/32/6A.png"},
  '6B': {lcc: "0", name: "TUIfly Nordic", code: "6B", icon: "https://images.kiwi.com/airlines/32/6B.png"},
  '6D': {lcc: "0", name: "Pelita", code: "6D", icon: "https://images.kiwi.com/airlines/32/6D.png"},
  '6E': {lcc: "1", name: "IndiGo Airlines", code: "6E", icon: "https://images.kiwi.com/airlines/32/6E.png"},
  '6F': {lcc: "1", name: "Primera Air Nordic", code: "6F", icon: "https://images.kiwi.com/airlines/32/6F.png"},
  '6G': {lcc: "0", name: "Air Wales", code: "6G", icon: "https://images.kiwi.com/airlines/32/6G.png"},
  '6H': {lcc: "0", name: "Israir", code: "6H", icon: "https://images.kiwi.com/airlines/32/6H.png"},
  '6I': {lcc: "0", name: "Alsie Express", code: "6I", icon: "https://images.kiwi.com/airlines/32/6I.png"},
  '6J': {lcc: "0", name: "Solaseed Air", code: "6J", icon: "https://images.kiwi.com/airlines/32/6J.png"},
  '6K': {lcc: "0", name: "Asian Spirit", code: "6K", icon: "https://images.kiwi.com/airlines/32/6K.png"},
  '6O': {lcc: "0", name: "Orbest", code: "6O", icon: "https://images.kiwi.com/airlines/32/6O.png"},
  '6P': {lcc: "0", name: "Gryphon Airlines", code: "6P", icon: "https://images.kiwi.com/airlines/32/6P.png"},
  '6R': {lcc: "1", name: "Alrosa", code: "6R", icon: "https://images.kiwi.com/airlines/32/6R.png"},
  '6S': {lcc: "0", name: "SaudiGulf Airlines", code: "6S", icon: "https://images.kiwi.com/airlines/32/6S.png"},
  '6T': {lcc: "1", name: "Air Mandalay", code: "6T", icon: "https://images.kiwi.com/airlines/32/6T.png"},
  '6U': {lcc: "0", name: "Air Cargo Germany", code: "6U", icon: "https://images.kiwi.com/airlines/32/6U.png"},
  '6W': {lcc: "1", name: "Saratov Aviation Division", code: "6W", icon: "https://images.kiwi.com/airlines/32/6W.png"},
  '6Y': {lcc: "0", name: "SmartLynx Airlines", code: "6Y", icon: "https://images.kiwi.com/airlines/32/6Y.png"},
  '76': {lcc: "0", name: "Southjet", code: "76", icon: "https://images.kiwi.com/airlines/32/76.png"},
  '77': {lcc: "0", name: "Southjet connect", code: "77", icon: "https://images.kiwi.com/airlines/32/77.png"},
  '78': {lcc: "0", name: "Southjet cargo", code: "78", icon: "https://images.kiwi.com/airlines/32/78.png"},
  '7B': {lcc: "0", name: "Fly Blue Crane", code: "7B", icon: "https://images.kiwi.com/airlines/32/7B.png"},
  '7C': {lcc: "1", name: "Jeju Air", code: "7C", icon: "https://images.kiwi.com/airlines/32/7C.png"},
  '7E': {lcc: "0", name: "Aeroline GmbH", code: "7E", icon: "https://images.kiwi.com/airlines/32/7E.png"},
  '7F': {lcc: "0", name: "First Air", code: "7F", icon: "https://images.kiwi.com/airlines/32/7F.png"},
  '7G': {lcc: "1", name: "Star Flyer", code: "7G", icon: "https://images.kiwi.com/airlines/32/7G.png"},
  '7H': {lcc: "1", name: "Ravn Alaska", code: "7H", icon: "https://images.kiwi.com/airlines/32/7H.png"},
  '7I': {lcc: "1", name: "Insel Air", code: "7I", icon: "https://images.kiwi.com/airlines/32/7I.png"},
  '7J': {lcc: "1", name: "Tajik Air", code: "7J", icon: "https://images.kiwi.com/airlines/32/7J.png"},
  '7K': {lcc: "0", name: "Kogalymavia Air Company", code: "7K", icon: "https://images.kiwi.com/airlines/32/7K.png"},
  '7L': {lcc: "0", name: "Sun D/u0027Or", code: "7L", icon: "https://images.kiwi.com/airlines/32/7L.png"},
  '7M': {lcc: "0", name: "MAYAir", code: "7M", icon: "https://images.kiwi.com/airlines/32/7M.png"},
  '7N': {lcc: "0", name: "Pawa Dominicana", code: "7N", icon: "https://images.kiwi.com/airlines/32/7N.png"},
  '7P': {lcc: "1", name: "AirPanama", code: "7P", icon: "https://images.kiwi.com/airlines/32/7P.png"},
  '7Q': {lcc: "0", name: "Elite Airways", code: "7Q", icon: "https://images.kiwi.com/airlines/32/7Q.png"},
  '7R': {lcc: "1", name: "Rusline", code: "7R", icon: "https://images.kiwi.com/airlines/32/7R.png"},
  '7T': {lcc: "0", name: "Trenitalia", code: "7T", icon: "https://images.kiwi.com/airlines/32/7T.png"},
  '7V': {lcc: "0", name: "Federai Airlines", code: "7V", icon: "https://images.kiwi.com/airlines/32/7V.png"},
  '7W': {lcc: "0", name: "Windrose Airlines", code: "7W", icon: "https://images.kiwi.com/airlines/32/7W.png"},
  '7Y': {lcc: "0", name: "Mann Yadanarpon Airlines", code: "7Y", icon: "https://images.kiwi.com/airlines/32/7Y.png"},
  '7Z': {lcc: "0", name: "Halcyonair", code: "7Z", icon: "https://images.kiwi.com/airlines/32/7Z.png"},
  '8A': {lcc: "0", name: "Atlas Blue", code: "8A", icon: "https://images.kiwi.com/airlines/32/8A.png"},
  '8B': {lcc: "0", name: "TransNusa", code: "8B", icon: "https://images.kiwi.com/airlines/32/8B.png"},
  '8D': {lcc: "0", name: "Astair", code: "8D", icon: "https://images.kiwi.com/airlines/32/8D.png"},
  '8E': {lcc: "0", name: "Bering Air", code: "8E", icon: "https://images.kiwi.com/airlines/32/8E.png"},
  '8F': {lcc: "0", name: "STP Airways", code: "8F", icon: "https://images.kiwi.com/airlines/32/8F.png"},
  '8G': {lcc: "0", name: "Mid Africa Aviation", code: "8G", icon: "https://images.kiwi.com/airlines/32/8G.png"},
  '8H': {lcc: "0", name: "Heli France", code: "8H", icon: "https://images.kiwi.com/airlines/32/8H.png"},
  '8I': {lcc: "0", name: "MyAir", code: "8I", icon: "https://images.kiwi.com/airlines/32/8I.png"},
  '8J': {lcc: "0", name: "Eco Jet", code: "8J", icon: "https://images.kiwi.com/airlines/32/8J.png"},
  '8L': {lcc: "1", name: "Lucky air", code: "8L", icon: "https://images.kiwi.com/airlines/32/8L.png"},
  '8M': {lcc: "0", name: "Myanmar Airways", code: "8M", icon: "https://images.kiwi.com/airlines/32/8M.png"},
  '8N': {lcc: "0", name: "Regional Air Services", code: "8N", icon: "https://images.kiwi.com/airlines/32/8N.png"},
  '8O': {lcc: "0", name: "West Coast Air", code: "8O", icon: "https://images.kiwi.com/airlines/32/8O.png"},
  '8P': {lcc: "0", name: "Pacific Coastal Airline", code: "8P", icon: "https://images.kiwi.com/airlines/32/8P.png"},
  '8Q': {lcc: "1", name: "Onur Air", code: "8Q", icon: "https://images.kiwi.com/airlines/32/8Q.png"},
  '8R': {lcc: "1", name: "Sol L/u00edneas A/u00e9reas", code: "8R", icon: "https://images.kiwi.com/airlines/32/8R.png"},
  '8T': {lcc: "0", name: "Air Tindi", code: "8T", icon: "https://images.kiwi.com/airlines/32/8T.png"},
  '8U': {lcc: "0", name: "Afriqiyah Airways", code: "8U", icon: "https://images.kiwi.com/airlines/32/8U.png"},
  '8V': {lcc: "0", name: "Astral Aviation", code: "8V", icon: "https://images.kiwi.com/airlines/32/8V.png"},
  '8Z': {lcc: "0", name: "Wizz Air Hungary", code: "8Z", icon: "https://images.kiwi.com/airlines/32/8Z.png"},
  '99': {lcc: "0", name: "Ciao Air", code: "99", icon: "https://images.kiwi.com/airlines/32/99.png"},
  '9B': {lcc: "0", name: "AccesRail", code: "9B", icon: "https://images.kiwi.com/airlines/32/9B.png"},
  '9C': {lcc: "1", name: "Spring Airlines", code: "9C", icon: "https://images.kiwi.com/airlines/32/9C.png"},
  '9E': {lcc: "0", name: "Endeavor Air", code: "9E", icon: "https://images.kiwi.com/airlines/32/9E.png"},
  '9H': {lcc: "0", name: "Air Changan", code: "9H", icon: "https://images.kiwi.com/airlines/32/9H.png"},
  '9I': {lcc: "0", name: "Alliance Air", code: "9I", icon: "https://images.kiwi.com/airlines/32/9I.png"},
  '9J': {lcc: "0", name: "Dana Airlines Limited", code: "9J", icon: "https://images.kiwi.com/airlines/32/9J.png"},
  '9K': {lcc: "1", name: "Cape Air", code: "9K", icon: "https://images.kiwi.com/airlines/32/9K.png"},
  '9L': {lcc: "0", name: "Colgan Air", code: "9L", icon: "https://images.kiwi.com/airlines/32/9L.png"},
  '9M': {lcc: "0", name: "Central Mountain Air", code: "9M", icon: "https://images.kiwi.com/airlines/32/9M.png"},
  '9N': {lcc: "0", name: "Tropic Air Limited", code: "9N", icon: "https://images.kiwi.com/airlines/32/9N.png"},
  '9P': {lcc: "1", name: "Air Arabia Jordan", code: "9P", icon: "https://images.kiwi.com/airlines/32/9P.png"},
  '9Q': {lcc: "0", name: "PB Air", code: "9Q", icon: "https://images.kiwi.com/airlines/32/9Q.png"},
  '9R': {lcc: "1", name: "SATENA", code: "9R", icon: "https://images.kiwi.com/airlines/32/9R.png"},
  '9S': {lcc: "0", name: "Spring Airlines", code: "9S", icon: "https://images.kiwi.com/airlines/32/9S.png"},
  '9T': {lcc: "0", name: "Transwest Air", code: "9T", icon: "https://images.kiwi.com/airlines/32/9T.png"},
  '9U': {lcc: "0", name: "Air Moldova", code: "9U", icon: "https://images.kiwi.com/airlines/32/9U.png"},
  '9V': {lcc: "0", name: "Avior Airlines", code: "9V", icon: "https://images.kiwi.com/airlines/32/9V.png"},
  '9W': {lcc: "0", name: "Jet Airways", code: "9W", icon: "https://images.kiwi.com/airlines/32/9W.png"},
  '9X': {lcc: "1", name: "Southern Airways Express", code: "9X", icon: "https://images.kiwi.com/airlines/32/9X.png"},
  '9Y': {lcc: "0", name: "Air Kazakhstan", code: "9Y", icon: "https://images.kiwi.com/airlines/32/9Y.png"},
  'A0': {lcc: "1", name: "Avianca Argentina", code: "A0", icon: "https://images.kiwi.com/airlines/32/A0.png"},
  'A1': {lcc: "0", name: "Atifly", code: "A1", icon: "https://images.kiwi.com/airlines/32/A1.png"},
  'A2': {lcc: "0", name: "Astra Airlines", code: "A2", icon: "https://images.kiwi.com/airlines/32/A2.png"},
  'A3': {lcc: "1", name: "Aegean", code: "A3", icon: "https://images.kiwi.com/airlines/32/A3.png"},
  'A4': {lcc: "0", name: "Azimuth", code: "A4", icon: "https://images.kiwi.com/airlines/32/A4.png"},
  'A5': {lcc: "1", name: "HOP!", code: "A5", icon: "https://images.kiwi.com/airlines/32/A5.png"},
  'A6': {lcc: "0", name: "Hongtu Airlines", code: "A6", icon: "https://images.kiwi.com/airlines/32/A6.png"},
  'A7': {lcc: "0", name: "Calafia Airlines", code: "A7", icon: "https://images.kiwi.com/airlines/32/A7.png"},
  'A9': {lcc: "0", name: "Georgian Airways", code: "A9", icon: "https://images.kiwi.com/airlines/32/A9.png"},
  'AA': {lcc: "0", name: "American Airlines", code: "AA", icon: "https://images.kiwi.com/airlines/32/AA.png"},
  'AB': {lcc: "1", name: "Air Berlin", code: "AB", icon: "https://images.kiwi.com/airlines/32/AB.png"},
  'AC': {lcc: "0", name: "Air Canada", code: "AC", icon: "https://images.kiwi.com/airlines/32/AC.png"},
  'AD': {lcc: "1", name: "Azul", code: "AD", icon: "https://images.kiwi.com/airlines/32/AD.png"},
  'AE': {lcc: "0", name: "Mandarin Airlines", code: "AE", icon: "https://images.kiwi.com/airlines/32/AE.png"},
  'AEH': {lcc: "None", name: " Aero4M", code: "AEH", icon: "https://images.kiwi.com/airlines/32/AEH.png"},
  'AF': {lcc: "0", name: "Air France", code: "AF", icon: "https://images.kiwi.com/airlines/32/AF.png"},
  'AG': {lcc: "0", name: "Aruba Airlines", code: "AG", icon: "https://images.kiwi.com/airlines/32/AG.png"},
  'AH': {lcc: "0", name: "Air Algerie", code: "AH", icon: "https://images.kiwi.com/airlines/32/AH.png"},
  'AI': {lcc: "0", name: "Air India Limited", code: "AI", icon: "https://images.kiwi.com/airlines/32/AI.png"},
  'AJ': {lcc: "0", name: "Aero Contractors", code: "AJ", icon: "https://images.kiwi.com/airlines/32/AJ.png"},
  'AK': {lcc: "1", name: "AirAsia", code: "AK", icon: "https://images.kiwi.com/airlines/32/AK.png"},
  'AL': {lcc: "0", name: "Skywalk Airlines", code: "AL", icon: "https://images.kiwi.com/airlines/32/AL.png"},
  'ALSA': {lcc: "0", name: "Alsa", code: "ALSA", icon: "https://images.kiwi.com/airlines/32/ALSA.png"},
  'AM': {lcc: "0", name: "AeroM/u00e9xico", code: "AM", icon: "https://images.kiwi.com/airlines/32/AM.png"},
  'AN': {lcc: "0", name: "Ansett Australia", code: "AN", icon: "https://images.kiwi.com/airlines/32/AN.png"},
  'ANDBUS': {lcc: "None", name: "Andbus", code: "ANDBUS", icon: "https://images.kiwi.com/airlines/32/ANDBUS.png"},
  'AO': {lcc: "0", name: "Avianova (Russia)", code: "AO", icon: "https://images.kiwi.com/airlines/32/AO.png"},
  'AP': {lcc: "0", name: "AlbaStar", code: "AP", icon: "https://images.kiwi.com/airlines/32/AP.png"},
  'APPENINO': {lcc: "None", name: "Appenino shuttle ", code: "APPENINO", icon: "https://images.kiwi.com/airlines/32/APPENINO.png"},
  'AQ': {lcc: "1", name: "9 Air", code: "AQ", icon: "https://images.kiwi.com/airlines/32/AQ.png"},
  'AR': {lcc: "0", name: "Aerolineas Argentinas", code: "AR", icon: "https://images.kiwi.com/airlines/32/AR.png"},
  'ARRIVA': {lcc: "None", name: "Arriva", code: "ARRIVA", icon: "https://images.kiwi.com/airlines/32/ARRIVA.png"},
  'AS': {lcc: "0", name: "Alaska Airlines", code: "AS", icon: "https://images.kiwi.com/airlines/32/AS.png"},
  'AT': {lcc: "0", name: "Royal Air Maroc", code: "AT", icon: "https://images.kiwi.com/airlines/32/AT.png"},
  'ATV': {lcc: "None", name: "Avanti Air", code: "ATV", icon: "https://images.kiwi.com/airlines/32/ATV.png"},
  'AU': {lcc: "0", name: "Austral Lineas Aereas", code: "AU", icon: "https://images.kiwi.com/airlines/32/AU.png"},
  'AUTOSTRAD': {lcc: "None", name: "Autostradale", code: "AUTOSTRAD", icon: "https://images.kiwi.com/airlines/32/AUTOSTRAD.png"},
  'AV': {lcc: "0", name: "Avianca", code: "AV", icon: "https://images.kiwi.com/airlines/32/AV.png"},
  'AW': {lcc: "1", name: "Africa World Airlines", code: "AW", icon: "https://images.kiwi.com/airlines/32/AW.png"},
  'AX': {lcc: "0", name: "Trans States Airlines", code: "AX", icon: "https://images.kiwi.com/airlines/32/AX.png"},
  'AY': {lcc: "0", name: "Finnair", code: "AY", icon: "https://images.kiwi.com/airlines/32/AY.png"},
  'AZ': {lcc: "0", name: "Alitalia", code: "AZ", icon: "https://images.kiwi.com/airlines/32/AZ.png"},
  'B0': {lcc: "0", name: "Aws express", code: "B0", icon: "https://images.kiwi.com/airlines/32/B0.png"},
  'B1': {lcc: "0", name: "Baltic Air lines", code: "B1", icon: "https://images.kiwi.com/airlines/32/B1.png"},
  'B2': {lcc: "0", name: "Belavia Belarusian Airlines", code: "B2", icon: "https://images.kiwi.com/airlines/32/B2.png"},
  'B3': {lcc: "0", name: "Bhutan Airlines", code: "B3", icon: "https://images.kiwi.com/airlines/32/B3.png"},
  'B4': {lcc: "0", name: "ZanAir", code: "B4", icon: "https://images.kiwi.com/airlines/32/B4.png"},
  'B5': {lcc: "0", name: "Fly-SAX", code: "B5", icon: "https://images.kiwi.com/airlines/32/B5.png"},
  'B6': {lcc: "1", name: "JetBlue Airways", code: "B6", icon: "https://images.kiwi.com/airlines/32/B6.png"},
  'B7': {lcc: "0", name: "Uni Air", code: "B7", icon: "https://images.kiwi.com/airlines/32/B7.png"},
  'B8': {lcc: "0", name: "Eritrean Airlines", code: "B8", icon: "https://images.kiwi.com/airlines/32/B8.png"},
  'B9': {lcc: "0", name: "Air Bangladesh", code: "B9", icon: "https://images.kiwi.com/airlines/32/B9.png"},
  'BA': {lcc: "0", name: "British Airways", code: "BA", icon: "https://images.kiwi.com/airlines/32/BA.png"},
  'BB': {lcc: "0", name: "Seaborne Airlines", code: "BB", icon: "https://images.kiwi.com/airlines/32/BB.png"},
  'BC': {lcc: "1", name: "Skymark Airlines", code: "BC", icon: "https://images.kiwi.com/airlines/32/BC.png"},
  'BD': {lcc: "0", name: "Cambodia Bayon Airlines", code: "BD", icon: "https://images.kiwi.com/airlines/32/BD.png"},
  'BE': {lcc: "1", name: "flybe", code: "BE", icon: "https://images.kiwi.com/airlines/32/BE.png"},
  'BF': {lcc: "0", name: "French Bee", code: "BF", icon: "https://images.kiwi.com/airlines/32/BF.png"},
  'BG': {lcc: "1", name: "Biman Bangladesh Airlines", code: "BG", icon: "https://images.kiwi.com/airlines/32/BG.png"},
  'BH': {lcc: "0", name: "Hawkair", code: "BH", icon: "https://images.kiwi.com/airlines/32/BH.png"},
  'BI': {lcc: "0", name: "Royal Brunei Airlines", code: "BI", icon: "https://images.kiwi.com/airlines/32/BI.png"},
  'BJ': {lcc: "0", name: "NouvelAir", code: "BJ", icon: "https://images.kiwi.com/airlines/32/BJ.png"},
  'BK': {lcc: "0", name: "Okay Airways", code: "BK", icon: "https://images.kiwi.com/airlines/32/BK.png"},
  'BL': {lcc: "0", name: "Jetstar Pacific", code: "BL", icon: "https://images.kiwi.com/airlines/32/BL.png"},
  'BM': {lcc: "0", name: "flybmi", code: "BM", icon: "https://images.kiwi.com/airlines/32/BM.png"},
  'BN': {lcc: "0", name: "Horizon Airlines", code: "BN", icon: "https://images.kiwi.com/airlines/32/BN.png"},
  'BP': {lcc: "0", name: "Air Botswana", code: "BP", icon: "https://images.kiwi.com/airlines/32/BP.png"},
  'BQ': {lcc: "0", name: "Buquebus L/u00edneas A/u00e9reas", code: "BQ", icon: "https://images.kiwi.com/airlines/32/BQ.png"},
  'BR': {lcc: "1", name: "EVA Air", code: "BR", icon: "https://images.kiwi.com/airlines/32/BR.png"},
  'BS': {lcc: "0", name: "British International Helicopters", code: "BS", icon: "https://images.kiwi.com/airlines/32/BS.png"},
  'BT': {lcc: "1", name: "airBaltic", code: "BT", icon: "https://images.kiwi.com/airlines/32/BT.png"},
  'BU': {lcc: "0", name: "Baikotovitchestrian Airlines ", code: "BU", icon: "https://images.kiwi.com/airlines/32/BU.png"},
  'BUS4YOU': {lcc: "None", name: "Bus4You", code: "BUS4YOU", icon: "https://images.kiwi.com/airlines/32/BUS4YOU.png"},
  'BUSPLANA': {lcc: "None", name: "Busplana", code: "BUSPLANA", icon: "https://images.kiwi.com/airlines/32/BUSPLANA.png"},
  'BUTAAIR': {lcc: "1", name: "Buta Airways", code: "BUTAAIR", icon: "https://images.kiwi.com/airlines/32/BUTAAIR.png"},
  'BV': {lcc: "1", name: "Blue Panorama", code: "BV", icon: "https://images.kiwi.com/airlines/32/BV.png"},
  'BW': {lcc: "0", name: "Caribbean Airlines", code: "BW", icon: "https://images.kiwi.com/airlines/32/BW.png"},
  'BX': {lcc: "1", name: "Air Busan", code: "BX", icon: "https://images.kiwi.com/airlines/32/BX.png"},
  'BY': {lcc: "1", name: "TUI Airways", code: "BY", icon: "https://images.kiwi.com/airlines/32/BY.png"},
  'BZ': {lcc: "0", name: "Black Stallion Airways", code: "BZ", icon: "https://images.kiwi.com/airlines/32/BZ.png"},
  'C0': {lcc: "0", name: "Centralwings", code: "C0", icon: "https://images.kiwi.com/airlines/32/C0.png"},
  'C1': {lcc: "0", name: "CanXpress", code: "C1", icon: "https://images.kiwi.com/airlines/32/C1.png"},
  'C2': {lcc: "0", name: "CanXplorer", code: "C2", icon: "https://images.kiwi.com/airlines/32/C2.png"},
  'C3': {lcc: "0", name: "Contact Air", code: "C3", icon: "https://images.kiwi.com/airlines/32/C3.png"},
  'C4': {lcc: "0", name: "LionXpress", code: "C4", icon: "https://images.kiwi.com/airlines/32/C4.png"},
  'C5': {lcc: "0", name: "CommutAir", code: "C5", icon: "https://images.kiwi.com/airlines/32/C5.png"},
  'C7': {lcc: "1", name: "Cinnamon Air", code: "C7", icon: "https://images.kiwi.com/airlines/32/C7.png"},
  'C9': {lcc: "1", name: "SkyWise", code: "C9", icon: "https://images.kiwi.com/airlines/32/C9.png"},
  'CA': {lcc: "0", name: "Air China", code: "CA", icon: "https://images.kiwi.com/airlines/32/CA.png"},
  'CAT': {lcc: "None", name: "CAT- City Airport Train", code: "CAT", icon: "https://images.kiwi.com/airlines/32/CAT.png"},
  'CB': {lcc: "0", name: "CCML Airlines", code: "CB", icon: "https://images.kiwi.com/airlines/32/CB.png"},
  'CC': {lcc: "0", name: "CM Airlines", code: "CC", icon: "https://images.kiwi.com/airlines/32/CC.png"},
  'CD': {lcc: "0", name: "Corendon Dutch Airlines B.V.", code: "CD", icon: "https://images.kiwi.com/airlines/32/CD.png"},
  'CE': {lcc: "0", name: "Chalair", code: "CE", icon: "https://images.kiwi.com/airlines/32/CE.png"},
  'CF': {lcc: "0", name: "City Airline", code: "CF", icon: "https://images.kiwi.com/airlines/32/CF.png"},
  'CG': {lcc: "0", name: "Airlines PNG", code: "CG", icon: "https://images.kiwi.com/airlines/32/CG.png"},
  'CH': {lcc: "0", name: "Bemidji Airlines", code: "CH", icon: "https://images.kiwi.com/airlines/32/CH.png"},
  'CI': {lcc: "0", name: "China Airlines", code: "CI", icon: "https://images.kiwi.com/airlines/32/CI.png"},
  'CILENTO': {lcc: "None", name: "Cilento", code: "CILENTO", icon: "https://images.kiwi.com/airlines/32/CILENTO.png"},
  'CITYBUSEXP': {lcc: "None", name: "CityBusExpress", code: "CITYBUSEXP", icon: "https://images.kiwi.com/airlines/32/CITYBUSEXP.png"},
  'CITYLINK': {lcc: "None", name: "Citylink", code: "CITYLINK", icon: "https://images.kiwi.com/airlines/32/CITYLINK.png"},
  'CJ': {lcc: "0", name: "BA CityFlyer", code: "CJ", icon: "https://images.kiwi.com/airlines/32/CJ.png"},
  'CL': {lcc: "0", name: "Lufthansa CityLine", code: "CL", icon: "https://images.kiwi.com/airlines/32/CL.png"},
  'CM': {lcc: "0", name: "Copa Airlines", code: "CM", icon: "https://images.kiwi.com/airlines/32/CM.png"},
  'CN': {lcc: "0", name: "Canadian National Airways", code: "CN", icon: "https://images.kiwi.com/airlines/32/CN.png"},
  'CO': {lcc: "0", name: "Cobalt Air", code: "CO", icon: "https://images.kiwi.com/airlines/32/CO.png"},
  'CP': {lcc: "0", name: "Compass Airlines", code: "CP", icon: "https://images.kiwi.com/airlines/32/CP.png"},
  'CQ': {lcc: "0", name: "Coastal Aviation", code: "CQ", icon: "https://images.kiwi.com/airlines/32/CQ.png"},
  'CS': {lcc: "0", name: "Continental Micronesia", code: "CS", icon: "https://images.kiwi.com/airlines/32/CS.png"},
  'CT': {lcc: "0", name: "Alitalia Cityliner", code: "CT", icon: "https://images.kiwi.com/airlines/32/CT.png"},
  'CU': {lcc: "0", name: "Cubana de Aviaci/u00f3n", code: "CU", icon: "https://images.kiwi.com/airlines/32/CU.png"},
  'CV': {lcc: "0", name: "Air Chathams", code: "CV", icon: "https://images.kiwi.com/airlines/32/CV.png"},
  'CW': {lcc: "0", name: "Air Marshall Islands", code: "CW", icon: "https://images.kiwi.com/airlines/32/CW.png"},
  'CX': {lcc: "0", name: "Cathay Pacific", code: "CX", icon: "https://images.kiwi.com/airlines/32/CX.png"},
  'CY': {lcc: "0", name: "Cyprus Airways", code: "CY", icon: "https://images.kiwi.com/airlines/32/CY.png"},
  'CZ': {lcc: "0", name: "China Southern Airlines", code: "CZ", icon: "https://images.kiwi.com/airlines/32/CZ.png"},
  'CZECHRAIL': {lcc: "None", name: "Czech Rail", code: "CZECHRAIL", icon: "https://images.kiwi.com/airlines/32/CZECHRAIL.png"},
  'D1': {lcc: "0", name: "Domenican Airlines", code: "D1", icon: "https://images.kiwi.com/airlines/32/D1.png"},
  'D2': {lcc: "1", name: "Severstal Air Company", code: "D2", icon: "https://images.kiwi.com/airlines/32/D2.png"},
  'D3': {lcc: "0", name: "Daallo Airlines", code: "D3", icon: "https://images.kiwi.com/airlines/32/D3.png"},
  'D4': {lcc: "0", name: "Aerodart", code: "D4", icon: "https://images.kiwi.com/airlines/32/D4.png"},
  'D6': {lcc: "0", name: "Interair South Africa", code: "D6", icon: "https://images.kiwi.com/airlines/32/D6.png"},
  'D7': {lcc: "0", name: "AirAsia X", code: "D7", icon: "https://images.kiwi.com/airlines/32/D7.png"},
  'D8': {lcc: "0", name: "Norwegian International", code: "D8", icon: "https://images.kiwi.com/airlines/32/D8.png"},
  'D9': {lcc: "0", name: "Aeroflot-Don", code: "D9", icon: "https://images.kiwi.com/airlines/32/D9.png"},
  'DA': {lcc: "0", name: "Dana Air", code: "DA", icon: "https://images.kiwi.com/airlines/32/DA.png"},
  'DB': {lcc: "0", name: "Brit Air", code: "DB", icon: "https://images.kiwi.com/airlines/32/DB.png"},
  'DC': {lcc: "1", name: "Braathens Regional Airways", code: "DC", icon: "https://images.kiwi.com/airlines/32/DC.png"},
  'DD': {lcc: "1", name: "Nok Air", code: "DD", icon: "https://images.kiwi.com/airlines/32/DD.png"},
  'DE': {lcc: "1", name: "Condor", code: "DE", icon: "https://images.kiwi.com/airlines/32/DE.png"},
  'DEINBUS': {lcc: "0", name: "DeinBus", code: "DEINBUS", icon: "https://images.kiwi.com/airlines/32/DEINBUS.png"},
  'DF': {lcc: "0", name: "Michael Airlines", code: "DF", icon: "https://images.kiwi.com/airlines/32/DF.png"},
  'DG': {lcc: "0", name: "CebGo", code: "DG", icon: "https://images.kiwi.com/airlines/32/DG.png"},
  'DH': {lcc: "0", name: "Dennis Sky", code: "DH", icon: "https://images.kiwi.com/airlines/32/DH.png"},
  'DI': {lcc: "0", name: "Norwegian Air UK", code: "DI", icon: "https://images.kiwi.com/airlines/32/DI.png"},
  'DJ': {lcc: "1", name: "AirAsia Japan", code: "DJ", icon: "https://images.kiwi.com/airlines/32/DJ.png"},
  'DK': {lcc: "0", name: "Eastland Air", code: "DK", icon: "https://images.kiwi.com/airlines/32/DK.png"},
  'DL': {lcc: "0", name: "Delta Air Lines", code: "DL", icon: "https://images.kiwi.com/airlines/32/DL.png"},
  'DM': {lcc: "0", name: "Maersk", code: "DM", icon: "https://images.kiwi.com/airlines/32/DM.png"},
  'DN': {lcc: "0", name: "Norwegian Air Argentina", code: "DN", icon: "https://images.kiwi.com/airlines/32/DN.png"},
  'DO': {lcc: "0", name: "Dominicana de Aviaci", code: "DO", icon: "https://images.kiwi.com/airlines/32/DO.png"},
  'DOMO': {lcc: "None", name: "Domo Swiss Express", code: "DOMO", icon: "https://images.kiwi.com/airlines/32/DOMO.png"},
  'DP': {lcc: "1", name: "Pobeda", code: "DP", icon: "https://images.kiwi.com/airlines/32/DP.png"},
  'DQ': {lcc: "0", name: "Coastal Air", code: "DQ", icon: "https://images.kiwi.com/airlines/32/DQ.png"},
  'DR': {lcc: "1", name: "Ruili Airlines", code: "DR", icon: "https://images.kiwi.com/airlines/32/DR.png"},
  'DS': {lcc: "0", name: "EasyJet (DS)", code: "DS", icon: "https://images.kiwi.com/airlines/32/DS.png"},
  'DT': {lcc: "0", name: "TAAG Angola Airlines", code: "DT", icon: "https://images.kiwi.com/airlines/32/DT.png"},
  'DV': {lcc: "1", name: "Scat Air", code: "DV", icon: "https://images.kiwi.com/airlines/32/DV.png"},
  'DX': {lcc: "0", name: "DAT Danish Air Transport", code: "DX", icon: "https://images.kiwi.com/airlines/32/DX.png"},
  'DY': {lcc: "1", name: "Norwegian", code: "DY", icon: "https://images.kiwi.com/airlines/32/DY.png"},
  'DZ': {lcc: "0", name: "Donghai Airlines", code: "DZ", icon: "https://images.kiwi.com/airlines/32/DZ.png"},
  'E1': {lcc: "0", name: "Usa Sky Cargo", code: "E1", icon: "https://images.kiwi.com/airlines/32/E1.png"},
  'E2': {lcc: "0", name: "Eurowings Europe", code: "E2", icon: "https://images.kiwi.com/airlines/32/E2.png"},
  'E3': {lcc: "0", name: "Domodedovo Airlines", code: "E3", icon: "https://images.kiwi.com/airlines/32/E3.png"},
  'E4': {lcc: "0", name: "Elysian Airlines", code: "E4", icon: "https://images.kiwi.com/airlines/32/E4.png"},
  'E5': {lcc: "0", name: "Air Arabia Egypt", code: "E5", icon: "https://images.kiwi.com/airlines/32/E5.png"},
  'E9': {lcc: "0", name: "Evelop Airlines", code: "E9", icon: "https://images.kiwi.com/airlines/32/E9.png"},
  'EA': {lcc: "0", name: "European Air Express", code: "EA", icon: "https://images.kiwi.com/airlines/32/EA.png"},
  'EB': {lcc: "1", name: "Wamos Air", code: "EB", icon: "https://images.kiwi.com/airlines/32/EB.png"},
  'EC': {lcc: "0", name: "Avialeasing Aviation Company", code: "EC", icon: "https://images.kiwi.com/airlines/32/EC.png"},
  'ED': {lcc: "1", name: "Airblue", code: "ED", icon: "https://images.kiwi.com/airlines/32/ED.png"},
  'EE': {lcc: "0", name: "RegionalJet", code: "EE", icon: "https://images.kiwi.com/airlines/32/EE.png"},
  'EF': {lcc: "1", name: "EasyFly", code: "EF", icon: "https://images.kiwi.com/airlines/32/EF.png"},
  'EG': {lcc: "0", name: "Ernest Airlines", code: "EG", icon: "https://images.kiwi.com/airlines/32/EG.png"},
  'EH': {lcc: "0", name: "ANA Wings", code: "EH", icon: "https://images.kiwi.com/airlines/32/EH.png"},
  'EI': {lcc: "1", name: "Aer Lingus", code: "EI", icon: "https://images.kiwi.com/airlines/32/EI.png"},
  'EILAT': {lcc: "None", name: "Eilat Shuttle", code: "EILAT", icon: "https://images.kiwi.com/airlines/32/EILAT.png"},
  'EJ': {lcc: "0", name: "New England Airlines", code: "EJ", icon: "https://images.kiwi.com/airlines/32/EJ.png"},
  'EJR': {lcc: "None", name: "EJR /u2013 East Japan Rail Company", code: "EJR", icon: "https://images.kiwi.com/airlines/32/EJR.png"},
  'EK': {lcc: "0", name: "Emirates", code: "EK", icon: "https://images.kiwi.com/airlines/32/EK.png"},
  'EL': {lcc: "1", name: "Ellinair", code: "EL", icon: "https://images.kiwi.com/airlines/32/EL.png"},
  'EM': {lcc: "0", name: "Empire Airlines", code: "EM", icon: "https://images.kiwi.com/airlines/32/EM.png"},
  'EN': {lcc: "0", name: "Air Dolomiti", code: "EN", icon: "https://images.kiwi.com/airlines/32/EN.png"},
  'ENZ': {lcc: "None", name: "Jota Aviation", code: "ENZ", icon: "https://images.kiwi.com/airlines/32/ENZ.png"},
  'EO': {lcc: "0", name: "Express One International", code: "EO", icon: "https://images.kiwi.com/airlines/32/EO.png"},
  'EP': {lcc: "0", name: "Iran Aseman Airlines", code: "EP", icon: "https://images.kiwi.com/airlines/32/EP.png"},
  'EQ': {lcc: "0", name: "TAME", code: "EQ", icon: "https://images.kiwi.com/airlines/32/EQ.png"},
  'ES': {lcc: "0", name: "Estelar Latinoamerica", code: "ES", icon: "https://images.kiwi.com/airlines/32/ES.png"},
  'ESTLOREK': {lcc: "None", name: "EST Lorek", code: "ESTLOREK", icon: "https://images.kiwi.com/airlines/32/ESTLOREK.png"},
  'ET': {lcc: "0", name: "Ethiopian Airlines", code: "ET", icon: "https://images.kiwi.com/airlines/32/ET.png"},
  'EU': {lcc: "0", name: "Chengdu Airlines", code: "EU", icon: "https://images.kiwi.com/airlines/32/EU.png"},
  'EUROLINES': { lcc: "0", name: "Eurolines", code: "EUROLINES", icon: "https://images.kiwi.com/airlines/32/EUROLINES.png"},
  'EV': {lcc: "0", name: "ExpressJet", code: "EV", icon: "https://images.kiwi.com/airlines/32/EV.png"},
  'EW': {lcc: "1", name: "Eurowings", code: "EW", icon: "https://images.kiwi.com/airlines/32/EW.png"},
  'EXPRESSBUS': {lcc: "None", name: "ExpressBus", code: "EXPRESSBUS", icon: "https://images.kiwi.com/airlines/32/EXPRESSBUS.png"},
  'EXPRTRANS': {lcc: "None", name: "Express transfer", code: "EXPRTRANS", icon: "https://images.kiwi.com/airlines/32/EXPRTRANS.png"},
  'EY': {lcc: "0", name: "Etihad Airways", code: "EY", icon: "https://images.kiwi.com/airlines/32/EY.png"},
  'EZ': {lcc: "0", name: "Evergreen International Airlines", code: "EZ", icon: "https://images.kiwi.com/airlines/32/EZ.png"},
  'F1': {lcc: "0", name: "Fly Brasil", code: "F1", icon: "https://images.kiwi.com/airlines/32/F1.png"},
  'F2': {lcc: "0", name: "Safarilink Aviation", code: "F2", icon: "https://images.kiwi.com/airlines/32/F2.png"},
  'F3': {lcc: "0", name: "Flyadeal", code: "F3", icon: "https://images.kiwi.com/airlines/32/F3.png"},
  'F7': {lcc: "0", name: "Etihad Regional", code: "F7", icon: "https://images.kiwi.com/airlines/32/F7.png"},
  'F8': {lcc: "0", name: "Flair Airlines", code: "F8", icon: "https://images.kiwi.com/airlines/32/F8.png"},
  'F9': {lcc: "1", name: "Frontier Airlines", code: "F9", icon: "https://images.kiwi.com/airlines/32/F9.png"},
  'FA': {lcc: "1", name: "Fly Safair", code: "FA", icon: "https://images.kiwi.com/airlines/32/FA.png"},
  'FB': {lcc: "0", name: "Bulgaria Air", code: "FB", icon: "https://images.kiwi.com/airlines/32/FB.png"},
  'FC': {lcc: "1", name: "Viva Air", code: "FC", icon: "https://images.kiwi.com/airlines/32/FC.png"},
  'FD': {lcc: "0", name: "Thai AirAsia", code: "FD", icon: "https://images.kiwi.com/airlines/32/FD.png"},
  'FE': {lcc: "None", name: "Far Eastern Air Transport", code: "FE", icon: "https://images.kiwi.com/airlines/32/FE.png"},
  'FG': {lcc: "0", name: "Ariana Afghan Airlines", code: "FG", icon: "https://images.kiwi.com/airlines/32/FG.png"},
  'FH': {lcc: "0", name: "Freebird Airlines", code: "FH", icon: "https://images.kiwi.com/airlines/32/FH.png"},
  'FI': {lcc: "0", name: "Icelandair", code: "FI", icon: "https://images.kiwi.com/airlines/32/FI.png"},
  'FIUMICINO': {lcc: "None", name: "Fiumicino express", code: "FIUMICINO", icon: "https://images.kiwi.com/airlines/32/FIUMICINO.png"},
  'FJ': {lcc: "0", name: "Fiji Airways", code: "FJ", icon: "https://images.kiwi.com/airlines/32/FJ.png"},
  'FK': {lcc: "0", name: "Africa West", code: "FK", icon: "https://images.kiwi.com/airlines/32/FK.png"},
  'FL': {lcc: "0", name: "AirTran Airways", code: "FL", icon: "https://images.kiwi.com/airlines/32/FL.png"},
  'FLIBCO': {lcc: "0", name: "Flibco shuttle", code: "FLIBCO", icon: "https://images.kiwi.com/airlines/32/FLIBCO.png"},
  'FLIXBUS': {lcc: "0", name: "Flixbus", code: "FLIXBUS", icon: "https://images.kiwi.com/airlines/32/FLIXBUS.png"},
  'FLIXTRAIN': { lcc: "0", name: "Flixtrain", code: "FLIXTRAIN", icon: "https://images.kiwi.com/airlines/32/FLIXTRAIN.png" },
  'FM': {lcc: "0", name: "Shanghai Airlines", code: "FM", icon: "https://images.kiwi.com/airlines/32/FM.png"},
  'FN': {lcc: "1", name: "Fastjet", code: "FN", icon: "https://images.kiwi.com/airlines/32/FN.png"},
  'FO': {lcc: "1", name: "Flybondi", code: "FO", icon: "https://images.kiwi.com/airlines/32/FO.png"},
  'FP': {lcc: "0", name: "Pelican Airlines", code: "FP", icon: "https://images.kiwi.com/airlines/32/FP.png"},
  'FQ': {lcc: "0", name: "Thomas Cook Airlines", code: "FQ", icon: "https://images.kiwi.com/airlines/32/FQ.png"},
  'FR': {lcc: "1", name: "Ryanair", code: "FR", icon: "https://images.kiwi.com/airlines/32/FR.png"},
  'FS': {lcc: "0", name: "Servicios de Transportes A", code: "FS", icon: "https://images.kiwi.com/airlines/32/FS.png"},
  'FT': {lcc: "0", name: "FlyEgypt FT", code: "FT", icon: "https://images.kiwi.com/airlines/32/FT.png"},
  'FU': {lcc: "0", name: "Felix Airways", code: "FU", icon: "https://images.kiwi.com/airlines/32/FU.png"},
  'FV': {lcc: "0", name: "Rossiya-Russian Airlines", code: "FV", icon: "https://images.kiwi.com/airlines/32/FV.png"},
  'FW': {lcc: "0", name: "Ibex Airlines", code: "FW", icon: "https://images.kiwi.com/airlines/32/FW.png"},
  'FY': {lcc: "1", name: "Firefly", code: "FY", icon: "https://images.kiwi.com/airlines/32/FY.png"},
  'FZ': {lcc: "1", name: "Fly Dubai", code: "FZ", icon: "https://images.kiwi.com/airlines/32/FZ.png"},
  'G0': {lcc: "0", name: "Ghana International Airlines", code: "G0", icon: "https://images.kiwi.com/airlines/32/G0.png"},
  'G1': {lcc: "0", name: "Indya Airline Group", code: "G1", icon: "https://images.kiwi.com/airlines/32/G1.png"},
  'G3': {lcc: "1", name: "Gol Transportes A/u00e9reos", code: "G3", icon: "https://images.kiwi.com/airlines/32/G3.png"},
  'G4': {lcc: "1", name: "Allegiant Air", code: "G4", icon: "https://images.kiwi.com/airlines/32/G4.png"},
  'G5': {lcc: "0", name: "China Express Airlines", code: "G5", icon: "https://images.kiwi.com/airlines/32/G5.png"},
  'G6': {lcc: "0", name: "Air Volga", code: "G6", icon: "https://images.kiwi.com/airlines/32/G6.png"},
  'G7': {lcc: "0", name: "GoJet Airlines", code: "G7", icon: "https://images.kiwi.com/airlines/32/G7.png"},
  'G8': {lcc: "1", name: "Go Air", code: "G8", icon: "https://images.kiwi.com/airlines/32/G8.png"},
  'G9': {lcc: "1", name: "Air Arabia", code: "G9", icon: "https://images.kiwi.com/airlines/32/G9.png"},
  'GA': {lcc: "0", name: "Garuda Indonesia", code: "GA", icon: "https://images.kiwi.com/airlines/32/GA.png"},
  'GALICJAEX': {lcc: "None", name: "Galicja Express", code: "GALICJAEX", icon: "https://images.kiwi.com/airlines/32/GALICJAEX.png"},
  'GB': {lcc: "0", name: "BRAZIL AIR", code: "GB", icon: "https://images.kiwi.com/airlines/32/GB.png"},
  'GE': {lcc: "0", name: "TransAsia Airways", code: "GE", icon: "https://images.kiwi.com/airlines/32/GE.png"},
  'GEORGIBUS': {lcc: "None", name: "Georgian Bus", code: "GEORGIBUS", icon: "https://images.kiwi.com/airlines/32/GEORGIBUS.png"},
  'GF': {lcc: "0", name: "Gulf Air Bahrain", code: "GF", icon: "https://images.kiwi.com/airlines/32/GF.png"},
  'GG': {lcc: "0", name: "Air Guyane", code: "GG", icon: "https://images.kiwi.com/airlines/32/GG.png"},
  'GH': {lcc: "0", name: "Globus", code: "GH", icon: "https://images.kiwi.com/airlines/32/GH.png"},
  'GI': {lcc: "0", name: "Itek Air", code: "GI", icon: "https://images.kiwi.com/airlines/32/GI.png"},
  'GJ': {lcc: "0", name: "Loong Air", code: "GJ", icon: "https://images.kiwi.com/airlines/32/GJ.png"},
  'GK': {lcc: "0", name: "Jetstar Japan", code: "GK", icon: "https://images.kiwi.com/airlines/32/GK.png"},
  'GL': {lcc: "0", name: "Air Greenland", code: "GL", icon: "https://images.kiwi.com/airlines/32/GL.png"},
  'GLOBTOUR': {lcc: "None", name: "Globtour", code: "GLOBTOUR", icon: "https://images.kiwi.com/airlines/32/GLOBTOUR.png"},
  'GM': {lcc: "0", name: "Germania Flug", code: "GM", icon: "https://images.kiwi.com/airlines/32/GM.png"},
  'GN': {lcc: "0", name: "GNB Linhas Aereas", code: "GN", icon: "https://images.kiwi.com/airlines/32/GN.png"},
  'GO': {lcc: "0", name: "Kuzu Airlines Cargo", code: "GO", icon: "https://images.kiwi.com/airlines/32/GO.png"},
  'GOBUS': {lcc: "None", name: "Gobus", code: "GOBUS", icon: "https://images.kiwi.com/airlines/32/GOBUS.png"},
  'GOOPTI': {lcc: "None", name: "GoOpti", code: "GOOPTI", icon: "https://images.kiwi.com/airlines/32/GOOPTI.png"},
  'GP': {lcc: "0", name: "APG Airlines", code: "GP", icon: "https://images.kiwi.com/airlines/32/GP.png"},
  'GQ': {lcc: "0", name: "Sky Express", code: "GQ", icon: "https://images.kiwi.com/airlines/32/GQ.png"},
  'GR': {lcc: "0", name: "Aurigny Air Services", code: "GR", icon: "https://images.kiwi.com/airlines/32/GR.png"},
  'GREYHOUND': {lcc: "0", name: "Greyhound", code: "GREYHOUND", icon: "https://images.kiwi.com/airlines/32/GREYHOUND.png"},
  'GS': {lcc: "0", name: "Tianjin Airlines", code: "GS", icon: "https://images.kiwi.com/airlines/32/GS.png"},
  'GT': {lcc: "1", name: "Air Guilin", code: "GT", icon: "https://images.kiwi.com/airlines/32/GT.png"},
  'GU': {lcc: "1", name: "Avianca Guatemala", code: "GU", icon: "https://images.kiwi.com/airlines/32/GU.png"},
  'GV': {lcc: "0", name: "Grant Aviation", code: "GV", icon: "https://images.kiwi.com/airlines/32/GV.png"},
  'GW': {lcc: "0", name: "Kuban Airlines", code: "GW", icon: "https://images.kiwi.com/airlines/32/GW.png"},
  'GX': {lcc: "1", name: "GX airlines", code: "GX", icon: "https://images.kiwi.com/airlines/32/GX.png"},
  'GY': {lcc: "0", name: "Colorful Guizhou Airlines", code: "GY", icon: "https://images.kiwi.com/airlines/32/GY.png"},
  'GZ': {lcc: "0", name: "Air Rarotonga", code: "GZ", icon: "https://images.kiwi.com/airlines/32/GZ.png"},
  'H1': {lcc: "0", name: "Hahn Air", code: "H1", icon: "https://images.kiwi.com/airlines/32/H1.png"},
  'H2': {lcc: "1", name: "Sky Airline", code: "H2", icon: "https://images.kiwi.com/airlines/32/H2.png"},
  'H3': {lcc: "0", name: "Harbour Air (Priv)", code: "H3", icon: "https://images.kiwi.com/airlines/32/H3.png"},
  'H5': {lcc: "0", name: "I-Fly", code: "H5", icon: "https://images.kiwi.com/airlines/32/H5.png"},
  'H6': {lcc: "0", name: "Bulgarian Air Charter", code: "H6", icon: "https://images.kiwi.com/airlines/32/H6.png"},
  'H7': {lcc: "0", name: "Eagle Air", code: "H7", icon: "https://images.kiwi.com/airlines/32/H7.png"},
  'H8': {lcc: "0", name: "Latin American Wings", code: "H8", icon: "https://images.kiwi.com/airlines/32/H8.png"},
  'H9': {lcc: "0", name: "Himalaya Airlines", code: "H9", icon: "https://images.kiwi.com/airlines/32/H9.png"},
  'HA': {lcc: "0", name: "Hawaiian Airlines", code: "HA", icon: "https://images.kiwi.com/airlines/32/HA.png"},
  'HC': {lcc: "0", name: "Air Senegal", code: "HC", icon: "https://images.kiwi.com/airlines/32/HC.png"},
  'HD': {lcc: "1", name: "Air Do", code: "HD", icon: "https://images.kiwi.com/airlines/32/HD.png"},
  'HE': {lcc: "0", name: "Luftfahrtgesellschaft Walter", code: "HE", icon: "https://images.kiwi.com/airlines/32/HE.png"},
  'HF': {lcc: "0", name: "Air Cote d/u0027Ivoire", code: "HF", icon: "https://images.kiwi.com/airlines/32/HF.png"},
  'HG': {lcc: "0", name: "Niki", code: "HG", icon: "https://images.kiwi.com/airlines/32/HG.png"},
  'HH': {lcc: "0", name: "Air Hamburg (AHO)", code: "HH", icon: "https://images.kiwi.com/airlines/32/HH.png"},
  'HI': {lcc: "0", name: "Papillon Grand Canyon Helicopters", code: "HI", icon: "https://images.kiwi.com/airlines/32/HI.png"},
  'HK': {lcc: "0", name: "Yangon Airways", code: "HK", icon: "https://images.kiwi.com/airlines/32/HK.png"},
  'HM': {lcc: "0", name: "Air Seychelles", code: "HM", icon: "https://images.kiwi.com/airlines/32/HM.png"},
  'HN': {lcc: "0", name: "Hankook Airline", code: "HN", icon: "https://images.kiwi.com/airlines/32/HN.png"},
  'HO': {lcc: "1", name: "Juneyao Airlines", code: "HO", icon: "https://images.kiwi.com/airlines/32/HO.png"},
  'HP': {lcc: "0", name: "America West Airlines", code: "HP", icon: "https://images.kiwi.com/airlines/32/HP.png"},
  'HQ': {lcc: "0", name: "Thomas Cook Belgium", code: "HQ", icon: "https://images.kiwi.com/airlines/32/HQ.png"},
  'HR': {lcc: "0", name: "Hahn Airlines", code: "HR", icon: "https://images.kiwi.com/airlines/32/HR.png"},
  'HT': {lcc: "0", name: "Hellenic Imperial Airways", code: "HT", icon: "https://images.kiwi.com/airlines/32/HT.png"},
  'HU': {lcc: "0", name: "Hainan Airlines", code: "HU", icon: "https://images.kiwi.com/airlines/32/HU.png"},
  'HV': {lcc: "1", name: "Transavia", code: "HV", icon: "https://images.kiwi.com/airlines/32/HV.png"},
  'HW': {lcc: "0", name: "Hello", code: "HW", icon: "https://images.kiwi.com/airlines/32/HW.png"},
  'HX': {lcc: "1", name: "Hong Kong Airlines", code: "HX", icon: "https://images.kiwi.com/airlines/32/HX.png"},
  'HY': {lcc: "0", name: "Uzbekistan Airways", code: "HY", icon: "https://images.kiwi.com/airlines/32/HY.png"},
  'HZ': {lcc: "0", name: "Aurora Airlines", code: "HZ", icon: "https://images.kiwi.com/airlines/32/HZ.png"},
  'I0': {lcc: "None", name: "LEVEL operated by Iberia", code: "I0", icon: "https://images.kiwi.com/airlines/32/I0.png"},
  'I2': {lcc: "0", name: "Iberia Express", code: "I2", icon: "https://images.kiwi.com/airlines/32/I2.png"},
  'I4': {lcc: "0", name: "I-Fly", code: "I4", icon: "https://images.kiwi.com/airlines/32/I4.png"},
  'I5': {lcc: "0", name: "AirAsia India", code: "I5", icon: "https://images.kiwi.com/airlines/32/I5.png"},
  'I6': {lcc: "0", name: "Air indus", code: "I6", icon: "https://images.kiwi.com/airlines/32/I6.png"},
  'I7': {lcc: "0", name: "Paramount Airways", code: "I7", icon: "https://images.kiwi.com/airlines/32/I7.png"},
  'I8': {lcc: "0", name: "Izhavia", code: "I8", icon: "https://images.kiwi.com/airlines/32/I8.png"},
  'I9': {lcc: "0", name: "Air Italy", code: "I9", icon: "https://images.kiwi.com/airlines/32/I9.png"},
  'IA': {lcc: "0", name: "Iraqi Airways", code: "IA", icon: "https://images.kiwi.com/airlines/32/IA.png"},
  'IB': {lcc: "0", name: "Iberia Airlines", code: "IB", icon: "https://images.kiwi.com/airlines/32/IB.png"},
  'IC': {lcc: "0", name: "Indian Airlines", code: "IC", icon: "https://images.kiwi.com/airlines/32/IC.png"},
  'ICBUS': {lcc: "0", name: "IC Bus", code: "ICBUS", icon: "https://images.kiwi.com/airlines/32/ICBUS.png"},
  'ID': {lcc: "1", name: "Batik Air", code: "ID", icon: "https://images.kiwi.com/airlines/32/ID.png"},
  'IE': {lcc: "0", name: "Solomon Airlines", code: "IE", icon: "https://images.kiwi.com/airlines/32/IE.png"},
  'IF': {lcc: "0", name: "Fly Baghdad Airlines", code: "IF", icon: "https://images.kiwi.com/airlines/32/IF.png"},
  'IG': {lcc: "1", name: "Air Italy", code: "IG", icon: "https://images.kiwi.com/airlines/32/IG.png"},
  'II': {lcc: "0", name: "LSM International ", code: "II", icon: "https://images.kiwi.com/airlines/32/II.png"},
  'IK': {lcc: "0", name: "Pegas Fly", code: "IK", icon: "https://images.kiwi.com/airlines/32/IK.png"},
  'IN': {lcc: "0", name: "Nam Air", code: "IN", icon: "https://images.kiwi.com/airlines/32/IN.png"},
  'INTERGLOB': {lcc: "None", name: "Follow me! Interglobus", code: "INTERGLOB", icon: "https://images.kiwi.com/airlines/32/INTERGLOB.png"},
  'IO': {lcc: "1", name: "IrAero", code: "IO", icon: "https://images.kiwi.com/airlines/32/IO.png"},
  'IP': {lcc: "0", name: "Island Spirit", code: "IP", icon: "https://images.kiwi.com/airlines/32/IP.png"},
  'IQ': {lcc: "0", name: "Qazaq Air", code: "IQ", icon: "https://images.kiwi.com/airlines/32/IQ.png"},
  'IR': {lcc: "0", name: "Iran Air", code: "IR", icon: "https://images.kiwi.com/airlines/32/IR.png"},
  'IRCITYLINK': {lcc: "None", name: "Irish Citylink", code: "IRCITYLINK", icon: "https://images.kiwi.com/airlines/32/IRCITYLINK.png"},
  'IS': {lcc: "0", name: "Fly Ais Airlines", code: "IS", icon: "https://images.kiwi.com/airlines/32/IS.png"},
  'ISILINES': {lcc: "0", name: "Isilines", code: "ISILINES", icon: "https://images.kiwi.com/airlines/32/ISILINES.png"},
  'IT': {lcc: "0", name: "Tigerair Taiwan", code: "IT", icon: "https://images.kiwi.com/airlines/32/IT.png"},
  'ITALONTV': {lcc: "None", name: "Italo NTV", code: "ITALONTV", icon: "https://images.kiwi.com/airlines/32/ITALONTV.png"},
  'IV': {lcc: "0", name: "Wind Jet", code: "IV", icon: "https://images.kiwi.com/airlines/32/IV.png"},
  'IW': {lcc: "0", name: "Wings Air", code: "IW", icon: "https://images.kiwi.com/airlines/32/IW.png"},
  'IX': {lcc: "1", name: "Air India Express", code: "IX", icon: "https://images.kiwi.com/airlines/32/IX.png"},
  'IY': {lcc: "0", name: "Yemenia", code: "IY", icon: "https://images.kiwi.com/airlines/32/IY.png"},
  'IZ': {lcc: "1", name: "Arkia", code: "IZ", icon: "https://images.kiwi.com/airlines/32/IZ.png"},
  'J1': {lcc: "0", name: "One Jet", code: "J1", icon: "https://images.kiwi.com/airlines/32/J1.png"},
  'J2': {lcc: "0", name: "Azerbaijan Airlines", code: "J2", icon: "https://images.kiwi.com/airlines/32/J2.png"},
  'J3': {lcc: "0", name: "Northwestern Air", code: "J3", icon: "https://images.kiwi.com/airlines/32/J3.png"},
  'J4': {lcc: "0", name: "ALAK", code: "J4", icon: "https://images.kiwi.com/airlines/32/J4.png"},
  'J5': {lcc: "0", name: "Alaska Seaplane Service", code: "J5", icon: "https://images.kiwi.com/airlines/32/J5.png"},
  'J7': {lcc: "1", name: "Greenland Express", code: "J7", icon: "https://images.kiwi.com/airlines/32/J7.png"},
  'J8': {lcc: "0", name: "Berjaya Air", code: "J8", icon: "https://images.kiwi.com/airlines/32/J8.png"},
  'J9': {lcc: "1", name: "Jazeera Airways", code: "J9", icon: "https://images.kiwi.com/airlines/32/J9.png"},
  'JA': {lcc: "0", name: "JetSMART", code: "JA", icon: "https://images.kiwi.com/airlines/32/JA.png"},
  'JB': {lcc: "0", name: "Helijet", code: "JB", icon: "https://images.kiwi.com/airlines/32/JB.png"},
  'JC': {lcc: "0", name: "JAL Express", code: "JC", icon: "https://images.kiwi.com/airlines/32/JC.png"},
  'JD': {lcc: "0", name: "Beijing Capital Airlines", code: "JD", icon: "https://images.kiwi.com/airlines/32/JD.png"},
  'JE': {lcc: "1", name: "Mango", code: "JE", icon: "https://images.kiwi.com/airlines/32/JE.png"},
  'JF': {lcc: "0", name: "Jetairfly", code: "JF", icon: "https://images.kiwi.com/airlines/32/JF.png"},
  'JG': {lcc: "1", name: "JetGo", code: "JG", icon: "https://images.kiwi.com/airlines/32/JG.png"},
  'JH': {lcc: "0", name: "Fuji Dream Airlines", code: "JH", icon: "https://images.kiwi.com/airlines/32/JH.png"},
  'JI': {lcc: "0", name: "Meraj Air", code: "JI", icon: "https://images.kiwi.com/airlines/32/JI.png"},
  'JJ': {lcc: "0", name: "LATAM Brasil", code: "JJ", icon: "https://images.kiwi.com/airlines/32/JJ.png"},
  'JK': {lcc: "0", name: "Spanair", code: "JK", icon: "https://images.kiwi.com/airlines/32/JK.png"},
  'JL': {lcc: "0", name: "Japan Airlines", code: "JL", icon: "https://images.kiwi.com/airlines/32/JL.png"},
  'JM': {lcc: "0", name: "Jambojet", code: "JM", icon: "https://images.kiwi.com/airlines/32/JM.png"},
  'JN': {lcc: "0", name: "JOON", code: "JN", icon: "https://images.kiwi.com/airlines/32/JN.png"},
  'JO': {lcc: "0", name: "JALways", code: "JO", icon: "https://images.kiwi.com/airlines/32/JO.png"},
  'JP': {lcc: "0", name: "Adria Airways", code: "JP", icon: "https://images.kiwi.com/airlines/32/JP.png"},
  'JQ': {lcc: "1", name: "Jetstar Airways", code: "JQ", icon: "https://images.kiwi.com/airlines/32/JQ.png"},
  'JR': {lcc: "0", name: "Joy Air", code: "JR", icon: "https://images.kiwi.com/airlines/32/JR.png"},
  'JS': {lcc: "0", name: "Air Koryo", code: "JS", icon: "https://images.kiwi.com/airlines/32/JS.png"},
  'JT': {lcc: "1", name: "Lion Air", code: "JT", icon: "https://images.kiwi.com/airlines/32/JT.png"},
  'JU': {lcc: "0", name: "Air Serbia", code: "JU", icon: "https://images.kiwi.com/airlines/32/JU.png"},
  'JV': {lcc: "0", name: "Bearskin Lake Air Service", code: "JV", icon: "https://images.kiwi.com/airlines/32/JV.png"},
  'JW': {lcc: "1", name: "Vanilla Air", code: "JW", icon: "https://images.kiwi.com/airlines/32/JW.png"},
  'JX': {lcc: "1", name: "Jambojet", code: "JX", icon: "https://images.kiwi.com/airlines/32/JX.png"},
  'JY': {lcc: "0", name: "interCaribbean Airways", code: "JY", icon: "https://images.kiwi.com/airlines/32/JY.png"},
  'JZ': {lcc: "0", name: "Skyways Express", code: "JZ", icon: "https://images.kiwi.com/airlines/32/JZ.png"},
  'K1': {lcc: "0", name: "Kostromskie avialinii", code: "K1", icon: "https://images.kiwi.com/airlines/32/K1.png"},
  'K2': {lcc: "0", name: "EuroLot", code: "K2", icon: "https://images.kiwi.com/airlines/32/K2.png"},
  'K5': {lcc: "0", name: "SeaPort Airlines", code: "K5", icon: "https://images.kiwi.com/airlines/32/K5.png"},
  'K6': {lcc: "0", name: "Cambodia Angkor Air", code: "K6", icon: "https://images.kiwi.com/airlines/32/K6.png"},
  'K7': {lcc: "0", name: "Air KBZ", code: "K7", icon: "https://images.kiwi.com/airlines/32/K7.png"},
  'K8': {lcc: "0", name: "Kan Air", code: "K8", icon: "https://images.kiwi.com/airlines/32/K8.png"},
  'K9': {lcc: "1", name: "KrasAvia (old iata)", code: "K9", icon: "https://images.kiwi.com/airlines/32/K9.png"},
  'KA': {lcc: "0", name: "Cathay Dragon", code: "KA", icon: "https://images.kiwi.com/airlines/32/KA.png"},
  'KB': {lcc: "0", name: "Druk Air", code: "KB", icon: "https://images.kiwi.com/airlines/32/KB.png"},
  'KC': {lcc: "0", name: "Air Astana", code: "KC", icon: "https://images.kiwi.com/airlines/32/KC.png"},
  'KD': {lcc: "0", name: "Kalstar Aviation", code: "KD", icon: "https://images.kiwi.com/airlines/32/KD.png"},
  'KE': {lcc: "0", name: "Korean Air", code: "KE", icon: "https://images.kiwi.com/airlines/32/KE.png"},
  'KF': {lcc: "0", name: "Air Belgium", code: "KF", icon: "https://images.kiwi.com/airlines/32/KF.png"},
  'KG': {lcc: "0", name: "Royal Airways", code: "KG", icon: "https://images.kiwi.com/airlines/32/KG.png"},
  'KI': {lcc: "1", name: "KrasAvia", code: "KI", icon: "https://images.kiwi.com/airlines/32/KI.png"},
  'KJ': {lcc: "0", name: "British Mediterranean Airways", code: "KJ", icon: "https://images.kiwi.com/airlines/32/KJ.png"},
  'KK': {lcc: "1", name: "AtlasGlobal", code: "KK", icon: "https://images.kiwi.com/airlines/32/KK.png"},
  'KL': {lcc: "0", name: "KLM Royal Dutch Airlines", code: "KL", icon: "https://images.kiwi.com/airlines/32/KL.png"},
  'KM': {lcc: "0", name: "Air Malta", code: "KM", icon: "https://images.kiwi.com/airlines/32/KM.png"},
  'KN': {lcc: "0", name: "China United", code: "KN", icon: "https://images.kiwi.com/airlines/32/KN.png"},
  'KO': {lcc: "0", name: "Komiaviatrans", code: "KO", icon: "https://images.kiwi.com/airlines/32/KO.png"},
  'KP': {lcc: "0", name: "ASKY Airlines", code: "KP", icon: "https://images.kiwi.com/airlines/32/KP.png"},
  'KQ': {lcc: "0", name: "Kenya Airways", code: "KQ", icon: "https://images.kiwi.com/airlines/32/KQ.png"},
  'KR': {lcc: "0", name: "Cambodia Airways", code: "KR", icon: "https://images.kiwi.com/airlines/32/KR.png"},
  'KS': {lcc: "0", name: "Peninsula Airways", code: "KS", icon: "https://images.kiwi.com/airlines/32/KS.png"},
  'KT': {lcc: "0", name: "VickJet", code: "KT", icon: "https://images.kiwi.com/airlines/32/KT.png"},
  'KU': {lcc: "0", name: "Kuwait Airways", code: "KU", icon: "https://images.kiwi.com/airlines/32/KU.png"},
  'KV': {lcc: "0", name: "Kavminvodyavia", code: "KV", icon: "https://images.kiwi.com/airlines/32/KV.png"},
  'KW': {lcc: "0", name: "Carnival Air Lines", code: "KW", icon: "https://images.kiwi.com/airlines/32/KW.png"},
  'KX': {lcc: "0", name: "Cayman Airways", code: "KX", icon: "https://images.kiwi.com/airlines/32/KX.png"},
  'KY': {lcc: "0", name: "Kunming Airlines", code: "KY", icon: "https://images.kiwi.com/airlines/32/KY.png"},
  'L3': {lcc: "0", name: "LTU Austria", code: "L3", icon: "https://images.kiwi.com/airlines/32/L3.png"},
  'L4': {lcc: "0", name: "LASA Argentina", code: "L4", icon: "https://images.kiwi.com/airlines/32/L4.png"},
  'L5': {lcc: "0", name: "Atlas Atlantique Airlines", code: "L5", icon: "https://images.kiwi.com/airlines/32/L5.png"},
  'L6': {lcc: "0", name: "Mauritania Airlines International", code: "L6", icon: "https://images.kiwi.com/airlines/32/L6.png"},
  'L7': {lcc: "0", name: "Lugansk Airlines", code: "L7", icon: "https://images.kiwi.com/airlines/32/L7.png"},
  'L8': {lcc: "0", name: "Line Blue", code: "L8", icon: "https://images.kiwi.com/airlines/32/L8.png"},
  'LA': {lcc: "0", name: "LATAM Airlines", code: "LA", icon: "https://images.kiwi.com/airlines/32/LA.png"},
  'LB': {lcc: "1", name: "Air Costa", code: "LB", icon: "https://images.kiwi.com/airlines/32/LB.png"},
  'LC': {lcc: "0", name: "Varig Log", code: "LC", icon: "https://images.kiwi.com/airlines/32/LC.png"},
  'LEBUSDIR': {lcc: "0", name: "Le Bus Direct", code: "LEBUSDIR", icon: "https://images.kiwi.com/airlines/32/LEBUSDIR.png"},
  'LEOEXB': {lcc: "None", name: "LEOEXPRESS Bus", code: "LEOEXB", icon: "https://images.kiwi.com/airlines/32/LEOEXB.png"},
  'LEOEXM': {lcc: "0", name: "LEOEXPRESS Minibus", code: "LEOEXM", icon: "https://images.kiwi.com/airlines/32/LEOEXM.png"},
  'LEOEXT': {lcc: "None", name: "LEOEXPRESS Train", code: "LEOEXT", icon: "https://images.kiwi.com/airlines/32/LEOEXT.png"},
  'LF': {lcc: "0", name: "FlyNordic", code: "LF", icon: "https://images.kiwi.com/airlines/32/LF.png"},
  'LG': {lcc: "0", name: "Luxair", code: "LG", icon: "https://images.kiwi.com/airlines/32/LG.png"},
  'LH': {lcc: "0", name: "Lufthansa", code: "LH", icon: "https://images.kiwi.com/airlines/32/LH.png"},
  'LI': {lcc: "0", name: "Leeward Islands Air Transport", code: "LI", icon: "https://images.kiwi.com/airlines/32/LI.png" },
  'LIZ': {lcc: "None", name: "Air Liaison", code: "LIZ", icon: "https://images.kiwi.com/airlines/32/LIZ.png"},
  'LJ': {lcc: "1", name: "Jin Air", code: "LJ", icon: "https://images.kiwi.com/airlines/32/LJ.png"},
  'LK': {lcc: "0", name: "Lao Skyway", code: "LK", icon: "https://images.kiwi.com/airlines/32/LK.png"},
  'LM': {lcc: "0", name: "LoganAir LM", code: "LM", icon: "https://images.kiwi.com/airlines/32/LM.png"},
  'LN': {lcc: "0", name: "Libyan Arab Airlines", code: "LN", icon: "https://images.kiwi.com/airlines/32/LN.png"},
  'LO': {lcc: "0", name: "LOT Polish Airlines", code: "LO", icon: "https://images.kiwi.com/airlines/32/LO.png"},
  'LONGTEST': {lcc: "0", name: "Long iata code test", code: "LONGTEST", icon: "https://images.kiwi.com/airlines/32/LONGTEST.png"},
  'LP': {lcc: "0", name: "LATAM Peru", code: "LP", icon: "https://images.kiwi.com/airlines/32/LP.png"},
  'LPA': {lcc: "None", name: "Air Leap", code: "LPA", icon: "https://images.kiwi.com/airlines/32/LPA.png"},
  'LQ': {lcc: "1", name: "Lanmei Airlines", code: "LQ", icon: "https://images.kiwi.com/airlines/32/LQ.png"},
  'LR': {lcc: "0", name: "LACSA", code: "LR", icon: "https://images.kiwi.com/airlines/32/LR.png"},
  'LS': {lcc: "1", name: "Jet2", code: "LS", icon: "https://images.kiwi.com/airlines/32/LS.png"},
  'LT': {lcc: "0", name: "LongJiang Airlines", code: "LT", icon: "https://images.kiwi.com/airlines/32/LT.png"},
  'LU': {lcc: "0", name: "LAN Express", code: "LU", icon: "https://images.kiwi.com/airlines/32/LU.png"},
  'LUFTHBUS': {lcc: "None", name: "Lufthansa express bus", code: "LUFTHBUS", icon: "https://images.kiwi.com/airlines/32/LUFTHBUS.png"},
  'LV': {lcc: "0", name: "Level", code: "LV", icon: "https://images.kiwi.com/airlines/32/LV.png"},
  'LW': {lcc: "0", name: "Latin American Wings", code: "LW", icon: "https://images.kiwi.com/airlines/32/LW.png"},
  'LX': {lcc: "0", name: "Swiss International Air Lines", code: "LX", icon: "https://images.kiwi.com/airlines/32/LX.png"},
  'LY': {lcc: "1", name: "El Al Israel Airlines", code: "LY", icon: "https://images.kiwi.com/airlines/32/LY.png"},
  'LZ': {lcc: "0", name: "belleair", code: "LZ", icon: "https://images.kiwi.com/airlines/32/LZ.png"},
  'M0': {lcc: "1", name: "Aero Mongolia", code: "M0", icon: "https://images.kiwi.com/airlines/32/M0.png"},
  'M1': {lcc: "0", name: "Maryland Air", code: "M1", icon: "https://images.kiwi.com/airlines/32/M1.png"},
  'M2': {lcc: "0", name: "MHS Aviation GmbH", code: "M2", icon: "https://images.kiwi.com/airlines/32/M2.png"},
  'M3': {lcc: "0", name: "Air Norway", code: "M3", icon: "https://images.kiwi.com/airlines/32/M3.png"},
  'M4': {lcc: "0", name: "Mistral Air", code: "M4", icon: "https://images.kiwi.com/airlines/32/M4.png"},
  'M5': {lcc: "0", name: "Kenmore Air", code: "M5", icon: "https://images.kiwi.com/airlines/32/M5.png"},
  'M7': {lcc: "0", name: "MasAir", code: "M7", icon: "https://images.kiwi.com/airlines/32/M7.png"},
  'M8': {lcc: "1", name: "SkyJet Airlines", code: "M8", icon: "https://images.kiwi.com/airlines/32/M8.png"},
  'M9': {lcc: "0", name: "Motor Sich", code: "M9", icon: "https://images.kiwi.com/airlines/32/M9.png"},
  'MA': {lcc: "0", name: "Mal/u00e9v", code: "MA", icon: "https://images.kiwi.com/airlines/32/MA.png"},
  'MARINO': {lcc: "0", name: "Marino Bus", code: "MARINO", icon: "https://images.kiwi.com/airlines/32/MARINO.png"},
  'MAROZZI': {lcc: "None", name: "Marozzi", code: "MAROZZI", icon: "https://images.kiwi.com/airlines/32/MAROZZI.png"},
  'MB': {lcc: "0", name: "MNG Airlines", code: "MB", icon: "https://images.kiwi.com/airlines/32/MB.png"},
  'MD': {lcc: "0", name: "Air Madagascar", code: "MD", icon: "https://images.kiwi.com/airlines/32/MD.png"},
  'ME': {lcc: "0", name: "Middle East Airlines", code: "ME", icon: "https://images.kiwi.com/airlines/32/ME.png"},
  'MEMENTO': {lcc: "None", name: "MementoBus", code: "MEMENTO", icon: "https://images.kiwi.com/airlines/32/MEMENTO.png"},
  'MF': {lcc: "0", name: "Xiamen Airlines", code: "MF", icon: "https://images.kiwi.com/airlines/32/MF.png"},
  'MH': {lcc: "1", name: "Malaysia Airlines", code: "MH", icon: "https://images.kiwi.com/airlines/32/MH.png"},
  'MI': {lcc: "0", name: "SilkAir", code: "MI", icon: "https://images.kiwi.com/airlines/32/MI.png"},
  'MICCOLIS': {lcc: "None", name: "Miccolis", code: "MICCOLIS", icon: "https://images.kiwi.com/airlines/32/MICCOLIS.png"},
  'MJ': {lcc: "1", name: "Myway Airlines", code: "MJ", icon: "https://images.kiwi.com/airlines/32/MJ.png"},
  'MK': {lcc: "0", name: "Air Mauritius", code: "MK", icon: "https://images.kiwi.com/airlines/32/MK.png"},
  'ML': {lcc: "1", name: "Air Mediterranee", code: "ML", icon: "https://images.kiwi.com/airlines/32/ML.png"},
  'MM': {lcc: "1", name: "Peach Aviation", code: "MM", icon: "https://images.kiwi.com/airlines/32/MM.png"},
  'MN': {lcc: "1", name: "Kulula", code: "MN", icon: "https://images.kiwi.com/airlines/32/MN.png"},
  'MO': {lcc: "1", name: "Calm Air", code: "MO", icon: "https://images.kiwi.com/airlines/32/MO.png"},
  'MP': {lcc: "0", name: "Martinair", code: "MP", icon: "https://images.kiwi.com/airlines/32/MP.png"},
  'MQ': {lcc: "0", name: "Envoy Air as American Eagle", code: "MQ", icon: "https://images.kiwi.com/airlines/32/MQ.png"},
  'MR': {lcc: "0", name: "Homer Air", code: "MR", icon: "https://images.kiwi.com/airlines/32/MR.png"},
  'MS': {lcc: "0", name: "Egyptair", code: "MS", icon: "https://images.kiwi.com/airlines/32/MS.png"},
  'MT': {lcc: "1", name: "Thomas Cook Airlines", code: "MT", icon: "https://images.kiwi.com/airlines/32/MT.png"},
  'MU': {lcc: "0", name: "China Eastern Airlines", code: "MU", icon: "https://images.kiwi.com/airlines/32/MU.png"},
  'MV': {lcc: "0", name: "Air Mediterranean", code: "MV", icon: "https://images.kiwi.com/airlines/32/MV.png"},
  'MW': {lcc: "0", name: "Mokulele Flight Service", code: "MW", icon: "https://images.kiwi.com/airlines/32/MW.png"},
  'MX': {lcc: "0", name: "Mexicana de Aviaci", code: "MX", icon: "https://images.kiwi.com/airlines/32/MX.png"},
  'MY': {lcc: "0", name: "Midwest Airlines (Egypt)", code: "MY", icon: "https://images.kiwi.com/airlines/32/MY.png"},
  'MZ': {lcc: "0", name: "Merpati Nusantara Airlines", code: "MZ", icon: "https://images.kiwi.com/airlines/32/MZ.png"},
  'N0': {lcc: "0", name: "Norte Lineas Aereas", code: "N0", icon: "https://images.kiwi.com/airlines/32/N0.png"},
  'N1': {lcc: "0", name: "N1", code: "N1", icon: "https://images.kiwi.com/airlines/32/N1.png"},
  'N4': {lcc: "0", name: "Nordwind Airlines", code: "N4", icon: "https://images.kiwi.com/airlines/32/N4.png"},
  'N5': {lcc: "0", name: "Skagway Air Service", code: "N5", icon: "https://images.kiwi.com/airlines/32/N5.png"},
  'N6': {lcc: "0", name: "Nomad Aviation", code: "N6", icon: "https://images.kiwi.com/airlines/32/N6.png"},
  'N7': {lcc: "0", name: "Nordic Regional Airlines", code: "N7", icon: "https://images.kiwi.com/airlines/32/N7.png"},
  'NA': {lcc: "0", name: "Nesma Air", code: "NA", icon: "https://images.kiwi.com/airlines/32/NA.png"},
  'NATEXPRESS': {lcc: "0", name: "National Express", code: "NATEXPRESS", icon: "https://images.kiwi.com/airlines/32/NATEXPRESS.png" },
  'NAVETTE': {lcc: "None", name: "Navette de Vatry", code: "NAVETTE", icon: "https://images.kiwi.com/airlines/32/NAVETTE.png"},
  'NB': {lcc: "0", name: "Sterling Airlines", code: "NB", icon: "https://images.kiwi.com/airlines/32/NB.png"},
  'NC': {lcc: "0", name: "National Jet Systems", code: "NC", icon: "https://images.kiwi.com/airlines/32/NC.png"},
  'ND': {lcc: "0", name: "FMI Air", code: "ND", icon: "https://images.kiwi.com/airlines/32/ND.png"},
  'NE': {lcc: "0", name: "Nesma Air", code: "NE", icon: "https://images.kiwi.com/airlines/32/NE.png"},
  'NETTBUSS': {lcc: "None", name: "Nettbuss", code: "NETTBUSS", icon: "https://images.kiwi.com/airlines/32/NETTBUSS.png"},
  'NF': {lcc: "0", name: "Air Vanuatu", code: "NF", icon: "https://images.kiwi.com/airlines/32/NF.png"},
  'NG': {lcc: "0", name: "Lauda Air", code: "NG", icon: "https://images.kiwi.com/airlines/32/NG.png"},
  'NH': {lcc: "0", name: "All Nippon Airways", code: "NH", icon: "https://images.kiwi.com/airlines/32/NH.png"},
  'NI': {lcc: "0", name: "Portugalia", code: "NI", icon: "https://images.kiwi.com/airlines/32/NI.png"},
  'NJ': {lcc: "0", name: "Nordic Global Airlines", code: "NJ", icon: "https://images.kiwi.com/airlines/32/NJ.png"},
  'NK': {lcc: "1", name: "Spirit Airlines", code: "NK", icon: "https://images.kiwi.com/airlines/32/NK.png"},
  'NL': {lcc: "1", name: "Shaheen Air International", code: "NL", icon: "https://images.kiwi.com/airlines/32/NL.png"},
  'NM': {lcc: "0", name: "Air Madrid", code: "NM", icon: "https://images.kiwi.com/airlines/32/NM.png"},
  'NN': {lcc: "1", name: "VIM Airlines", code: "NN", icon: "https://images.kiwi.com/airlines/32/NN.png"},
  'NO': {lcc: "1", name: "Neos Air", code: "NO", icon: "https://images.kiwi.com/airlines/32/NO.png"},
  'NOMAGO': {lcc: "None", name: "Nomago", code: "NOMAGO", icon: "https://images.kiwi.com/airlines/32/NOMAGO.png"},
  'NP': {lcc: "0", name: "Nile Air", code: "NP", icon: "https://images.kiwi.com/airlines/32/NP.png"},
  'NQ': {lcc: "0", name: "Air Japan", code: "NQ", icon: "https://images.kiwi.com/airlines/32/NQ.png"},
  'NS': {lcc: "0", name: "Hebei Airlines", code: "NS", icon: "https://images.kiwi.com/airlines/32/NS.png"},
  'NSB': {lcc: "None", name: "NSB", code: "NSB", icon: "https://images.kiwi.com/airlines/32/NSB.png"},
  'NT': {lcc: "1", name: "Binter Canarias", code: "NT", icon: "https://images.kiwi.com/airlines/32/NT.png"},
  'NU': {lcc: "0", name: "Japan Transocean Air", code: "NU", icon: "https://images.kiwi.com/airlines/32/NU.png"},
  'NW': {lcc: "0", name: "Northwest Airlines", code: "NW", icon: "https://images.kiwi.com/airlines/32/NW.png"},
  'NX': {lcc: "0", name: "Air Macau", code: "NX", icon: "https://images.kiwi.com/airlines/32/NX.png"},
  'NY': {lcc: "1", name: "Air Iceland Connect", code: "NY", icon: "https://images.kiwi.com/airlines/32/NY.png"},
  'NZ': {lcc: "1", name: "Air New Zealand", code: "NZ", icon: "https://images.kiwi.com/airlines/32/NZ.png"},
  'O1': {lcc: "0", name: "Orbit Airlines Azerbaijan", code: "O1", icon: "https://images.kiwi.com/airlines/32/O1.png"},
  'O2': {lcc: "0", name: "Oceanic Airlines", code: "O2", icon: "https://images.kiwi.com/airlines/32/O2.png"},
  'O6': {lcc: "0", name: "Avianca Brazil", code: "O6", icon: "https://images.kiwi.com/airlines/32/O6.png"},
  'O7': {lcc: "0", name: "Orenburzhye Airline", code: "O7", icon: "https://images.kiwi.com/airlines/32/O7.png"},
  'O8': {lcc: "0", name: "Siam Air", code: "O8", icon: "https://images.kiwi.com/airlines/32/O8.png"},
  'OA': {lcc: "0", name: "Olympic Air", code: "OA", icon: "https://images.kiwi.com/airlines/32/OA.png"},
  'OB': {lcc: "1", name: "Boliviana de Aviaci/u00f3n", code: "OB", icon: "https://images.kiwi.com/airlines/32/OB.png"},
  'OBB': {lcc: "None", name: "OBB", code: "OBB", icon: "https://images.kiwi.com/airlines/32/OBB.png"},
  'OC': {lcc: "0", name: "Oriental Air Bridge", code: "OC", icon: "https://images.kiwi.com/airlines/32/OC.png"},
  'OD': {lcc: "1", name: "Malindo Air", code: "OD", icon: "https://images.kiwi.com/airlines/32/OD.png"},
  'OE': {lcc: "1", name: "LaudaMotion", code: "OE", icon: "https://images.kiwi.com/airlines/32/OE.png"},
  'OF': {lcc: "0", name: "Air Finland", code: "OF", icon: "https://images.kiwi.com/airlines/32/OF.png"},
  'OG': {lcc: "0", name: "AirOnix", code: "OG", icon: "https://images.kiwi.com/airlines/32/OG.png"},
  'OH': {lcc: "0", name: "Comair", code: "OH", icon: "https://images.kiwi.com/airlines/32/OH.png"},
  'OI': {lcc: "0", name: "Orchid Airlines", code: "OI", icon: "https://images.kiwi.com/airlines/32/OI.png"},
  'OJ': {lcc: "0", name: "Overland Airways", code: "OJ", icon: "https://images.kiwi.com/airlines/32/OJ.png"},
  'OK': {lcc: "0", name: "Czech Airlines", code: "OK", icon: "https://images.kiwi.com/airlines/32/OK.png"},
  'OKBUS': {lcc: "None", name: "OK bus", code: "OKBUS", icon: "https://images.kiwi.com/airlines/32/OKBUS.png"},
  'OL': {lcc: "0", name: "Ostfriesische Lufttransport", code: "OL", icon: "https://images.kiwi.com/airlines/32/OL.png"},
  'OLLEX': {lcc: "None", name: "Ollex (express)", code: "OLLEX", icon: "https://images.kiwi.com/airlines/32/OLLEX.png"},
  'OM': {lcc: "0", name: "MIAT Mongolian Airlines", code: "OM", icon: "https://images.kiwi.com/airlines/32/OM.png"},
  'ON': {lcc: "0", name: "Nauru Air Corporation", code: "ON", icon: "https://images.kiwi.com/airlines/32/ON.png"},
  'OO': {lcc: "0", name: "SkyWest", code: "OO", icon: "https://images.kiwi.com/airlines/32/OO.png"},
  'OPENSKIES': {lcc: "1", name: "Openskies", code: "OPENSKIES", icon: "https://images.kiwi.com/airlines/32/OPENSKIES.png"},
  'OQ': {lcc: "0", name: "Chongqing Airlines", code: "OQ", icon: "https://images.kiwi.com/airlines/32/OQ.png"},
  'OR': {lcc: "1", name: "TUI Airlines Netherlands", code: "OR", icon: "https://images.kiwi.com/airlines/32/OR.png"},
  'ORESUNDST': {lcc: "0", name: "Oresundstag", code: "ORESUNDST", icon: "https://images.kiwi.com/airlines/32/ORESUNDST.png"},
  'OS': {lcc: "0", name: "Austrian Airlines", code: "OS", icon: "https://images.kiwi.com/airlines/32/OS.png"},
  'OT': {lcc: "0", name: "Aeropelican Air Services", code: "OT", icon: "https://images.kiwi.com/airlines/32/OT.png"},
  'OU': {lcc: "0", name: "Croatia Airlines", code: "OU", icon: "https://images.kiwi.com/airlines/32/OU.png"},
  'OUIBUS': {lcc: "0", name: "Ouibus", code: "OUIBUS", icon: "https://images.kiwi.com/airlines/32/OUIBUS.png"},
  'OUIGO': {lcc: "0", name: "Ouigo", code: "OUIGO", icon: "https://images.kiwi.com/airlines/32/OUIGO.png"},
  'OV': {lcc: "1", name: "SalamAir", code: "OV", icon: "https://images.kiwi.com/airlines/32/OV.png"},
  'OX': {lcc: "1", name: "Orient Thai Airlines", code: "OX", icon: "https://images.kiwi.com/airlines/32/OX.png"},
  'OXFORDBUS': {lcc: "None", name: "Oxford Bus Company", code: "OXFORDBUS", icon: "https://images.kiwi.com/airlines/32/OXFORDBUS.png"},
  'OY': {lcc: "0", name: "Andes L/u00edneas A/u00e9reas", code: "OY", icon: "https://images.kiwi.com/airlines/32/OY.png"},
  'OZ': {lcc: "0", name: "Asiana Airlines", code: "OZ", icon: "https://images.kiwi.com/airlines/32/OZ.png"},
  'P0': {lcc: "0", name: "Proflight Zambia", code: "P0", icon: "https://images.kiwi.com/airlines/32/P0.png"},
  'P1': {lcc: "0", name: "Regional Sky", code: "P1", icon: "https://images.kiwi.com/airlines/32/P1.png"},
  'P2': {lcc: "1", name: "Air Kenya", code: "P2", icon: "https://images.kiwi.com/airlines/32/P2.png"},
  'P4': {lcc: "0", name: "Air Peace Limited", code: "P4", icon: "https://images.kiwi.com/airlines/32/P4.png"},
  'P5': {lcc: "0", name: "Wingo airlines", code: "P5", icon: "https://images.kiwi.com/airlines/32/P5.png"},
  'P6': {lcc: "0", name: "Pascan Aviation", code: "P6", icon: "https://images.kiwi.com/airlines/32/P6.png"},
  'P7': {lcc: "0", name: "Small Planet Airline", code: "P7", icon: "https://images.kiwi.com/airlines/32/P7.png"},
  'P8': {lcc: "0", name: "Air Mekong", code: "P8", icon: "https://images.kiwi.com/airlines/32/P8.png"},
  'P9': {lcc: "1", name: "Peruvian Airlines", code: "P9", icon: "https://images.kiwi.com/airlines/32/P9.png"},
  'PA': {lcc: "1", name: "Airblue", code: "PA", icon: "https://images.kiwi.com/airlines/32/PA.png"},
  'PB': {lcc: "0", name: "Provincial Airlines", code: "PB", icon: "https://images.kiwi.com/airlines/32/PB.png"},
  'PC': {lcc: "1", name: "Pegasus", code: "PC", icon: "https://images.kiwi.com/airlines/32/PC.png"},
  'PD': {lcc: "1", name: "Porter Airlines", code: "PD", icon: "https://images.kiwi.com/airlines/32/PD.png"},
  'PE': {lcc: "0", name: "People/u0027s Viennaline PE", code: "PE", icon: "https://images.kiwi.com/airlines/32/PE.png"},
  'PF': {lcc: "1", name: "Primera Air", code: "PF", icon: "https://images.kiwi.com/airlines/32/PF.png"},
  'PG': {lcc: "1", name: "Bangkok Airways", code: "PG", icon: "https://images.kiwi.com/airlines/32/PG.png"},
  'PH': {lcc: "0", name: "Polynesian Airlines", code: "PH", icon: "https://images.kiwi.com/airlines/32/PH.png"},
  'PI': {lcc: "0", name: "Polar Airlines", code: "PI", icon: "https://images.kiwi.com/airlines/32/PI.png"},
  'PJ': {lcc: "0", name: "Air Saint Pierre", code: "PJ", icon: "https://images.kiwi.com/airlines/32/PJ.png"},
  'PK': {lcc: "0", name: "Pakistan International Airlines", code: "PK", icon: "https://images.kiwi.com/airlines/32/PK.png"},
  'PKSZCZECIN': {lcc: "None", name: "PKS Szczencin", code: "PKSZCZECIN", icon: "https://images.kiwi.com/airlines/32/PKSZCZECIN.png"},
  'PL': {lcc: "0", name: "Southern Air Charter", code: "PL", icon: "https://images.kiwi.com/airlines/32/PL.png"},
  'PM': {lcc: "0", name: "Canary Fly", code: "PM", icon: "https://images.kiwi.com/airlines/32/PM.png"},
  'PN': {lcc: "1", name: "West Air China", code: "PN", icon: "https://images.kiwi.com/airlines/32/PN.png"},
  'PO': {lcc: "0", name: "Polar Airlines", code: "PO", icon: "https://images.kiwi.com/airlines/32/PO.png"},
  'PP': {lcc: "0", name: "Air Indus", code: "PP", icon: "https://images.kiwi.com/airlines/32/PP.png"},
  'PQ': {lcc: "0", name: "SkyUp Airlines", code: "PQ", icon: "https://images.kiwi.com/airlines/32/PQ.png"},
  'PR': {lcc: "0", name: "Philippine Airlines", code: "PR", icon: "https://images.kiwi.com/airlines/32/PR.png"},
  'PS': {lcc: "0", name: "Ukraine International Airlines", code: "PS", icon: "https://images.kiwi.com/airlines/32/PS.png"},
  'PT': {lcc: "0", name: "Red Jet Andes", code: "PT", icon: "https://images.kiwi.com/airlines/32/PT.png"},
  'PU': {lcc: "0", name: "Plus Ultra Lineas Aereas", code: "PU", icon: "https://images.kiwi.com/airlines/32/PU.png"},
  'PV': {lcc: "0", name: "PAN Air", code: "PV", icon: "https://images.kiwi.com/airlines/32/PV.png"},
  'PW': {lcc: "0", name: "Precision Air", code: "PW", icon: "https://images.kiwi.com/airlines/32/PW.png"},
  'PX': {lcc: "0", name: "Air Niugini", code: "PX", icon: "https://images.kiwi.com/airlines/32/PX.png"},
  'PY': {lcc: "0", name: "Surinam Airways", code: "PY", icon: "https://images.kiwi.com/airlines/32/PY.png"},
  'PZ': {lcc: "0", name: "LATAM Paraguay", code: "PZ", icon: "https://images.kiwi.com/airlines/32/PZ.png"},
  'Q2': {lcc: "0", name: "Maldivian", code: "Q2", icon: "https://images.kiwi.com/airlines/32/Q2.png"},
  'Q3': {lcc: "0", name: "Anguilla Air Services", code: "Q3", icon: "https://images.kiwi.com/airlines/32/Q3.png"},
  'Q5': {lcc: "0", name: "40-Mile Air", code: "Q5", icon: "https://images.kiwi.com/airlines/32/Q5.png"},
  'Q6': {lcc: "0", name: "Volaris Costa Rica", code: "Q6", icon: "https://images.kiwi.com/airlines/32/Q6.png"},
  'Q7': {lcc: "0", name: "SkyBahamas Air", code: "Q7", icon: "https://images.kiwi.com/airlines/32/Q7.png"},
  'Q8': {lcc: "0", name: "Trans Air Congo", code: "Q8", icon: "https://images.kiwi.com/airlines/32/Q8.png"},
  'Q9': {lcc: "0", name: "Wataniya Airways", code: "Q9", icon: "https://images.kiwi.com/airlines/32/Q9.png"},
  'QA': {lcc: "0", name: "Click (Mexicana)", code: "QA", icon: "https://images.kiwi.com/airlines/32/QA.png"},
  'QB': {lcc: "0", name: "Georgian National Airlines", code: "QB", icon: "https://images.kiwi.com/airlines/32/QB.png"},
  'QC': {lcc: "0", name: "Camair-co", code: "QC", icon: "https://images.kiwi.com/airlines/32/QC.png"},
  'QD': {lcc: "0", name: "JC International Airlines", code: "QD", icon: "https://images.kiwi.com/airlines/32/QD.png"},
  'QF': {lcc: "0", name: "Qantas", code: "QF", icon: "https://images.kiwi.com/airlines/32/QF.png"},
  'QG': {lcc: "1", name: "Citilink", code: "QG", icon: "https://images.kiwi.com/airlines/32/QG.png"},
  'QH': {lcc: "0", name: "Bamboo Airways", code: "QH", icon: "https://images.kiwi.com/airlines/32/QH.png"},
  'QI': {lcc: "0", name: "Cimber Air", code: "QI", icon: "https://images.kiwi.com/airlines/32/QI.png"},
  'QJ': {lcc: "0", name: "Jet Airways", code: "QJ", icon: "https://images.kiwi.com/airlines/32/QJ.png"},
  'QK': {lcc: "0", name: "Air Canada Jazz", code: "QK", icon: "https://images.kiwi.com/airlines/32/QK.png"},
  'QL': {lcc: "1", name: "Laser Air", code: "QL", icon: "https://images.kiwi.com/airlines/32/QL.png"},
  'QM': {lcc: "0", name: "Air Malawi", code: "QM", icon: "https://images.kiwi.com/airlines/32/QM.png"},
  'QO': {lcc: "0", name: "Origin Pacific Airways", code: "QO", icon: "https://images.kiwi.com/airlines/32/QO.png"},
  'QQ': {lcc: "0", name: "Alliance Airlines", code: "QQ", icon: "https://images.kiwi.com/airlines/32/QQ.png"},
  'QR': {lcc: "0", name: "Qatar Airways", code: "QR", icon: "https://images.kiwi.com/airlines/32/QR.png"},
  'QS': {lcc: "1", name: "SmartWings", code: "QS", icon: "https://images.kiwi.com/airlines/32/QS.png"},
  'QT': {lcc: "0", name: "TAMPA", code: "QT", icon: "https://images.kiwi.com/airlines/32/QT.png"},
  'QU': {lcc: "0", name: "East African", code: "QU", icon: "https://images.kiwi.com/airlines/32/QU.png"},
  'QV': {lcc: "0", name: "Lao Airlines", code: "QV", icon: "https://images.kiwi.com/airlines/32/QV.png"},
  'QW': {lcc: "0", name: "Qingdao Airlines", code: "QW", icon: "https://images.kiwi.com/airlines/32/QW.png"},
  'QX': {lcc: "0", name: "Horizon Air", code: "QX", icon: "https://images.kiwi.com/airlines/32/QX.png"},
  'QY': {lcc: "0", name: "Red Jet Canada", code: "QY", icon: "https://images.kiwi.com/airlines/32/QY.png"},
  'QZ': {lcc: "0", name: "Indonesia AirAsia", code: "QZ", icon: "https://images.kiwi.com/airlines/32/QZ.png"},
  'R2': {lcc: "1", name: "Orenburg Airlines", code: "R2", icon: "https://images.kiwi.com/airlines/32/R2.png"},
  'R3': {lcc: "1", name: "Yakutia Airlines", code: "R3", icon: "https://images.kiwi.com/airlines/32/R3.png"},
  'R4': {lcc: "0", name: "Rossiya", code: "R4", icon: "https://images.kiwi.com/airlines/32/R4.png"},
  'R5': {lcc: "0", name: "Jordan Aviation", code: "R5", icon: "https://images.kiwi.com/airlines/32/R5.png"},
  'R6': {lcc: "0", name: "RACSA", code: "R6", icon: "https://images.kiwi.com/airlines/32/R6.png"},
  'R7': {lcc: "0", name: "Aserca Airlines", code: "R7", icon: "https://images.kiwi.com/airlines/32/R7.png"},
  'R8': {lcc: "0", name: "AirRussia", code: "R8", icon: "https://images.kiwi.com/airlines/32/R8.png"},
  'RA': {lcc: "0", name: "Nepal Airlines", code: "RA", icon: "https://images.kiwi.com/airlines/32/RA.png"},
  'RB': {lcc: "0", name: "Syrian Arab Airlines", code: "RB", icon: "https://images.kiwi.com/airlines/32/RB.png"},
  'RC': {lcc: "0", name: "Atlantic Airways", code: "RC", icon: "https://images.kiwi.com/airlines/32/RC.png"},
  'RD': {lcc: "0", name: "Ryan International Airlines", code: "RD", icon: "https://images.kiwi.com/airlines/32/RD.png"},
  'RE': {lcc: "0", name: "Stobart Air", code: "RE", icon: "https://images.kiwi.com/airlines/32/RE.png"},
  'REGIOJET': {lcc: "0", name: "RegioJet", code: "REGIOJET", icon: "https://images.kiwi.com/airlines/32/REGIOJET.png"},
  'REGIOJETB': {lcc: "0", name: "Regiojet Bus", code: "REGIOJETB", icon: "https://images.kiwi.com/airlines/32/REGIOJETB.png"},
  'REGIOJETT': {lcc: "0", name: "Regiojet Train", code: "REGIOJETT", icon: "https://images.kiwi.com/airlines/32/REGIOJETT.png"},
  'RF': {lcc: "0", name: "Florida West International Airways", code: "RF", icon: "https://images.kiwi.com/airlines/32/RF.png"},
  'RG': {lcc: "0", name: "Rotana Jet", code: "RG", icon: "https://images.kiwi.com/airlines/32/RG.png"},
  'RH': {lcc: "0", name: "Republic Express Airlines", code: "RH", icon: "https://images.kiwi.com/airlines/32/RH.png"},
  'RI': {lcc: "0", name: "Mandala Airlines", code: "RI", icon: "https://images.kiwi.com/airlines/32/RI.png"},
  'RJ': {lcc: "0", name: "Royal Jordanian", code: "RJ", icon: "https://images.kiwi.com/airlines/32/RJ.png"},
  'RK': {lcc: "0", name: "Air Afrique", code: "RK", icon: "https://images.kiwi.com/airlines/32/RK.png"},
  'RL': {lcc: "0", name: "Royal Falcon", code: "RL", icon: "https://images.kiwi.com/airlines/32/RL.png"},
  'RM': {lcc: "0", name: "Armenia Aircompany", code: "RM", icon: "https://images.kiwi.com/airlines/32/RM.png"},
  'RO': {lcc: "0", name: "Tarom", code: "RO", icon: "https://images.kiwi.com/airlines/32/RO.png"},
  'RP': {lcc: "0", name: "Chautauqua Airlines", code: "RP", icon: "https://images.kiwi.com/airlines/32/RP.png"},
  'RQ': {lcc: "0", name: "Kam Air", code: "RQ", icon: "https://images.kiwi.com/airlines/32/RQ.png"},
  'RS': {lcc: "0", name: "Air Seoul", code: "RS", icon: "https://images.kiwi.com/airlines/32/RS.png"},
  'RT': {lcc: "0", name: "JSC UVT Aero", code: "RT", icon: "https://images.kiwi.com/airlines/32/RT.png"},
  'RV': {lcc: "0", name: "Caspian Airlines", code: "RV", icon: "https://images.kiwi.com/airlines/32/RV.png"},
  'RW': {lcc: "0", name: "Republic Airlines", code: "RW", icon: "https://images.kiwi.com/airlines/32/RW.png"},
  'RX': {lcc: "1", name: "Regent Airways", code: "RX", icon: "https://images.kiwi.com/airlines/32/RX.png"},
  'RY': {lcc: "1", name: "Air Jiangxi", code: "RY", icon: "https://images.kiwi.com/airlines/32/RY.png"},
  'RZ': {lcc: "0", name: "Sansa Air", code: "RZ", icon: "https://images.kiwi.com/airlines/32/RZ.png"},
  'S0': {lcc: "1", name: "Aerolinas Sosa", code: "S0", icon: "https://images.kiwi.com/airlines/32/S0.png"},
  'S1': {lcc: "0", name: "Serbian Airlines", code: "S1", icon: "https://images.kiwi.com/airlines/32/S1.png"},
  'S2': {lcc: "0", name: "Jet Konnect", code: "S2", icon: "https://images.kiwi.com/airlines/32/S2.png"},
  'S3': {lcc: "0", name: "SBA Airlines", code: "S3", icon: "https://images.kiwi.com/airlines/32/S3.png"},
  'S4': {lcc: "0", name: "SATA Azores Airlines", code: "S4", icon: "https://images.kiwi.com/airlines/32/S4.png"},
  'S5': {lcc: "0", name: "Shuttle America", code: "S5", icon: "https://images.kiwi.com/airlines/32/S5.png"},
  'S6': {lcc: "0", name: "Sunrise Airways", code: "S6", icon: "https://images.kiwi.com/airlines/32/S6.png"},
  'S7': {lcc: "0", name: "S7 Airlines", code: "S7", icon: "https://images.kiwi.com/airlines/32/S7.png"},
  'S8': {lcc: "1", name: "SkyWise", code: "S8", icon: "https://images.kiwi.com/airlines/32/S8.png"},
  'S9': {lcc: "0", name: "Starbow Airlines", code: "S9", icon: "https://images.kiwi.com/airlines/32/S9.png"},
  'SA': {lcc: "0", name: "South African Airways", code: "SA", icon: "https://images.kiwi.com/airlines/32/SA.png"},
  'SAGALES': {lcc: "None", name: "Sagales", code: "SAGALES", icon: "https://images.kiwi.com/airlines/32/SAGALES.png"},
  'SB': {lcc: "0", name: "Aircalin", code: "SB", icon: "https://images.kiwi.com/airlines/32/SB.png"},
  'SC': {lcc: "0", name: "Shandong Airlines", code: "SC", icon: "https://images.kiwi.com/airlines/32/SC.png"},
  'SD': {lcc: "0", name: "Sudan Airways", code: "SD", icon: "https://images.kiwi.com/airlines/32/SD.png"},
  'SE': {lcc: "1", name: "XL Airways France", code: "SE", icon: "https://images.kiwi.com/airlines/32/SE.png"},
  'SF': {lcc: "0", name: "Tassili Airlines", code: "SF", icon: "https://images.kiwi.com/airlines/32/SF.png"},
  'SG': {lcc: "1", name: "Spicejet", code: "SG", icon: "https://images.kiwi.com/airlines/32/SG.png"},
  'SH': {lcc: "0", name: "Sharp Airlines", code: "SH", icon: "https://images.kiwi.com/airlines/32/SH.png"},
  'SI': {lcc: "0", name: "Blue Islands", code: "SI", icon: "https://images.kiwi.com/airlines/32/SI.png"},
  'SJ': {lcc: "0", name: "Sriwijaya Air", code: "SJ", icon: "https://images.kiwi.com/airlines/32/SJ.png"},
  'SJRAIL': {lcc: "None", name: "Swedish Railways", code: "SJRAIL", icon: "https://images.kiwi.com/airlines/32/SJRAIL.png"},
  'SK': {lcc: "0", name: "SAS", code: "SK", icon: "https://images.kiwi.com/airlines/32/SK.png"},
  'SKANETRAF': {lcc: "None", name: "Skanetrafiken", code: "SKANETRAF", icon: "https://images.kiwi.com/airlines/32/SKANETRAF.png"},
  'SL': {lcc: "1", name: "Thai Lion Air", code: "SL", icon: "https://images.kiwi.com/airlines/32/SL.png"},
  'SLOVAKLNS': {lcc: "None", name: "Slovak Lines ", code: "SLOVAKLNS", icon: "https://images.kiwi.com/airlines/32/SLOVAKLNS.png"},
  'SM': {lcc: "1", name: "Air Cairo", code: "SM", icon: "https://images.kiwi.com/airlines/32/SM.png"},
  'SMSFLUG': {lcc: "None", name: "SMS Flughafen", code: "SMSFLUG", icon: "https://images.kiwi.com/airlines/32/SMSFLUG.png"},
  'SN': {lcc: "0", name: "Brussels Airlines", code: "SN", icon: "https://images.kiwi.com/airlines/32/SN.png"},
  'SNCB': {lcc: "0", name: "SNCB", code: "SNCB", icon: "https://images.kiwi.com/airlines/32/SNCB.png"},
  'SO': {lcc: "0", name: "Salsa d//u0027Haiti", code: "SO", icon: "https://images.kiwi.com/airlines/32/SO.png"},
  'SP': {lcc: "0", name: "SATA Air Acores", code: "SP", icon: "https://images.kiwi.com/airlines/32/SP.png"},
  'SQ': {lcc: "0", name: "Singapore Airlines", code: "SQ", icon: "https://images.kiwi.com/airlines/32/SQ.png"},
  'SQS': {lcc: "None", name: "Susi Air", code: "SQS", icon: "https://images.kiwi.com/airlines/32/SQS.png"},
  'SR': {lcc: "0", name: "Sundair", code: "SR", icon: "https://images.kiwi.com/airlines/32/SR.png"},
  'SS': {lcc: "0", name: "Corsair International", code: "SS", icon: "https://images.kiwi.com/airlines/32/SS.png"},
  'ST': {lcc: "1", name: "Germania", code: "ST", icon: "https://images.kiwi.com/airlines/32/ST.png"},
  'STANSTDEXP': {lcc: "None", name: "Stansted Express", code: "STANSTDEXP", icon: "https://images.kiwi.com/airlines/32/STANSTDEXP.png"},
  'SU': {lcc: "0", name: "Aeroflot Russian Airlines", code: "SU", icon: "https://images.kiwi.com/airlines/32/SU.png"},
  'SUNLINES': {lcc: "None", name: "Sun lines", code: "SUNLINES", icon: "https://images.kiwi.com/airlines/32/SUNLINES.png"},
  'SV': {lcc: "0", name: "Saudi Arabian Airlines", code: "SV", icon: "https://images.kiwi.com/airlines/32/SV.png"},
  'SVENSKABUS': {lcc: "None", name: "Svenska Buss", code: "SVENSKABUS", icon: "https://images.kiwi.com/airlines/32/SVENSKABUS.png"},
  'SW': {lcc: "0", name: "Air Namibia", code: "SW", icon: "https://images.kiwi.com/airlines/32/SW.png"},
  'SWISSTOURS': {lcc: "None", name: "SwissTours", code: "SWISSTOURS", icon: "https://images.kiwi.com/airlines/32/SWISSTOURS.png"},
  'SX': {lcc: "0", name: "SkyWork Airlines", code: "SX", icon: "https://images.kiwi.com/airlines/32/SX.png"},
  'SY': {lcc: "1", name: "Sun Country Airlines", code: "SY", icon: "https://images.kiwi.com/airlines/32/SY.png"},
  'SZ': {lcc: "0", name: "Somon Air", code: "SZ", icon: "https://images.kiwi.com/airlines/32/SZ.png"},
  'SZS': {lcc: "None", name: "Scandinavian Airlines Ireland", code: "SZS", icon: "https://images.kiwi.com/airlines/32/SZS.png"},
  'T0': {lcc: "0", name: "TACA Peru", code: "T0", icon: "https://images.kiwi.com/airlines/32/T0.png"},
  'T2': {lcc: "0", name: "Thai Air Cargo", code: "T2", icon: "https://images.kiwi.com/airlines/32/T2.png"},
  'T3': {lcc: "0", name: "Eastern Airways", code: "T3", icon: "https://images.kiwi.com/airlines/32/T3.png"},
  'T4': {lcc: "0", name: "Hellas Jet", code: "T4", icon: "https://images.kiwi.com/airlines/32/T4.png"},
  'T5': {lcc: "0", name: "Turkmenistan Airlines", code: "T5", icon: "https://images.kiwi.com/airlines/32/T5.png"},
  'T6': {lcc: "0", name: "Airswift Transport", code: "T6", icon: "https://images.kiwi.com/airlines/32/T6.png"},
  'T7': {lcc: "0", name: "Twin Jet", code: "T7", icon: "https://images.kiwi.com/airlines/32/T7.png"},
  'TA': {lcc: "0", name: "Grupo TACA", code: "TA", icon: "https://images.kiwi.com/airlines/32/TA.png"},
  'TB': {lcc: "1", name: "tuifly.be", code: "TB", icon: "https://images.kiwi.com/airlines/32/TB.png"},
  'TC': {lcc: "0", name: "Air Tanzania", code: "TC", icon: "https://images.kiwi.com/airlines/32/TC.png"},
  'TD': {lcc: "0", name: "Atlantis European Airways", code: "TD", icon: "https://images.kiwi.com/airlines/32/TD.png"},
  'TE': {lcc: "0", name: "FlyLal", code: "TE", icon: "https://images.kiwi.com/airlines/32/TE.png"},
  'TERAVSN': {lcc: "None", name: "Terravision", code: "TERAVSN", icon: "https://images.kiwi.com/airlines/32/TERAVSN.png"},
  'TF': {lcc: "0", name: "Braathens Regional Aviation", code: "TF", icon: "https://images.kiwi.com/airlines/32/TF.png"},
  'TG': {lcc: "0", name: "Thai Airways International", code: "TG", icon: "https://images.kiwi.com/airlines/32/TG.png"},
  'TH': {lcc: "0", name: "TransBrasil Airlines", code: "TH", icon: "https://images.kiwi.com/airlines/32/TH.png"},
  'TI': {lcc: "0", name: "Tailwind Airlines", code: "TI", icon: "https://images.kiwi.com/airlines/32/TI.png"},
  'TJ': {lcc: "0", name: "Tradewind Aviation", code: "TJ", icon: "https://images.kiwi.com/airlines/32/TJ.png"},
  'TK': {lcc: "0", name: "Turkish Airlines", code: "TK", icon: "https://images.kiwi.com/airlines/32/TK.png"},
  'TL': {lcc: "1", name: "Airnorth", code: "TL", icon: "https://images.kiwi.com/airlines/32/TL.png"},
  'TM': {lcc: "0", name: "LAM Mozambique Airlines", code: "TM", icon: "https://images.kiwi.com/airlines/32/TM.png"},
  'TN': {lcc: "0", name: "Air Tahiti Nui", code: "TN", icon: "https://images.kiwi.com/airlines/32/TN.png"},
  'TO': {lcc: "0", name: "Transavia France", code: "TO", icon: "https://images.kiwi.com/airlines/32/TO.png"},
  'TP': {lcc: "0", name: "TAP Portugal", code: "TP", icon: "https://images.kiwi.com/airlines/32/TP.png"},
  'TQ': {lcc: "0", name: "Tandem Aero", code: "TQ", icon: "https://images.kiwi.com/airlines/32/TQ.png"},
  'TR': {lcc: "1", name: "Scoot", code: "TR", icon: "https://images.kiwi.com/airlines/32/TR.png"},
  'TRANSFERO': {lcc: "0", name: "Transfero", code: "TRANSFERO", icon: "https://images.kiwi.com/airlines/32/TRANSFERO.png"},
  'TROPOCEAN': {lcc: "None", name: "Tropic Ocean Airways", code: "TROPOCEAN", icon: "https://images.kiwi.com/airlines/32/TROPOCEAN.png"},
  'TS': {lcc: "0", name: "Air Transat", code: "TS", icon: "https://images.kiwi.com/airlines/32/TS.png"},
  'TT': {lcc: "1", name: "Tiger Airways Australia", code: "TT", icon: "https://images.kiwi.com/airlines/32/TT.png"},
  'TU': {lcc: "0", name: "Tunisair", code: "TU", icon: "https://images.kiwi.com/airlines/32/TU.png"},
  'TV': {lcc: "0", name: "Tibet Airlines", code: "TV", icon: "https://images.kiwi.com/airlines/32/TV.png"},
  'TW': {lcc: "1", name: "Tway Airlines", code: "TW", icon: "https://images.kiwi.com/airlines/32/TW.png"},
  'TX': {lcc: "0", name: "Air Cara/u00efbes", code: "TX", icon: "https://images.kiwi.com/airlines/32/TX.png"},
  'TY': {lcc: "0", name: "Air Caledonie", code: "TY", icon: "https://images.kiwi.com/airlines/32/TY.png"},
  'TZ': {lcc: "1", name: "Scoot - old", code: "TZ", icon: "https://images.kiwi.com/airlines/32/TZ.png"},
  'U1': {lcc: "0", name: "Aviabus", code: "U1", icon: "https://images.kiwi.com/airlines/32/U1.png"},
  'U2': {lcc: "1", name: "easyJet", code: "U2", icon: "https://images.kiwi.com/airlines/32/U2.png"},
  'U3': {lcc: "0", name: "Avies", code: "U3", icon: "https://images.kiwi.com/airlines/32/U3.png"},
  'U4': {lcc: "0", name: "Buddha Air", code: "U4", icon: "https://images.kiwi.com/airlines/32/U4.png"},
  'U5': {lcc: "0", name: "USA3000 Airlines", code: "U5", icon: "https://images.kiwi.com/airlines/32/U5.png"},
  'U6': {lcc: "1", name: "Ural Airlines", code: "U6", icon: "https://images.kiwi.com/airlines/32/U6.png"},
  'U7': {lcc: "0", name: "Northern Dene Airways", code: "U7", icon: "https://images.kiwi.com/airlines/32/U7.png"},
  'U8': {lcc: "0", name: "TUS Airways", code: "U8", icon: "https://images.kiwi.com/airlines/32/U8.png"},
  'U9': {lcc: "0", name: "Tatarstan Airlines", code: "U9", icon: "https://images.kiwi.com/airlines/32/U9.png"},
  'UA': {lcc: "0", name: "United Airlines", code: "UA", icon: "https://images.kiwi.com/airlines/32/UA.png"},
  'UB': {lcc: "0", name: "Myanmar National Airlines", code: "UB", icon: "https://images.kiwi.com/airlines/32/UB.png"},
  'UD': {lcc: "0", name: "Hex/u0027Air", code: "UD", icon: "https://images.kiwi.com/airlines/32/UD.png"},
  'UE': {lcc: "0", name: "Nasair", code: "UE", icon: "https://images.kiwi.com/airlines/32/UE.png"},
  'UF': {lcc: "0", name: "UM Airlines", code: "UF", icon: "https://images.kiwi.com/airlines/32/UF.png"},
  'UG': {lcc: "0", name: "TunisAir Express", code: "UG", icon: "https://images.kiwi.com/airlines/32/UG.png"},
  'UH': {lcc: "0", name: "AtlasGlobal Ukraine", code: "UH", icon: "https://images.kiwi.com/airlines/32/UH.png"},
  'UI': {lcc: "0", name: "Auric Air", code: "UI", icon: "https://images.kiwi.com/airlines/32/UI.png"},
  'UJ': {lcc: "0", name: "AlMasria Universal Airlines", code: "UJ", icon: "https://images.kiwi.com/airlines/32/UJ.png"},
  'UK': {lcc: "0", name: "Air Vistara", code: "UK", icon: "https://images.kiwi.com/airlines/32/UK.png"},
  'UL': {lcc: "0", name: "SriLankan Airlines", code: "UL", icon: "https://images.kiwi.com/airlines/32/UL.png"},
  'UM': {lcc: "0", name: "Air Zimbabwe", code: "UM", icon: "https://images.kiwi.com/airlines/32/UM.png"},
  'UN': {lcc: "1", name: "Transaero Airlines", code: "UN", icon: "https://images.kiwi.com/airlines/32/UN.png"},
  'UO': {lcc: "1", name: "Hong Kong Express Airways", code: "UO", icon: "https://images.kiwi.com/airlines/32/UO.png"},
  'UP': {lcc: "1", name: "Bahamasair", code: "UP", icon: "https://images.kiwi.com/airlines/32/UP.png"},
  'UQ': {lcc: "0", name: "Urumqi Airlines", code: "UQ", icon: "https://images.kiwi.com/airlines/32/UQ.png"},
  'UR': {lcc: "0", name: "Azur Air Germany", code: "UR", icon: "https://images.kiwi.com/airlines/32/UR.png"},
  'US': {lcc: "0", name: "US Airways", code: "US", icon: "https://images.kiwi.com/airlines/32/US.png"},
  'UT': {lcc: "1", name: "UTair", code: "UT", icon: "https://images.kiwi.com/airlines/32/UT.png"},
  'UU': {lcc: "0", name: "Air Austral", code: "UU", icon: "https://images.kiwi.com/airlines/32/UU.png"},
  'UX': {lcc: "0", name: "Air Europa", code: "UX", icon: "https://images.kiwi.com/airlines/32/UX.png"},
  'UZ': {lcc: "0", name: "El-Buraq Air Transport", code: "UZ", icon: "https://images.kiwi.com/airlines/32/UZ.png"},
  'V0': {lcc: "1", name: "Conviasa", code: "V0", icon: "https://images.kiwi.com/airlines/32/V0.png"},
  'V2': {lcc: "0", name: "Vision Airlines", code: "V2", icon: "https://images.kiwi.com/airlines/32/V2.png"},
  'V3': {lcc: "0", name: "Carpatair", code: "V3", icon: "https://images.kiwi.com/airlines/32/V3.png"},
  'V4': {lcc: "0", name: "Vieques Air Link", code: "V4", icon: "https://images.kiwi.com/airlines/32/V4.png"},
  'V5': {lcc: "0", name: "Aerov/u00edas DAP", code: "V5", icon: "https://images.kiwi.com/airlines/32/V5.png"},
  'V6': {lcc: "0", name: "VIP Ecuador", code: "V6", icon: "https://images.kiwi.com/airlines/32/V6.png"},
  'V7': {lcc: "1", name: "Volotea", code: "V7", icon: "https://images.kiwi.com/airlines/32/V7.png"},
  'V8': {lcc: "0", name: "ATRAN Cargo Airlines", code: "V8", icon: "https://images.kiwi.com/airlines/32/V8.png"},
  'V9': {lcc: "0", name: "Star1 Airlines", code: "V9", icon: "https://images.kiwi.com/airlines/32/V9.png"},
  'VA': {lcc: "1", name: "Virgin Australia Airlines", code: "VA", icon: "https://images.kiwi.com/airlines/32/VA.png"},
  'VASTTRAF': {lcc: "None", name: "Vasttrafik", code: "VASTTRAF", icon: "https://images.kiwi.com/airlines/32/VASTTRAF.png"},
  'VB': {lcc: "1", name: "VivaAerobus", code: "VB", icon: "https://images.kiwi.com/airlines/32/VB.png"},
  'VC': {lcc: "0", name: "Via Air", code: "VC", icon: "https://images.kiwi.com/airlines/32/VC.png"},
  'VD': {lcc: "0", name: "Air Libert", code: "VD", icon: "https://images.kiwi.com/airlines/32/VD.png"},
  'VE': {lcc: "0", name: "VE", code: "VE", icon: "https://images.kiwi.com/airlines/32/VE.png"},
  'VF': {lcc: "0", name: "Valuair", code: "VF", icon: "https://images.kiwi.com/airlines/32/VF.png"},
  'VG': {lcc: "0", name: "VLM Airlines", code: "VG", icon: "https://images.kiwi.com/airlines/32/VG.png"},
  'VH': {lcc: "0", name: "Viva Air", code: "VH", icon: "https://images.kiwi.com/airlines/32/VH.png"},
  'VI': {lcc: "0", name: "Volga-Dnepr Airlines", code: "VI", icon: "https://images.kiwi.com/airlines/32/VI.png"},
  'VIARAILCAD': {lcc: "None", name: "VIA Rail Canada", code: "VIARAILCAD", icon: "https://images.kiwi.com/airlines/32/VIARAILCAD.png"},
  'VIEAPLINES': {lcc: "None", name: "Vienna Airport Lines", code: "VIEAPLINES", icon: "https://images.kiwi.com/airlines/32/VIEAPLINES.png"},
  'VJ': {lcc: "1", name: "VietJet Air", code: "VJ", icon: "https://images.kiwi.com/airlines/32/VJ.png"},
  'VK': {lcc: "0", name: "LEVEL operated by ANISEC", code: "VK", icon: "https://images.kiwi.com/airlines/32/VK.png"},
  'VL': {lcc: "0", name: "Med-View Airline", code: "VL", icon: "https://images.kiwi.com/airlines/32/VL.png"},
  'VN': {lcc: "0", name: "Vietnam Airlines", code: "VN", icon: "https://images.kiwi.com/airlines/32/VN.png"},
  'VO': {lcc: "0", name: "FlyVLM", code: "VO", icon: "https://images.kiwi.com/airlines/32/VO.png"},
  'VP': {lcc: "0", name: "VASP", code: "VP", icon: "https://images.kiwi.com/airlines/32/VP.png"},
  'VQ': {lcc: "1", name: "Novoair", code: "VQ", icon: "https://images.kiwi.com/airlines/32/VQ.png"},
  'VR': {lcc: "0", name: "TACV", code: "VR", icon: "https://images.kiwi.com/airlines/32/VR.png"},
  'VRG': {lcc: "1", name: "Voyage Air", code: "VRG", icon: "https://images.kiwi.com/airlines/32/VRG.png"},
  'VS': {lcc: "0", name: "Virgin Atlantic Airways", code: "VS", icon: "https://images.kiwi.com/airlines/32/VS.png"},
  'VT': {lcc: "0", name: "Air Tahiti", code: "VT", icon: "https://images.kiwi.com/airlines/32/VT.png"},
  'VU': {lcc: "0", name: "Air Ivoire", code: "VU", icon: "https://images.kiwi.com/airlines/32/VU.png"},
  'VV': {lcc: "0", name: "Viva Air", code: "VV", icon: "https://images.kiwi.com/airlines/32/VV.png"},
  'VW': {lcc: "0", name: "Aeromar", code: "VW", icon: "https://images.kiwi.com/airlines/32/VW.png"},
  'VX': {lcc: "1", name: "Virgin America", code: "VX", icon: "https://images.kiwi.com/airlines/32/VX.png"},
  'VY': {lcc: "1", name: "Vueling", code: "VY", icon: "https://images.kiwi.com/airlines/32/VY.png"},
  'VZ': {lcc: "0", name: "Thai Vietjet", code: "VZ", icon: "https://images.kiwi.com/airlines/32/VZ.png"},
  'W1': {lcc: "0", name: "World Experience Airline", code: "W1", icon: "https://images.kiwi.com/airlines/32/W1.png"},
  'W2': {lcc: "0", name: "Flexflight", code: "W2", icon: "https://images.kiwi.com/airlines/32/W2.png"},
  'W3': {lcc: "0", name: "Arik Air", code: "W3", icon: "https://images.kiwi.com/airlines/32/W3.png"},
  'W4': {lcc: "1", name: "LC Per/u00fa", code: "W4", icon: "https://images.kiwi.com/airlines/32/W4.png"},
  'W5': {lcc: "0", name: "Mahan Air", code: "W5", icon: "https://images.kiwi.com/airlines/32/W5.png"},
  'W6': {lcc: "1", name: "Wizzair", code: "W6", icon: "https://images.kiwi.com/airlines/32/W6.png"},
  'W7': {lcc: "0", name: "Austral Brasil", code: "W7", icon: "https://images.kiwi.com/airlines/32/W7.png"},
  'W8': {lcc: "0", name: "Cargojet Airways", code: "W8", icon: "https://images.kiwi.com/airlines/32/W8.png"},
  'W9': {lcc: "0", name: "Wizz Air UK", code: "W9", icon: "https://images.kiwi.com/airlines/32/W9.png"},
  'WA': {lcc: "0", name: "KLM Cityhopper", code: "WA", icon: "https://images.kiwi.com/airlines/32/WA.png"},
  'WAGNERTRNS': {lcc: "None", name: "Wagner Transport", code: "WAGNERTRNS", icon: "https://images.kiwi.com/airlines/32/WAGNERTRNS.png"},
  'WB': {lcc: "0", name: "Rwandair Express", code: "WB", icon: "https://images.kiwi.com/airlines/32/WB.png"},
  'WC': {lcc: "0", name: "Islena De Inversiones", code: "WC", icon: "https://images.kiwi.com/airlines/32/WC.png"},
  'WD': {lcc: "0", name: "Amsterdam Airlines", code: "WD", icon: "https://images.kiwi.com/airlines/32/WD.png"},
  'WE': {lcc: "1", name: "Thai Smile", code: "WE", icon: "https://images.kiwi.com/airlines/32/WE.png"},
  'WF': {lcc: "0", name: "Wider/u00f8e", code: "WF", icon: "https://images.kiwi.com/airlines/32/WF.png"},
  'WG': {lcc: "1", name: "Sunwing", code: "WG", icon: "https://images.kiwi.com/airlines/32/WG.png"},
  'WH': {lcc: "0", name: "China Northwest Airlines (WH)", code: "WH", icon: "https://images.kiwi.com/airlines/32/WH.png"},
  'WI': {lcc: "0", name: "White Airways", code: "WI", icon: "https://images.kiwi.com/airlines/32/WI.png"},
  'WJ': {lcc: "0", name: "Air Labrador", code: "WJ", icon: "https://images.kiwi.com/airlines/32/WJ.png"},
  'WK': {lcc: "0", name: "Edelweiss Air", code: "WK", icon: "https://images.kiwi.com/airlines/32/WK.png"},
  'WM': {lcc: "0", name: "Windward Islands Airways", code: "WM", icon: "https://images.kiwi.com/airlines/32/WM.png"},
  'WN': {lcc: "1", name: "Southwest Airlines", code: "WN", icon: "https://images.kiwi.com/airlines/32/WN.png"},
  'WO': {lcc: "0", name: "Swoop", code: "WO", icon: "https://images.kiwi.com/airlines/32/WO.png"},
  'WP': {lcc: "0", name: "Island Air", code: "WP", icon: "https://images.kiwi.com/airlines/32/WP.png"},
  'WQ': {lcc: "0", name: "PanAm World Airways", code: "WQ", icon: "https://images.kiwi.com/airlines/32/WQ.png"},
  'WR': {lcc: "1", name: "WestJet Encore", code: "WR", icon: "https://images.kiwi.com/airlines/32/WR.png"},
  'WS': {lcc: "1", name: "WestJet", code: "WS", icon: "https://images.kiwi.com/airlines/32/WS.png"},
  'WU': {lcc: "0", name: "Wizz Air Ukraine", code: "WU", icon: "https://images.kiwi.com/airlines/32/WU.png"},
  'WV': {lcc: "0", name: "Aero VIP", code: "WV", icon: "https://images.kiwi.com/airlines/32/WV.png"},
  'WW': {lcc: "1", name: "WOW air", code: "WW", icon: "https://images.kiwi.com/airlines/32/WW.png"},
  'WX': {lcc: "0", name: "CityJet", code: "WX", icon: "https://images.kiwi.com/airlines/32/WX.png"},
  'WY': {lcc: "0", name: "Oman Air", code: "WY", icon: "https://images.kiwi.com/airlines/32/WY.png"},
  'WZ': {lcc: "1", name: "Red Wings", code: "WZ", icon: "https://images.kiwi.com/airlines/32/WZ.png"},
  'X3': {lcc: "1", name: "TUIfly", code: "X3", icon: "https://images.kiwi.com/airlines/32/X3.png"},
  'X4': {lcc: "0", name: "Alaska Seaplanes X4", code: "X4", icon: "https://images.kiwi.com/airlines/32/X4.png"},
  'X5': {lcc: "None", name: "Air Europa express", code: "X5", icon: "https://images.kiwi.com/airlines/32/X5.png"},
  'X9': {lcc: "0", name: "Fake Airline", code: "X9", icon: "https://images.kiwi.com/airlines/32/X9.png"},
  'XB': {lcc: "0", name: "NEXT Brasil", code: "XB", icon: "https://images.kiwi.com/airlines/32/XB.png"},
  'XC': {lcc: "1", name: "Corendon", code: "XC", icon: "https://images.kiwi.com/airlines/32/XC.png"},
  'XE': {lcc: "0", name: "ExpressJet", code: "XE", icon: "https://images.kiwi.com/airlines/32/XE.png"},
  'XF': {lcc: "0", name: "Vladivostok Air", code: "XF", icon: "https://images.kiwi.com/airlines/32/XF.png"},
  'XG': {lcc: "0", name: "SunExpress", code: "XG", icon: "https://images.kiwi.com/airlines/32/XG.png"},
  'XJ': {lcc: "0", name: "Thai AirAsia X", code: "XJ", icon: "https://images.kiwi.com/airlines/32/XJ.png"},
  'XK': {lcc: "0", name: "Air Corsica", code: "XK", icon: "https://images.kiwi.com/airlines/32/XK.png"},
  'XL': {lcc: "0", name: "LATAM Ecuador", code: "XL", icon: "https://images.kiwi.com/airlines/32/XL.png"},
  'XM': {lcc: "0", name: "Alitalia Express", code: "XM", icon: "https://images.kiwi.com/airlines/32/XM.png"},
  'XN': {lcc: "0", name: "Xpressair", code: "XN", icon: "https://images.kiwi.com/airlines/32/XN.png"},
  'XO': {lcc: "0", name: "LTE International Airways", code: "XO", icon: "https://images.kiwi.com/airlines/32/XO.png"},
  'XQ': {lcc: "1", name: "SunExpress", code: "XQ", icon: "https://images.kiwi.com/airlines/32/XQ.png"},
  'XR': {lcc: "0", name: "Corendon Airlines Europe", code: "XR", icon: "https://images.kiwi.com/airlines/32/XR.png"},
  'XT': {lcc: "0", name: "Indonesia AirAsia X", code: "XT", icon: "https://images.kiwi.com/airlines/32/XT.png"},
  'XU': {lcc: "1", name: "African Express", code: "XU", icon: "https://images.kiwi.com/airlines/32/XU.png"},
  'XV': {lcc: "0", name: "BVI Airways", code: "XV", icon: "https://images.kiwi.com/airlines/32/XV.png"},
  'XW': {lcc: "1", name: "NokScoot", code: "XW", icon: "https://images.kiwi.com/airlines/32/XW.png"},
  'XX': {lcc: "0", name: "Greenfly", code: "XX", icon: "https://images.kiwi.com/airlines/32/XX.png"},
  'XY': {lcc: "1", name: "flynas", code: "XY", icon: "https://images.kiwi.com/airlines/32/XY.png"},
  'XZ': {lcc: "0", name: "Congo Express", code: "XZ", icon: "https://images.kiwi.com/airlines/32/XZ.png"},
  'Y1': {lcc: "0", name: "Yellowstone Club Private Shuttle", code: "Y1", icon: "https://images.kiwi.com/airlines/32/Y1.png"},
  'Y2': {lcc: "0", name: "AirCentury", code: "Y2", icon: "https://images.kiwi.com/airlines/32/Y2.png"},
  'Y4': {lcc: "1", name: "Volaris", code: "Y4", icon: "https://images.kiwi.com/airlines/32/Y4.png"},
  'Y5': {lcc: "1", name: "Golden Myanmar Airlines", code: "Y5", icon: "https://images.kiwi.com/airlines/32/Y5.png"},
  'Y7': {lcc: "1", name: "NordStar Airlines", code: "Y7", icon: "https://images.kiwi.com/airlines/32/Y7.png"},
  'Y8': {lcc: "0", name: "Marusya Airways", code: "Y8", icon: "https://images.kiwi.com/airlines/32/Y8.png"},
  'Y9': {lcc: "0", name: "Kish Air", code: "Y9", icon: "https://images.kiwi.com/airlines/32/Y9.png"},
  'YB': {lcc: "1", name: "BoraJet", code: "YB", icon: "https://images.kiwi.com/airlines/32/YB.png"},
  'YC': {lcc: "1", name: "Yamal Air", code: "YC", icon: "https://images.kiwi.com/airlines/32/YC.png"},
  'YD': {lcc: "0", name: "Gomelavia", code: "YD", icon: "https://images.kiwi.com/airlines/32/YD.png"},
  'YE': {lcc: "0", name: "Yan Air", code: "YE", icon: "https://images.kiwi.com/airlines/32/YE.png"},
  'YH': {lcc: "0", name: "Yangon Airways Ltd.", code: "YH", icon: "https://images.kiwi.com/airlines/32/YH.png"},
  'YJ': {lcc: "1", name: "Asian Wings Air", code: "YJ", icon: "https://images.kiwi.com/airlines/32/YJ.png"},
  'YK': {lcc: "0", name: "Avia Traffic Airline", code: "YK", icon: "https://images.kiwi.com/airlines/32/YK.png"},
  'YL': {lcc: "0", name: "Yamal Airlines", code: "YL", icon: "https://images.kiwi.com/airlines/32/YL.png"},
  'YM': {lcc: "0", name: "Montenegro Airlines", code: "YM", icon: "https://images.kiwi.com/airlines/32/YM.png"},
  'YN': {lcc: "0", name: "Air Creebec", code: "YN", icon: "https://images.kiwi.com/airlines/32/YN.png"},
  'YO': {lcc: "0", name: "TransHolding System", code: "YO", icon: "https://images.kiwi.com/airlines/32/YO.png"},
  'YQ': {lcc: "0", name: "TAR Aerolineas", code: "YQ", icon: "https://images.kiwi.com/airlines/32/YQ.png"},
  'YR': {lcc: "0", name: "SENIC AIRLINES", code: "YR", icon: "https://images.kiwi.com/airlines/32/YR.png"},
  'YS': {lcc: "0", name: "R/u00e9gional", code: "YS", icon: "https://images.kiwi.com/airlines/32/YS.png"},
  'YT': {lcc: "0", name: "Yeti Airways", code: "YT", icon: "https://images.kiwi.com/airlines/32/YT.png"},
  'YU': {lcc: "0", name: "EuroAtlantic Airways", code: "YU", icon: "https://images.kiwi.com/airlines/32/YU.png"},
  'YV': {lcc: "0", name: "Mesa Airlines", code: "YV", icon: "https://images.kiwi.com/airlines/32/YV.png"},
  'YW': {lcc: "0", name: "Air Nostrum", code: "YW", icon: "https://images.kiwi.com/airlines/32/YW.png"},
  'YX': {lcc: "0", name: "Republic Airline", code: "YX", icon: "https://images.kiwi.com/airlines/32/YX.png"},
  'YY': {lcc: "0", name: "Virginwings", code: "YY", icon: "https://images.kiwi.com/airlines/32/YY.png"},
  'YZ': {lcc: "0", name: "Alas Uruguay", code: "YZ", icon: "https://images.kiwi.com/airlines/32/YZ.png"},
  'Z2': {lcc: "0", name: "Philippines AirAsia", code: "Z2", icon: "https://images.kiwi.com/airlines/32/Z2.png"},
  'Z3': {lcc: "0", name: "Avient Aviation", code: "Z3", icon: "https://images.kiwi.com/airlines/32/Z3.png"},
  'Z4': {lcc: "0", name: "Zoom Airlines", code: "Z4", icon: "https://images.kiwi.com/airlines/32/Z4.png"},
  'Z6': {lcc: "1", name: "Dniproavia", code: "Z6", icon: "https://images.kiwi.com/airlines/32/Z6.png"},
  'Z7': {lcc: "1", name: "Amaszonas Uruguay", code: "Z7", icon: "https://images.kiwi.com/airlines/32/Z7.png"},
  'Z8': {lcc: "1", name: "Amaszonas", code: "Z8", icon: "https://images.kiwi.com/airlines/32/Z8.png"},
  'Z9': {lcc: "0", name: "Bek Air", code: "Z9", icon: "https://images.kiwi.com/airlines/32/Z9.png"},
  'ZA': {lcc: "0", name: "Sky Angkor Airlines", code: "ZA", icon: "https://images.kiwi.com/airlines/32/ZA.png"},
  'ZB': {lcc: "1", name: "Monarch", code: "ZB", icon: "https://images.kiwi.com/airlines/32/ZB.png"},
  'ZC': {lcc: "0", name: "Korongo Airlines", code: "ZC", icon: "https://images.kiwi.com/airlines/32/ZC.png"},
  'ZD': {lcc: "0", name: "EWA Air", code: "ZD", icon: "https://images.kiwi.com/airlines/32/ZD.png"},
  'ZE': {lcc: "1", name: "Eastar Jet", code: "ZE", icon: "https://images.kiwi.com/airlines/32/ZE.png"},
  'ZF': {lcc: "0", name: "Azur Air", code: "ZF", icon: "https://images.kiwi.com/airlines/32/ZF.png"},
  'ZG': {lcc: "0", name: "Grozny Avia", code: "ZG", icon: "https://images.kiwi.com/airlines/32/ZG.png"},
  'ZH': {lcc: "0", name: "Shenzhen Airlines", code: "ZH", icon: "https://images.kiwi.com/airlines/32/ZH.png"},
  'ZI': {lcc: "0", name: "Aigle Azur", code: "ZI", icon: "https://images.kiwi.com/airlines/32/ZI.png"},
  'ZJ': {lcc: "0", name: "Zambezi Airlines (ZMA)", code: "ZJ", icon: "https://images.kiwi.com/airlines/32/ZJ.png"},
  'ZK': {lcc: "0", name: "Great Lakes Airlines", code: "ZK", icon: "https://images.kiwi.com/airlines/32/ZK.png"},
  'ZL': {lcc: "1", name: "Regional Express", code: "ZL", icon: "https://images.kiwi.com/airlines/32/ZL.png"},
  'ZM': {lcc: "0", name: "Air Manas", code: "ZM", icon: "https://images.kiwi.com/airlines/32/ZM.png"},
  'ZN': {lcc: "0", name: "Zenith International Airline", code: "ZN", icon: "https://images.kiwi.com/airlines/32/ZN.png"},
  'ZP': {lcc: "0", name: "Amaszonas del Paraguay S.A. Lineas Aereas", code: "ZP", icon: "https://images.kiwi.com/airlines/32/ZP.png"},
  'ZQ': {lcc: "0", name: "Locair", code: "ZQ", icon: "https://images.kiwi.com/airlines/32/ZQ.png"},
  'ZS': {lcc: "0", name: "Sama Airlines", code: "ZS", icon: "https://images.kiwi.com/airlines/32/ZS.png"},
  'ZT': {lcc: "0", name: "Titan Airways", code: "ZT", icon: "https://images.kiwi.com/airlines/32/ZT.png"},
  'ZV': {lcc: "1", name: "V Air", code: "ZV", icon: "https://images.kiwi.com/airlines/32/ZV.png"},
  'ZW': {lcc: "0", name: "Air Wisconsin", code: "ZW", icon: "https://images.kiwi.com/airlines/32/ZW.png"},
  'ZX': {lcc: "0", name: "Japan Regio", code: "ZX", icon: "https://images.kiwi.com/airlines/32/ZX.png"},
  'ZY': {lcc: "0", name: "Ada Air", code: "ZY", icon: "https://images.kiwi.com/airlines/32/ZY.png"},
  'ZZ': {lcc: "0", name: "Zz", code: "ZZ", icon: "https://images.kiwi.com/airlines/32/ZZ.png"},
  '__': {lcc: "0", name: "FakeAirline", code: "__", icon: "https://images.kiwi.com/airlines/32/__.png"},
};

export default function (code) {
  return airlineInfo[code] || {lcc: "0", name: "", code: "", icon: "https:/images.kiwi.com/airlines/32/XX.png"};
}
