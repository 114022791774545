import isEmpty from 'lodash/isEmpty';
// custom
const getKey = function (obj) {

  // is empty
  if (isEmpty(obj) ){
    return '';
  }

  /*
  * 1 градус по экватору равен примерно 111 км.
  * 3 знака = 0,110метров * 1,41 ~= 150метров
  **/
  return obj.adults
     + '_' + obj.children.length
     + '_' + parseFloat(obj.lat).toFixed(3)
     + '_' + parseFloat(obj.lon).toFixed(3)
     + '_' + obj.start_date + ' '+ obj.end_date
    // + '_' + obj.currency
    // + '_' + obj.language;
};

/////////////////////////////////
const getStoreStructure = function () {
  return {
    loadStatus: "CREATED", // LOAD, SUCCESS, ERROR, CREATED???, ABORT???
    request   : null, // TODO: use request
    data      : null
  };
};

import Vue from 'vue';
import sendRequest from "@/function/global/sendRequest";

import prepare from "@/function/hotel/hotelPrepare";

let hotelListMergeId = {};
let hotelListMerge = [];


export default  {
  state: {
    hotelList: {},
  },
  getters: {
    hotelList_getLoadStatus(state) {
      return function(search){
        let value = state['hotelList'][  getKey(search.request) ];
        if(!value) {
          value = getStoreStructure(search);
        }
        return value.loadStatus;
      };
    },
    hotelList_getData(state) {
      return function(search){
        let value = state['hotelList'][  getKey(search.request) ];
        if(!value) {
          value = getStoreStructure(search);
        }
        return value.data;
      };
    },
  },
  mutations: {
    hotelList_setLoadStatus (state, {key, loadStatus}) {
      state['hotelList'][ key ].loadStatus = loadStatus;
    },
    hotelList_setData (state, {key, data}) {
      if(!state['hotelList'][ key ].data ){
        state['hotelList'][ key ].data       = data;
      } else {
        state['hotelList'][ key ].data = state['hotelList'][ key ].data.concat(data)
      }
    }
  },
  actions: {
    hotelList_clearAll({ state, getters, commit, dispatch }, search) {
      hotelListMergeId  = {};
      hotelListMerge    = [];
      state.hotelList   = {};
    },
    hotelList_abort({ state, getters, commit, dispatch }, search) {
      // TODO
    },
    /*
    search : {
      request: {
        lat         : 1,
        lon         : 1,
        adults      : 1,
        start_date  : "2018-07-22",
        end_date    : "2018-07-24",
        children    : []
      }
    }
    **/
    hotelList_load({ state, getters, commit, dispatch }, search) {

      return new Promise((resolve, reject) => {

        let key = getKey(search.request);

        // TODO: fix
        // if(state['hotelList'][ key ].loadStatus === 'LOAD'){
        //   return;
        // }

        Vue.set(state['hotelList'], key, getStoreStructure());

        commit('hotelList_setLoadStatus', {key: key, loadStatus: 'LOAD'});

        sendRequest('WhiteLabel::HotelListRateHawkMinimal2Get', search.request)
          .then((data) => {

            for(let i = 0; i< data.hotel_list.length; i++){
              data.hotel_list[i].provider = 'ratehawk';
            }
            let hotelList = prepare(data.hotel_list, search.prepare);

            commit('hotelList_setData'      , {key: key, data: hotelList});
            commit('hotelList_setLoadStatus', {key: key, loadStatus: 'SUCCESS'});

            resolve(hotelList);
          })
          .catch(e => {
            commit('hotelList_setData'      , {key: key, data: null});
            commit('hotelList_setLoadStatus', {key: key, loadStatus: 'ERROR'});
            console.warn("ERROR", e);
            reject();
          });
      })

    }
  }
}
