
global.share = {
    copyShareLink(eventId, eventType) {
        let link = window.origin + '/share/'+ eventType + '/' + eventId

        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(link)
                .then(() => VueApp.$notify({
                    title: 'Ссылка скопирована',
                }))
        } else {
            console.debug(link)
            VueApp.$notify({
                type: 'error',
                title: 'Ошибка при копировании ссылки',
                text: 'Возможно подключение не защищено'
            })
        }
    }
}