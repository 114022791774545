export default function (pub, priv) {
  return {
    GOOGLE_MAP_KEY: pub.GOOGLE_MAP_KEY,

    security: priv.SECURITY,

    version: pub.VERSION,
    serverType: pub.SERVER_TYPE,

    url:{
      apiServerBase: pub.DOMAIN_API,
      widgetEditServerBase: pub.DOMAIN_WIDGET_EDIT,
    },

    script: {
      googleAnalytics : pub.SCRIPT_GOOGLE_ANALYTICS,
      gTag            : pub.SCRIPT_GTAG,
      yandexMetrika   : pub.SCRIPT_YANDEX_METRIKA,
      intercom        : pub.SCRIPT_INTERCOM,
    },
    language: {
      localTranslate: pub.LANGUAGE_LOCAL_TRANSLATE,
    },
    labelSettings: {
      ru: {
        callToActionsIcon: priv.CALL_TO_ACTIONS_ICON,
        label: priv.LABEL_RU,
        callToActionsDescription: priv.CALL_TO_ACTIONS_DESCRIPTION_RU,
        appStoreLink: priv.APP_STORE_LINK,
        googlePlayLink: priv.GOOGLE_PLAY_LINK,
        websiteLink: priv.LINK_FOR_WEBSITE,
        shareLogo: priv.SHARE_LOGO_RU
      },
      en: {
        callToActionsIcon: priv.CALL_TO_ACTIONS_ICON,
        label: priv.LABEL_EN,
        callToActionsDescription: priv.CALL_TO_ACTIONS_DESCRIPTION_EN,
        appStoreLink: priv.APP_STORE_LINK,
        googlePlayLink: priv.GOOGLE_PLAY_LINK,
        websiteLink: priv.LINK_FOR_WEBSITE,
        shareLogo: priv.SHARE_LOGO_EN
      },
    }
  }
}
