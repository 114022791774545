import getCurrentCoordinates from "@/function/global/location/getCurrentCoordinates";
import getCityByCoordinates from "@/function/global/location/getCityByCoordinates";
import sendRequest from "@/function/global/sendRequest";
import axios from 'axios';
import City from "@/class/City";

export default  {
  state: {
    location: {
      cancelToken: {
        autoComplete: null,
      },

      city: new City(),
    },
  },

  getters: {
    location(state) {
      return state.location.city.id ? state.location.city : null;
    },
    cancelToken(state) {
      return state.location.cancelToken;
    }
  },

  mutations: {
    location (state, location) {

      localStorage.setItem('location_version'      , 1);
      localStorage.setItem('location_id'           , location.id           );
      localStorage.setItem('location_name'         , location.name         );
      localStorage.setItem('location_country_code' , location.country_code );
      localStorage.setItem('location_population'   , location.population   );
      localStorage.setItem('location_lat'          , location.lat          );
      localStorage.setItem('location_lon'          , location.lon          );

      state.location.city = location;
    },
    cancelToken(state, { type, token }) {
      state.location.cancelToken[type] = token;
    }
  },

  actions: {
    async initLocation({ commit }) {

      let locationVersion = localStorage.getItem('location_version');
      if(locationVersion){
        commit('location', new City({
          id            : localStorage.getItem('location_id')           || -100,
          name          : localStorage.getItem('location_name')         || '',
          country_code  : localStorage.getItem('location_country_code') || '',
          population    : localStorage.getItem('location_population')   || 0,
          lat           : localStorage.getItem('location_lat')          || 0,
          lon           : localStorage.getItem('location_lon')          || 0,
        }));
        return;
      }

      const coordinates = await getCurrentCoordinates();

      if (!coordinates) {
        return;
      }
      const location = await getCityByCoordinates(coordinates);

      if (!location) {
        return;
      }

      commit('location', new City(location));
    },

    async locationAutoComplete({ getters, commit }, searchText) {
      const token = getters.cancelToken.autoComplete;
      if (token) {
        token.cancel();
      }

      const newCancelToken = axios.CancelToken.source();
      commit('cancelToken', { type: 'autoComplete', token: newCancelToken });

      const result = await sendRequest('LocationAutoCompleteGet', { search: searchText,  cancelToken: newCancelToken.token })

      if (!result || !result.search) {
        return;
      }

      return result.search;
    }
  }
}
