
import _FlightRating from './flightRating';
import getAirlineInfo from './getAirlineInfo';


var flightPrepareHelper = {

  getSegmentMinBaggage: (_f, baggageType) => {
    let minPice   = _f[0][baggageType].piece;
    let minWeight = _f[0][baggageType].weight;

    for (var i = 1; i < _f.length; i++) {
      if(minPice   > _f[i][baggageType].piece){ minPice   = _f[i][baggageType].piece; }
      if(minWeight > _f[i][baggageType].piece){ minWeight = _f[i][baggageType].weight; }
    }

    return {
      piece: minPice,
      weight: minWeight
    }
  },
  minBaggage: (b1, b2) => {
    return {
      piece : (b1.piece > b2.piece)   ? b1.piece  : b2.piece,
      weight: (b1.weight > b2.weight) ? b1.weight : b2.weight,
    }
  },
  // layout - обрабатываем пересадки (все данные которые необходимо получить)
  getSegmentMaxLayoutTime: (_s) => {
    let time = 0;
    for (let i = 1; i < _s.length; i++) {
      let timeTemp = _s[i].dTime - _s[i-1].aTime;
      if(timeTemp > time){
        time = timeTemp;
      }
    }
    return time;
  },
  getSegmentTotalLayoutTime: (_s) => {
    let time = 0;
    for (let i = 1; i < _s.length; i++) {
      time += (_s[i].dTime - _s[i-1].aTime);
    }
    return time;
  },
  getSegmentDurationTime: (_s) => {
    let time = 0;
    for (let i = 0; i < _s.length; i++) {
      time += _s[i].duration;
    }
    time += _s.allLayoutTime;
    return time;
  },
  // Является ли перелет с ночной пересадкой
  segmentIsNightFlight: (_s) => {
    let nightFlight = false;

    // берем дату перелета и время 22:00
    var departure_date = _s[0].dTimeMoment.clone();
    departure_date.set({'hour': 22, 'minute': 0, 'second': '0'})
    // определяем в timestamp промежуток 22:00-06:00 (8 часов)
    var nightStart = departure_date.valueOf()/1000;
    var nightEnd  = nightStart + 8 * 60 * 60;

    for (var i = 0; i < _s.length; i++) {
      // проверяем 3 возможных сценария если время пересадки больше 3 часов
      if(i > 0 && (_s[i].dTime - _s[i-1].aTime > 1080) ){
        // прилет в ночь
        if( nightStart <  _s[i-1].aTime &&  _s[i-1].aTime < nightEnd) {
          nightFlight = true;
          break;
        }
        // вылет в ночь
        if( nightStart <  _s[i].dTime &&  _s[i].dTime < nightEnd) {
          nightFlight = true;
          break;
        }
        // прилет перед ночью вылет на следующий день
        if( _s[i-1].aTime < nightStart   &&  nightEnd < _s[i].dTime) {
          nightFlight = true;
          break;
        }
      }
    }

    return nightFlight;
  },

  // charge prepare
  flightSegmentChangeGet: (_s) => {
    var change    = true;
    for (var i = 1; i < _s.length; i++) {
      if(!_s[i].change){ change = false }
    }
    return change
  },

  // Refund prepare
  flightSegmentRefundGet:(_s) => {
    var refund    = true;
    for (var i = 1; i < _s.length; i++) {
      if(!_s[i].refund){ refund = false }
    }
    return refund
  },

};

export default function(flight, supplement){
  // flightOption = {flightCount, travelerCount, nightsInDest, eventDateStart, eventDateEnd, ratingSettingOptimal}
  supplement = supplement || {};
  supplement.ratingSettingOptimal = supplement.ratingSettingOptimal || {};

  var _f = flight;

  // update
  _f.total_flight     = supplement.flightCount;
  _f.travelers_count  = supplement.travelerCount;
  _f.nightsInDest     = supplement.nightsInDest;

  if(_f._prepare){
    return _f;
  } else {
    _f._prepare = true;
  }

  for (let i = 0; i < _f.departure_flight.length; i++) {
    _f.departure_flight[i].airlineObj = getAirlineInfo(_f.departure_flight[i].airline);
    _f.departure_flight[i].dTimeMoment = moment(_f.departure_flight[i].dTime * 1000).utc();
    _f.departure_flight[i].aTimeMoment = moment(_f.departure_flight[i].aTime * 1000).utc();
  }
  for (let i = 0; i < _f.return_flight.length; i++) {
    _f.return_flight[i].airlineObj = getAirlineInfo(_f.return_flight[i].airline);
    _f.return_flight[i].dTimeMoment = moment(_f.return_flight[i].dTime * 1000).utc();
    _f.return_flight[i].aTimeMoment = moment(_f.return_flight[i].aTime * 1000).utc();
  }

  _f.flyFrom  = _f.departure_flight[0].flyFrom;
  _f.flyTo    = _f.departure_flight[(_f.departure_flight.length - 1)].flyFrom;
  _f.cityFrom = _f.departure_flight[0].cityFrom;
  _f.cityTo   = _f.departure_flight[(_f.departure_flight.length - 1)].cityTo;

  // количество секунд от начала дня
  _f.departure_flight.dTimeSecondSinceDay  = _f.departure_flight[0].dTime % 86400;
  _f.return_flight.dTimeSecondSinceDay     = _f.return_flight[0].dTime % 86400;

  // ==================================================================================================================
  // обновленный вариант структуры данных

  // максимальное время одной пересадки
  _f.departure_flight.maxLayoutTime = flightPrepareHelper.getSegmentMaxLayoutTime(_f.departure_flight);
  _f.return_flight.maxLayoutTime    = flightPrepareHelper.getSegmentMaxLayoutTime(_f.return_flight);
  _f.maxLayoutTime                  = _f.departure_flight.maxLayoutTime > _f.return_flight.maxLayoutTime ? _f.departure_flight.maxLayoutTime : _f.return_flight.maxLayoutTime;

  // суммарное время одной пересадки
  _f.departure_flight.allLayoutTime = flightPrepareHelper.getSegmentTotalLayoutTime(_f.departure_flight);
  _f.return_flight.allLayoutTime    = flightPrepareHelper.getSegmentTotalLayoutTime(_f.return_flight);
  _f.allLayoutTime                  = _f.departure_flight.allLayoutTime + _f.return_flight.allLayoutTime;

  // время полета ( зависит от allLayoutTime = getSegmentTotalLayoutTime() )
  _f.departure_flight.durationTime  = flightPrepareHelper.getSegmentDurationTime(_f.departure_flight);
  _f.return_flight.durationTime     = flightPrepareHelper.getSegmentDurationTime(_f.return_flight);
  _f.durationTime                   = _f.departure_flight.durationTime + _f.return_flight.durationTime;

  // определяем ночной перелет.
  _f.departure_flight.nightFlight   = flightPrepareHelper.segmentIsNightFlight(_f.departure_flight);
  _f.return_flight.nightFlight      = flightPrepareHelper.segmentIsNightFlight(_f.return_flight);
  _f.nightFlight                    = _f.departure_flight.nightFlight || _f.return_flight.nightFlight;

  // багаж
  _f.departure_flight.baggage      = flightPrepareHelper.getSegmentMinBaggage(_f.departure_flight, 'baggage');
  _f.return_flight.baggage         = flightPrepareHelper.getSegmentMinBaggage(_f.return_flight, 'baggage');
  _f.baggage                       = flightPrepareHelper.minBaggage(_f.departure_flight.baggage, _f.return_flight.baggage);

  _f.departure_flight.cbaggage     = flightPrepareHelper.getSegmentMinBaggage(_f.departure_flight, 'cbaggage');
  _f.return_flight.cbaggage        = flightPrepareHelper.getSegmentMinBaggage(_f.return_flight, 'cbaggage');
  // fix cabin baggage!!!
  _f.departure_flight.cbaggage.piece     = _f.departure_flight.cbaggage.piece > 1 ? _f.departure_flight.cbaggage.piece : 1;
  _f.return_flight.cbaggage.piece        = _f.return_flight.cbaggage.piece    > 1 ? _f.return_flight.cbaggage.piece    : 1;
  // end fix
  _f.cbaggage                      = flightPrepareHelper.minBaggage(_f.departure_flight.cbaggage, _f.return_flight.cbaggage);

  // fix Personal baggage
  _f.departure_flight.pbaggage     = true;
  _f.return_flight.pbaggage        = true;
  _f.pbaggage                      = true;

  //
  _f.departure_flight.change     = flightPrepareHelper.flightSegmentChangeGet(_f.departure_flight);
  _f.return_flight.change        = flightPrepareHelper.flightSegmentChangeGet(_f.departure_flight);
  _f.change                      = _f.departure_flight.change && _f.return_flight.change;

  //
  _f.departure_flight.refund     = flightPrepareHelper.flightSegmentRefundGet(_f.departure_flight);
  _f.return_flight.refund        = flightPrepareHelper.flightSegmentRefundGet(_f.departure_flight);
  _f.refund                      = _f.departure_flight.refund && _f.return_flight.refund;

  // пересадки
  _f.transplantCount = {
    all           : _f.departure_flight.length + _f.return_flight.length, // сумма всех пересадок
    maxInOneTrip  : _f.departure_flight.length > _f.return_flight.length ?  _f.departure_flight.length :  _f.return_flight.length, // максимально число пересадок на одном из направлений
    departure     : _f.departure_flight.length,
    return        : _f.return_flight.length,
  };
  // ==================================================================================================================

  /////////////////////////
  // поиск перелетов оптимальных по дате прилета за день до начала мероприятия
  _f.optimalDateArrival = false;
  var _eventDateStart = supplement.eventDateStart ? supplement.eventDateStart.format('YYYYMMDD') : false;
  var flightData = _f.departure_flight[_f.departure_flight.length-1].aTime;
  flightData = moment(flightData*1000).utc().format('YYYYMMDD')
  if(!_eventDateStart || flightData < _eventDateStart) {
    _f.optimalDateArrival = true;
  }

  // поиск перелетов оптимальных по дате улета после окончания мероприятия
  // _f.optimalDateReturn = false;
  // var _eventDateEnd = eventDateEnd ? eventDateEnd.format('YYYYMMDD') : false;
  // var flightData = _f.return_flight[0].dTime;
  // flightData = moment(flightData*1000).utc().format('YYYYMMDD')
  // if(!_eventDateEnd || _eventDateEnd >= flightData) {
  //   _f.optimalDateReturn = true;
  // }
  _f.optimalDateReturn = true;
  /////////////////////////

  let airCompany = {};
  let airCompanyCount = 0;
  for (let i = 0; i < _f.departure_flight.length; i++) {
    let s = _f.departure_flight[i];
    if(airCompany[s.airline]){ continue; }
    airCompany[s.airline] = true;
    airCompanyCount++;
  }
  for (let i = 0; i < _f.return_flight.length; i++) {
    let s = _f.return_flight[i];
    if(airCompany[s.airline]){ continue; }
    airCompany[s.airline] = true;
    airCompanyCount++;
  }
  _f.airlineCount = airCompanyCount;

  _f._rating = {
    economy   : _FlightRating.economy(_f),
    // optimal   : _FlightRating.optimal(_f, supplement.ratingSettingOptimal),
    optimal   : _FlightRating.optimal(_f, {
      comfortDepartureTime: {start: 36000, end: 66600},
      comfortReturnTime: {start: 36000, end: 70200},
    }),
    /*
    *
     :


__proto__: Object
comfortReturnTime:
start:
end:
    *
    * */

    superior  : _FlightRating.superior(_f)
  }

  return _f;
}
