
/**
 *
 * ============================= Правило от 2019-12-18
 * Самый дешевый
 * До 1 пересадки.
 *
 * Предлагать 2-е пересадки когда других рейсов нет
 *
 * удобство/цена
 * Добавлять пересадку при разнице в цене более $100
 *
 * Без "тяжелых перелетов" длительные ночные пересадки более 3-х часов в период 22:00-06:00
 */

export default function (flight) {

  let comfort = 100000;

  let priceFakeEUR = flight.price.EUR;

  // количество пересадок туда
  switch (true) {
    case flight.transplantCount.departure <= 1: priceFakeEUR +=   0; /*comfort *=   21;*/ break;
    case flight.transplantCount.departure <= 2: priceFakeEUR += 100; /*comfort *=   21;*/ break;
    case flight.transplantCount.departure <= 3: priceFakeEUR += 200; /*comfort *=    8;*/ break;
    case flight.transplantCount.departure <= 4: priceFakeEUR += 300; /*comfort *=    3;*/ break;
    default:                                    priceFakeEUR += 400; /*comfort *=    1;*/ break;
  }

  // количество пересадок обратно
  switch (true) {
    case flight.transplantCount.return <= 1: priceFakeEUR +=   0; /*comfort *=   21;*/ break;
    case flight.transplantCount.return <= 2: priceFakeEUR += 100; /*comfort *=   21;*/ break;
    case flight.transplantCount.return <= 3: priceFakeEUR += 200; /*comfort *=    8;*/ break;
    case flight.transplantCount.return <= 4: priceFakeEUR += 300; /*comfort *=    3;*/ break;
    default:                                 priceFakeEUR += 400; /*comfort *=    1;*/ break;
  }


  // поиск перелетов оптимальных по дате прилета за день до начала мероприятия
  switch (true) {
    case flight.optimalDateArrival: comfort *=   2; break;
    default:                        comfort *=   0.000001; break;
  }
  // поиск перелетов оптимальных по дате улета за день до после окончания мероприятия
  switch (true) {
    case flight.optimalDateReturn: comfort *=   2; break;
    default:                        comfort *=   0.000001; break;
  }

  let comfortPerMoney = comfort / priceFakeEUR;

  return parseFloat(comfortPerMoney);
}
