
export default {
  state: {
    common:{
      region  : 'EN',
      language: window.siteSetting.language,
      currency: window.siteSetting.currency,
      // datetime: '',
      distance: 'MI',
      weight  : 'lb', // фунты
    }
  },
  getters: {
    getRegion: function(state) {
      return state.common.region;
    },
    getLanguage: function(state) {
      return state.common.language;
    },
    getCurrency: function(state) {
      return state.common.currency;
    },
    getDistance: function(state) {
      return state.common.distance;
    },
    getWeight: function(state) {
      return state.common.weight;
    },

  },
  actions: {
    setRegion: function({state}, region) {
      state.common.region = region;
    },
    setLanguage: function({state}, language) {
      state.common.language = language;
    },
    setCurrency: function({state}, currency) {
      state.common.currency = currency;
    },
    setDistance: function({state}, distance) {
      state.common.distance = distance;
    },
    setWeight: function({state}, weight) {
      state.common.weight = weight;
    },
  }
}
