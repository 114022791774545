
import flightPrepareItem from './flightPrepareItem';

export default function(flightList, supplement){

  for(var i = 0; i < flightList.length; i++) {
    flightPrepareItem(flightList[i], supplement);
  }

  return flightList;
}
