import currencyMap from './currency.map'
import isEmpty from 'lodash/isEmpty';

function round(value, decimals) {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}

function thousandsSeparator(mixedVar, separator, after_length) {
  let result;
  if (separator == null) { separator = ' '; }
  if (after_length == null) { after_length = 4; }
  if (typeof mixedVar === 'string') { mixedVar = mixedVar.replace(/ /g, ''); }
  const precision = Math.round((parseFloat(mixedVar) - parseInt(mixedVar)) * 100);
  const intVar = parseInt(mixedVar);
  mixedVar = String(intVar);

  if (mixedVar.length < after_length) {
    result = mixedVar;
  } else {
    result = mixedVar.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1' + separator);
  }

  if (precision > 0) {
    return result + '.' + (precision < 10 ? `0${precision}` : precision);
  } else {
    return result;
  }
}

function sum() {
  if (arguments.length === 0) {
    return;
  }

  let sum = {};

  for (let i = 0; i < arguments.length; i += 1) {
    const price = arguments[i];

    if (!price) {
      continue;
    }

    for (let j in price) {
      sum[j] = (sum[j] || 0) + price[j];
    }
  }
  return sum;
}

function diff(price1, price2) {
  if (!price1 || !price2) {
    return;
  }

  let diff = {};

  for (let i in price1) {
    if (!price2[i]) {
      break;
    }
    diff[i] = price1[i] - price2[i];
  }

  return diff;
}

function view(price, currencyType = 'USD') {

  if (isEmpty(price) || !currencyType) {
    return;
  }
  let priceOfCurrency = price[currencyType];
  let currency        = currencyType;

  if(!priceOfCurrency && price['USD']){
    priceOfCurrency = price['USD'];
    currency = 'USD';
  }
  if(!priceOfCurrency && price['EUR']){
    priceOfCurrency = price['EUR'];
    currency = 'EUR';
  }

  return Currency.format(priceOfCurrency, currency);
}

function isEnable(price) {
  if (!price || isEmpty(price)) {
    return false;
  }
  // if (!price[state.currency] || price[state.currency] === 0) {
  //   return false;
  // }
  return true;
}

export default () => {

  const getName = () => {
    /* eslint-disable*/
    return VueStore.getters.getCurrency;
    /* eslint-enable */
  };

  const setName = (name) => {
    // TODO: validate
    /* eslint-disable*/
    localStorage.setItem('currency', name);

    // Почему сеттер валюты занимается очищением каких-то хотел-листов???
    VueStore.dispatch('hotelList_clearAll', name);

    return VueStore.dispatch('setCurrency', name);
    /* eslint-enable */
  };

  const getSymbolByName = (currencyName) => {
    return currencyMap[currencyName] ? currencyMap[currencyName].symbol : '';
  };

  const getCurrentSymbol = () => {
    return getSymbolByName( getName() );
  };

  return {
    getSymbolByName : getSymbolByName,
    getSymbol       : getCurrentSymbol,
    getName         : getName,

    //
    sum,
    diff,
    view,
    isEnable,
    //

    getCurrencyList: () => {
      return Object.keys(currencyMap);
    },

    format: (price, currency = getName()) => {
      const roundPrice = round(price, 2);
      const sign = currencyMap[currency].symbol;

      const format = currencyMap[currency].format;
      const defaultFormat = () => `${sign}  ${roundPrice}`;

      return format ? format(price) : defaultFormat(price);
    },

    set: setName
    // set: function (currencyName) {
    //   currencyName = currencyName.toUpperCase();
    //
    //   if (!currencyMap[currencyName]) {
    //     // console.error("Currency is not found", currencyName);
    //     return;
    //   }
    //   _currency.name = currencyName;
    // }
  }
}
