/**
 * 1- эконом- самый дешевый вариант(хостел), с рейтингом 6+, в радиусе 2-5 км / перелет самый дешевый до 2-х пересадок
 *
 * радиус до 5км - 1000, радиус до 10км - 500, до 20км - 250, более 0.
 * рейтинг 6+ => 1000, 4-6 => 500, 2-4 => 250, 0-2 => 0
 *
 *
 */
export default function (hotel) {

  var comfort = 1000;

  // расстояние до мероприятия
  switch (true) {
    case hotel.distanceToEvent < 5000:   comfort *=   16; break;
    case hotel.distanceToEvent < 12000:  comfort *=    4; break;
    case hotel.distanceToEvent < 20000:  comfort *=    1; break;
    default:                             comfort *= 0.01; break;
  }
  //
  // рейтинг отеля
  switch (true) {
    case hotel.rating > 6: comfort *=1.3; break;
    case hotel.rating > 4: comfort *=1.2; break;
    case hotel.rating > 2: comfort *=1.1; break;
    case hotel.rating > 1: comfort *=  1; break;
    default:               comfort *=  1; break;
  }

  var comfortPerMoney = comfort / hotel.minHotelRoomPrice;

  return parseInt(comfortPerMoney);
}