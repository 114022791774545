export default function (weightName) {

  var _weightInfo = {
    KG: {
      name: 'kilogram',
      symbol: "kg",
      course: 1,
    },
    LB: { // фунт
      name: 'pound',
      symbol: "lb",
      course: 2.2046,
    }
  };

  var _weightEnum = {KG: 'KG', LB: 'LB'};
  // for(var key in _weightInfo){
  //   _weightEnum[key] = key;
  // }

  var _weight = {
    name: weightName
  };

  return {
    Enum: _weightEnum,
    convert: function (weight, weightUnit, newUnit) {
      return weight / _weightInfo[weightUnit].course * _weightInfo[newUnit].course
    },
    set: function(weightName){
      _weight.name = weightName;
    }
  }
}