import axios from 'axios'

let request = null
export default function sendRequest(method, data) {

  const prepareUrl = function (method, data) {
    return CONST.url.apiServerBase + '/rpc?' + method;
  };

  const prepareRequestType = function (method, data) {
    return 'POST';
  };

  const prepareData = function (method, data) {

    data.method = method;
    const formData = new FormData;
    formData.append('data', JSON.stringify(data));
    return formData;
  };

  const send = function(method, data){

    let requestType = prepareRequestType(method, data);
    let requestUrl  = prepareUrl(method, data);
    let requestData = prepareData(method, data);
    let axiosSource = null;

    if (method === 'EventsGetByLatLon2') {
      if (request) {
        request.cancel();
        request.msg = 'Cancelled';
        request = null;
      }

      axiosSource = axios.CancelToken.source();
      request = { cancel: axiosSource.cancel, msg: "Loading..." };
    }

    // TODO: Продумать функцию прерывания
    return new Promise((resolve, reject) => {
      axios({
        url: requestUrl,
        data: requestData,
        method: requestType,
        cancelToken: axiosSource ? axiosSource.token : null
      })
        .then((response) => {
          if (request) {
            request.msg = 'Success';
            request = null;
          }

          if (response.data.error !== "ERROR_NOT") {
            reject(response.data.error);
            return;
          }
          resolve(response.data);
        })
        .catch((err) => reject(err));


      // Content-Type 'multipart/form-data'
      // 'Content-Disposition: form-data; name="data"\r\n\r\n' + prepareData(method, data) + '\r\n'
      // axios
      //   .get('https://api.coindesk.com/v1/bpi/currentprice.json')
      //   .then((response) => {
      //     let responseData  = {};
      //
      //     let doWhile = false;
      //     do{
      //       try {
      //         // responseData = JSON.parse(response); // for json text
      //         responseData = response.data;
      //       } catch (error) {
      //         responseData = {error: 'ERROR_XHR_NOT_CORRECT_RESPONSE_JSON', debug: response};
      //         break;
      //       }
      //
      //       if(!responseData.error ){
      //         responseData = {error: 'ERROR_XHR_DATA_NOT_ERROR_CODE', debug: responseData};
      //         break;
      //       }
      //
      //       break;
      //     } while(doWhile);
      //
      //     if(responseData.error === 'ERROR_NOT'){
      //       resolve(responseData);
      //     } else {
      //       reject(responseData);
      //     }
      //   });
    })

  };

  return send(method, data);
}
