import Vue from 'vue';
import Vuex from 'vuex';

import common     from '@/core/store/common/common';
import grid       from '@/core/store/common/grid';
import location   from '@/core/store/common/location';

import eventWidget from "@/core/store/eventWidget";
import event      from '@/core/store/event';
import flightList from '@/core/store/flightList';
import hotelList  from '@/core/store/hotelList';
import hotelListBooking  from '@/core/store/hotelListBooking';
import widgetSettings  from '@/core/store/widgetSettings';
import weather  from '@/core/store/weather';
import venueList  from '@/core/store/venueList.js';
import bottomSheet from "@/core/store/bottomSheet";

// add for test
// import testExample from '@/core/store/example/testExample';

Vue.use(Vuex);

global.VueStore = new Vuex.Store({
  modules: {
    // system
    common,
    grid,
    location,

    // cache store
    eventWidget,
    event,
    flightList,
    hotelList,
    hotelListBooking,
    widgetSettings,
    weather,
    venueList,
    bottomSheet,

    // temp
    // testExample
  },
})

export default global.VueStore
