<script>
  export default {
    name: 'layout-grid',
    data: function () {
      return { grid_width : 0 };
    },
    computed:{
      getGridClass(){
        let size    = 'grid-sm';
        let padding = 'none';
        let display = 'sm';

        // min-width: 410px padding 10
        // min-width: 450px padding 15

        // min-width: 810px padding 10
        // min-width: 930px padding 15

        // min-width: 1200px padding 10
        // min-width: 1380px padding 15

        if(this.grid_width > 1380) {
          size    = 'grid-lg';
          padding = 'md';
          display = 'lg';
        } else if(this.grid_width > 1200) {
          size    = 'grid-lg';
          padding = 'sm';
          display = 'lg';
        } else if(this.grid_width > 930) {
          size    = 'grid-md';
          padding = 'md';
          display = 'md';
        } else if(this.grid_width > 810) {
          size    = 'grid-md';
          padding = 'sm';
          display = 'md';
        } else if(this.grid_width > 450) {
          size    = 'grid-sm';
          padding = 'md';
        } else if(this.grid_width > 410) {
          size    = 'grid-sm';
          padding = 'sm';
        }

        this.$store.dispatch('grid_updateDisplay', display);
        return size + ' grid-padding-' + padding;
      },
    },
    methods:{
      updateGridWidth() {
        this.grid_width = window.innerWidth;
      },
    },
    created() {
      this.updateGridWidth()
      window.addEventListener('resize', this.updateGridWidth);
    },
  };
</script>