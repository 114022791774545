

import Vue from 'vue';
import sendRequest from "@/function/global/sendRequest";

import eventLoadByData  from "@/function/event/load/eventLoadByData";
import eventLoadById    from "@/function/event/load/eventLoadById";

export default  {
  state: {
    eventWidget: {
      loadStatus: "CREATED", // LOAD, SUCCESS, ERROR, CREATED???, ABORT???
      request   : null, // TODO: use request
      data      : null
    },
  },
  getters: {
    eventWidget_getLoadStatus(state) {
      return state.eventWidget.loadStatus;
    },
    eventWidget_getData(state) {
      return state.eventWidget.data;
    },
  },
  mutations: {
    eventWidget_setLoadStatus (state, loadStatus) {
      state.eventWidget.loadStatus = loadStatus;
    },
    eventWidget_setData (state, data) {
      state.eventWidget.data       = data;
    },
    eventWidget_setRequest (state, request) {
      state.eventWidget.request       = request;
    },
  },
  actions: {
    eventWidget_load({ state, getters, commit, dispatch }, search) {
      if(window.eventWidgetPromise && !search?.request?.reload){
        return window.eventWidgetPromise;
      }

      commit('eventWidget_setRequest', search.request)
      commit('eventWidget_setLoadStatus', 'CREATED');

      window.eventWidgetPromise = new Promise((resolve, reject) => {
        // fix re-download
        if(state.eventWidget.loadStatus === 'SUCCESS'){
          resolve(state.eventWidget.data);
          return;
        }

        commit('eventWidget_setLoadStatus', 'LOAD');

        const thenEvent = (event) => {
          commit('eventWidget_setData'      , event);
          commit('eventWidget_setLoadStatus', 'SUCCESS');
          resolve(event);
        };
        const catchEvent = (error) => {
          commit('eventWidget_setData'      , null);
          commit('eventWidget_setLoadStatus', 'ERROR');
          console.warn("ERROR", error);
          reject();
        };

        if(search.loadType === 'eventByData'){
          eventLoadByData(search.request).then(thenEvent).catch(catchEvent);
        } else
        if(search.loadType === 'eventById'){
          if(!search.request.offerId && search.request.id){
            search.request.offerId = search.request.id;
          }
          eventLoadById(search.request).then(thenEvent).catch(catchEvent);
        } else {
          console.debug('event widget store - not correct search.type', search);
          reject();
        }

        return window.eventWidgetPromise;

      })

    }
  }
}
