
import sendRequest from "@/function/global/sendRequest";
import prepare from "@/function/event/eventPrepare";

export default (request) => {

  return new Promise((resolve, reject) => {

    sendRequest('OfferGet', request)
      .then((data) => {
        let event = prepare(data);

        request.hotels    && event.setHotels(request.hotels);
        request.poi       && event.setPoi(request.poi);
        request.icon      && event.setIcon(request.icon);

        resolve(event);
      })
      .catch(e => {
        reject();
        console.warn("ERROR", e);
      });
  });

}

