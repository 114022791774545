/**
 * оптимальный- отель от 3-х звезд, с рейтингом 7+, в радиусе 2 км./ перелет самый дешевый до 1-й пересадки
 */
export default function (hotel) {

  var comfort = 1000;

  // не хостел
  if(hotel._isHostel){comfort *= 0.1;}

  // количество звезд
  switch (true) {
    case hotel.stars  == 5: comfort *=    4; break;
    case hotel.stars  >= 3: comfort *=   16; break;
    case hotel.stars === 2: comfort *=    4; break;
    case hotel.stars === 1: comfort *=    1; break;
    default:                comfort *=    1; break;
  }

  // расстояние до мероприятия
  switch (true) {
    case hotel.distanceToEvent < 2500:   comfort *=    4; break;
    case hotel.distanceToEvent < 5000:   comfort *=    2; break;
    case hotel.distanceToEvent < 12000:  comfort *=    1; break;
    case hotel.distanceToEvent < 20000:  comfort *=  0.5; break;
    default:                             comfort *= 0.01; break;
  }
  //
  // рейтинг отеля
  switch (true) {
    case hotel.rating > 7: comfort *= 16; break;
    case hotel.rating > 6: comfort *=  8; break;
    case hotel.rating > 4: comfort *=  4; break;
    case hotel.rating > 2: comfort *=  2; break;
    case hotel.rating > 1: comfort *=  1; break;
    default:               comfort *=  1; break;
  }

  var $price = hotel.minHotelRoomBreakfastPrice;
  // fix отелей без завтрака
  if(!hotel.roomBreakfastInclude){
    comfort *= 0.0001;
    $price = hotel.minHotelRoomPrice;
  }
  var comfortPerMoney = comfort / $price;

  return parseInt(comfortPerMoney);
}
