module.exports = function () {

  return {
    VERSION : '2.0.0',
    SERVER_TYPE: ENUM.SERVER_TYPE.LOCAL,

    DOMAIN_API: 'https://funtam.wr-tech.online',

    GOOGLE_MAP_KEY: 'AIzaSyAJob5VMQPqtrxigTcEpHUDAj8wL02rBZQ',


    DEFAULT_LANGUAGE: ENUM.LANGUAGE.EN,
    DEFAULT_REGION  : ENUM.REGION.EN,

    SCRIPT_GOOGLE_ANALYTICS : true,
    SCRIPT_GTAG             : true,
    SCRIPT_YANDEX_METRIKA   : true,
    SCRIPT_INTERCOM         : false,

    LANGUAGE_LOCAL_TRANSLATE: true,
  }
}