
// global class unit
import UnitCurrency from '@/function/global/unit/currency.js';
import UnitDistance from '@/function/global/unit/distance.js';
import UnitWeight from '@/function/global/unit/weight.js';
import UnitLanguage from '@/function/global/unit/language.js';
// global class locale
// import Region   from '@/function/global/locale/region.js';


global.Currency = new UnitCurrency('USD');
global.Distance = new UnitDistance('MI');
global.Weight = new UnitWeight('LB');

global.Language = new UnitLanguage();

// import UnitDateTime from '@/function/global/unit/datetime.js';
// import UnitDistance from '@/function/global/unit/distance.js';

// global static class;
// global.Region = new Region('us');
