import sendRequest from "@/function/global/sendRequest";

export default  {
  state: {
    weather: [],
  },
  getters: {
    weather(state) {
      return state.weather;
    },
  },
  mutations: {
    weather(state, weather) {
      if (!weather || weather.legth === 0) {
        return;
      }
      state.weather = [];

      for (let item of weather) {
        const dateMoment = moment(item.date);
        state.weather.push({ ...item, dateMoment });
      }
    }
  },
  actions: {
    async getWeather({ commit }, { latitude, longitude, startDate, endDate }) {

      return sendRequest('Weather', {
        method: 'Weather',
        latitude,
        longitude,
        startDate,
        endDate,
      })
        .then((response) => {
          commit('weather', response.data);
          return response;
        })
        .catch(e => {
          console.warn("ERROR", e);
        });
    },
  }
}
