const attach = (Vue) => {
    for (let key in directives) {
        Vue.directive(key, directives[key])
    }
}

const directives = {
    'set-free-color': {
        bind(el, isFree) {
            if (!isFree.value) return
            el.style.color = 'var(--color-partner-identity)'
        },
        update(el, binding) {
            binding.def.bind(el, binding)
        }
    }
}

export default attach