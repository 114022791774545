import axios from 'axios';

const getCurrentCoordinates = () => {
  if (!CONST || !CONST['GOOGLE_MAP_KEY']) {
    throw(new Error('undefined GOOGLE_MAP_KEY'));
  }

  const url = 'https://www.googleapis.com/geolocation/v1/geolocate?key=' + CONST['GOOGLE_MAP_KEY'] + '&language=en';

  return new Promise((resolve, reject) => {
    axios({
      url,
      method: 'POST',
      data: {
        considerIp: true
      },
    })
      .then((response) => {
        // TODO: add check on accuracy
        const coordinates = {
          latitude: response.data.location.lat,
          longitude: response.data.location.lng,
        }
        resolve(coordinates);
      })
      .catch((err) => {
        reject(err);
        throw(new Error('getCurrentCoordinates', err));
      });
  });
};

export default getCurrentCoordinates;
