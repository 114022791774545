
import sendRequest from "@/function/global/sendRequest";
import prepare from "@/function/hotel/hotelPrepare";

export default  {
  state: {
    hotelListBooking: {
      loadStatus: "CREATED", // LOAD, SUCCESS, ERROR, CREATED???, ABORT???
      request   : null, // TODO: use request
      data      : [],
    },
  },
  getters: {
    hotelListBooking_getLoadStatus(state) {
      return () => state.hotelListBooking.loadStatus;
    },
    hotelListBooking_getData(state) {
      return () => state.hotelListBooking.data;
    },
  },
  mutations: {
    hotelListBooking_setLoadStatus (state, loadStatus) {
      state.hotelListBooking.loadStatus = loadStatus;
    },
    hotelListBooking_setData (state, data) {
      state.hotelListBooking.data       = data;
    }
  },
  actions: {
    hotelListBooking_load({ state, getters, commit, dispatch }, search) {

      commit('hotelListBooking_setLoadStatus', 'LOAD');

      return new Promise((resolve, reject) => {

        sendRequest('HotelListBooking', search.request)
          .then((data) => {

            for(let i = 0; i< data.hotel_list.length; i++){
              data.hotel_list[i].provider = 'booking';
              // data.hotel_list[i].id = 'booking_fix_id__' + i;
              // data.hotel_list[i].kind = 'hotel';

              // if(data.hotel_list[i].images.length === 0){}
              data.hotel_list[i].images = [{
                url: '#'
              }];
            }

            let hotelListBooking = prepare(data.hotel_list, search.prepare);

            commit('hotelListBooking_setData'      , hotelListBooking);
            commit('hotelListBooking_setLoadStatus', 'SUCCESS');
          })
          .catch(e => {
            // commit('hotelListBooking_setData'      , []);
            // commit('hotelListBooking_setLoadStatus', 'ERROR');
            console.warn("ERROR", e);
          });


      })

    }
  }
}
