<script>

  export default {
    components: {
    },
    props: {},
    data() {
      return {
        eventRequestData: {},
      }
    },
    methods: {
      eventLoad(){
        return this.$store.dispatch('eventWidget_load', this.eventRequestData);
      }
    },
    computed: {
      eventStatus() {
        return this.$store.getters.eventWidget_getLoadStatus;
      },
      eventData() {
        return this.$store.getters.eventWidget_getData;
      },
    },
  };

</script>
