module.exports = function () {
  return {
    SECURITY: '12345678',

    CLIENT: 'FunTam',

    LABEL_EN: 'FunTum!',
    LABEL_RU: 'ФанТам!',

    LINK_FOR_WEBSITE: 'https://funtam.ru/',
    CALL_TO_ACTIONS_ICON: 'funTam', // funTam || false
    CALL_TO_ACTIONS_DESCRIPTION_EN: 'Interesting places and events on the map',
    CALL_TO_ACTIONS_DESCRIPTION_RU: 'Интересные места и события на карте',

    APP_STORE_LINK: 'https://apps.apple.com/us/app/%D1%84%D0%B0%D0%BD%D1%82%D0%B0%D0%BC/id6443924698', // false || link:String
    GOOGLE_PLAY_LINK: 'https://play.google.com/store/apps/details?id=com.funtam.android', // false || link:String

    SHARE_LOGO_RU: false, // false (FunTamLogo) || link:String
    SHARE_LOGO_EN: false, // false (FunTamLogo) || link:String
  }
}