export default {
    actions: {
        openBottomSheet(ctx, content) {
            ctx.commit('updateBottomSheetContent', content)
        },
        closeBottomSheet(ctx) {
            ctx.commit('updateBottomSheetContent', '')
        }
    },
    mutations: {
        updateBottomSheetContent(state, content) {
            state.bottomSheetContent = content
        }
    },
    state: {
        bottomSheetContent: ''
    },
    getters: {
        bottomSheetContent(state) {
            return state.bottomSheetContent
        }
    }
}