import languageList from './language.map';

export default (languageName) => {

  const getLanguage = () => {
    /* eslint-disable*/
    return VueStore.getters.getLanguage;
    /* eslint-enable */
  };
  const setLanguage = (name) => {
    // TODO: validate
    /* eslint-disable*/
    localStorage.setItem('language', name);
    return VueStore.dispatch('setLanguage', name);
    /* eslint-enable */
  };

  // init
  // setLanguage(languageName);

  return {
    getName: getLanguage,
    getLanguageList: () => languageList,
    set: setLanguage,
  };
}
