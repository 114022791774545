import routerEventWidgetInit    from "@/core/router/hook/routerEventWidgetInit";
import routerWidgetSettingsInit from "@/core/router/hook/routerWidgetSettingsInit";

// routerInit
export default (router) => {


  const routerFirstInitFunction = (route) => {
    routerEventWidgetInit(route);
    routerWidgetSettingsInit(route)
  };

  let routerFirstInit = false;
  router.beforeEach((to, from, next) => {
    if(!routerFirstInit){

      router.firstQuery = to.query;
      // ROUTER to
      routerFirstInitFunction(to)
    }
    routerFirstInit = true;
    next();
  });
}

