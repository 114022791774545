export default {
  "ar": "Arabic",
  "bg": "Bulgarian",
  "ca": "Catalan",
  "cs": "Czech",
  "da": "Danish",
  "de": "Deutsch",
  "el": "Greek",
  "en": "English",
  // "en-gb": "",
  // "en-us": "",
  "es": "Español",
  // "es-ar": "",
  "et": "Estonian",
  "fi": "Finnish",
  "fr": "Français",
  "he": "Hebrew",
  "hr": "Croatian",
  "hu": "Hungarian",
  // "id": "",
  "is": "Icelandic",
  "it": "Italiano",
  "ja": "日本語",
  "ko": "Korean",
  "lt": "Lithuanian",
  "lv": "Latvian",
  "ms": "Malay",
  "nl": "Dutch",
  "no": "Norwegian",
  "pl": "Polskie",
  "pt": "Português",
  // "pt-br": "",
  // "pt-pt": "",
  "ro": "Romanian",
  "ru": "Russian",
  "sk": "Slovak",
  "sl": "Slovenian",
  "sr": "Serbian",
  "sv": "Swedish",
  "th": "Thai",
  // "tl": "",
  "tr": "Turkish",
  "uk": "Ukrainian",
  "vi": "Vietnamese",
  "zh": "中文 (简体)",
  // "zh-cn": "",
  "zh-tw": "中文 (繁體)",  
}
  