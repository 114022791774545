import getwidgetSettingsDefault from './widgetSettingsDefault';
// import {isEmpty, has, isArray, isPlainObject} from 'lodash';
import has from 'lodash/has';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';

export default  {
  state: {
    widgetSettings: getwidgetSettingsDefault(),
  },
  getters: {
    widgetSetting(state) {
      return (name) => state.widgetSettings[name] ? state.widgetSettings[name].value : undefined;
    },
    widgetSettings(state) {
      return Object.entries(state.widgetSettings).reduce((acc, [name, item]) => {
        acc[name] = item.value;
        return acc;
      }, {});
    },
    widgetSettingsDeep(state) {
      return state.widgetSettings;
    },
    objectValueMapping(state) {
      return (object) => {
        let result = {},
            stateValue

        for (let key in object) {
          stateValue = state.widgetSettings[object[key]] && state.widgetSettings[object[key]].value

          if (stateValue)
            result[key] = stateValue
        }

        return  result;
      }
    }
  },
  mutations: {
    widgetSetting(state, [name, setting]) {
      if (!has(state.widgetSettings, name)) {
        console.debug('Undefined widget setting', name, state);
        return;
      }

      // Если категория не указывается, то значение категории в стэйте не меняется.
      // Категори могут задаваться массивом [cat1, catN] или строкой 'cat1', которая будет приведена к массиву.
      if (has(setting, 'categories')) {
        state.widgetSettings[name].categories = isArray(setting.categories) ? setting.categories : [setting.categories];
      }

      // Настройки могут задаваться в упрощенном виде: widgetSettings: { settingName: settingValue, ... }
      // В таком случае перед записью в стор они будут приводиться к стандартному виду widgetSettings: { settingName: { categories: [], value: settingValue } ... }
      if (!isPlainObject(setting)) {
        state.widgetSettings[name].value = setting;
        return;
      }

      state.widgetSettings[name].value = has(setting, 'value') ? setting.value : undefined;
    }
  },
  actions: {
    setWidgetSetting({ commit }, setting) {
      commit('widgetSetting', setting);
    },

    setWidgetSettings({ dispatch }, settings) {
      Object.entries(settings).map((setting) => dispatch('setWidgetSetting', setting));
    },
  }
};
