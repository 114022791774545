import Event from "@/class/Event";
import calculateDistance from "@/function/hotel/calculateDistance";

export default function(event, supplement){

  let _e = event;

  // organizer info
  let eventProvider = 'none';
  let organizerName         = '';
  let organizerDescription  = '';
  let organizerUrl          = '';
  let organizerButtonText   = 'see tickets';
  if(_e._event){
    if (_e._event.provider === '[]') {
      organizerName         = _e.organizer_name         || '';
      organizerDescription  = _e.organizer_description  || '';
      organizerUrl          = _e.organizer_url          || _e.url || '';

      if (_e.segment === 'Sports') {
        organizerButtonText = 'registration';
      }
    }

    if (_e._event.provider === 'ticketmaster') {
      organizerName        = 'ticketmaster';
      organizerDescription = 'https://www.ticketmaster.com/about/about-us.html';
      organizerUrl         = _e.url || '';
    }
  }

  // eventDate
  let eventDateTimeFix = function (datetime, timezone) {
    ///# // moment.tz(cache.event.start_datetime, cache.event.seance_timezone);
    ///# var tt = moment.tz(datetime, timezone);
    ///# var o = tt.utcOffset();
    ///# return moment(datetime).add(o, 'm');
    return moment(datetime).add(-480, 'm');
  };

  if (_e.start_datetime > _e.end_datetime) {
    _e.end_datetime = _e.start_datetime;
  }

  // TODO: delete (use UTC)
  let eventDateStart = eventDateTimeFix(_e.start_datetime, _e.seance_timezone || '');
  let eventDateEnd   = eventDateTimeFix(_e.end_datetime  , _e.seance_timezone || '');

  let diff = eventDateStart.diff(moment(), "days");
  if (diff < 1){
    eventDateStart = moment().add(30, 'days')
    eventDateEnd   = eventDateStart.clone().add(1, 'days')
  }


  // окно поездки
  // const tripDateStart = (d => d ? moment(d) : '')(_e.setting.travel_departure_date) || eventDateStart.clone().add(-1, 'days');
  // TODO: поправить значения, если eventDateStart устарело (eventDateStart = +30day), но есть настройки
  // const tripDateStart = _e.setting.travel_departure_date ? moment(_e.setting.travel_departure_date) : eventDateStart.clone().add(-1, 'days');
  // const tripDateEnd   = _e.setting.travel_return_date ? moment(_e.setting.travel_return_date) : eventDateEnd.clone().add(1, 'days');
  const tripDateStart = eventDateStart.clone().add(-1, 'days');
  const tripDateEnd   = eventDateEnd.clone().add(1, 'days');

  // цена
  let eventPrice = _e.min_price ? _e.min_price : {};

  // event setting
  let eventSetting = _e.setting || {};

  eventSetting.flight = _e.setting.flight || {};
  eventSetting.flight.recommended = _e.setting.flight.recommended || [];

  eventSetting.hotel = _e.setting.hotel || {};
  eventSetting.hotel.recommended = _e.setting.hotel.recommended || [];

  const newEventStructure = {
    id      : _e.id,
    name    :  _e.name || _e.event_name || '',
    previewUrl : _e.image_url,

    provider        : eventProvider,
    callToAction    : _e.call_to_action || '',
    information     : _e.information || '',
    notice          : _e.notice || '',
    recommendation  : _e.recommendation || "offer:Go to the website of the organizer to buy a ticket to the event. If you have the ticket, proceed to booking your trip",

    dateStart : eventDateStart,
    dateEnd   : eventDateEnd,

    tripDateStart : tripDateStart,
    tripDateEnd   : tripDateEnd,

    venue: {
      name    : _e.venue_name,
      address : _e.venue_address,
      lat     : _e.venue_latitude,
      lon     : _e.venue_longitude,

      city    : _e.eventCity,
    },
    organizer: {
      name        : organizerName,
      description : organizerDescription,
      url         : organizerUrl,
      buttonText  : organizerButtonText
    },
    ticket: [
      {
        amount: eventPrice
      }
    ],
    setting: eventSetting,

  };

  return new Event(newEventStructure);
}
