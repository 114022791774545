import i18next from 'i18next'
import Locize from 'i18next-locize-backend'
// import locizeEditor from 'locize-editor'
import intervalPlural from 'i18next-intervalplural-postprocessor';

let namespace = require('./namespace');

i18next.use(Locize)
  // .use(locizeEditor)
  .use(intervalPlural).init({
  ns: namespace,
  defaultNS: 'common',
  debug: false,
  keySeparator: false,
  fallbackLng: Language.getName(),
  saveMissing: true,
  backend: {
    projectId: 'ab8a3dba-1f51-4c45-a93d-68c10c1e91b5',
    apiKey: 'c96c8287-9f0b-4b5c-9226-8aa551dd036d',
    referenceLng: 'en'
  }
});

export default i18next;
