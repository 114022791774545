import City from "@/class/City";

export default class Event {

  constructor(event) {
    let defaultEvent = this._getDefaultEventStructure();
    for(let key in defaultEvent) {
      this[key] = defaultEvent[key];
    }

    // если есть обьект эвента, то инициализируем только сущестующие поля
    if(event){
      for(let key in defaultEvent) {
        // if(event.hasOwnProperty(key)){
        if( Object.getOwnPropertyDescriptor(event, key) ){
          this[key] = event[key];
        }
      }
    }

  }

  // base
  setName(name){ this.name = name; }

  // venue
  setVenueAddress(venueAddress){ this.venue.address = venueAddress; }
  setVenueLat(venueLat){ this.venue.lat = venueLat; }
  setVenueLon(venueLon){ this.venue.lon = venueLon; }

  // settings
  setHotels(hotels) { this.hotels = hotels; }
  setPoi(poi)       { this.poi = poi; }
  setIcon(icon)     { this.icon = icon; }
  setClearHotelFilter(clearHotelFilter) {this.clearHotelFilter = clearHotelFilter}


  setDateStart(dateStart){
    let diff = dateStart.diff(moment(), "days");
    if (diff < 1){
      console.warn('Class Event, method setDateStart. date start not set')
      return;
    }

    this.dateStart      = dateStart;
    this.tripDateStart  = dateStart.clone().add(-1, 'days');

    // TODO: fix date end
    this.dateEnd        = this.dateStart.clone();
    this.tripDateEnd    = this.dateEnd.clone().add(1, 'days');
  }

  setDateEnd(dateEnd){
    let diff = dateEnd.diff(moment(), "days");
    if (diff < 1){
      console.warn('Class Event, method setDateEnd. date end not set')
      return;
    }
    let diff2 = this.dateStart.diff(this.dateEnd, "days");
    if (diff2 < 0){
      console.warn('Class Event, method setDateEnd. date end not set')
      return;
    }

    this.dateEnd        = dateEnd;
    this.tripDateEnd    = this.dateEnd.clone().add(1, 'days');
  }

  _getDefaultEventStructure(){

    let dateStart     = moment().add(30, 'days')
    let dateEnd       = dateStart.clone()
    let tripDateStart = dateStart.clone().add(-1, 'days');
    let tripDateEnd   = dateEnd.clone().add(1, 'days');

    return {
      id          : -100,
      name        :  '',
      previewUrl  : '',
      icon        : '/resource/img/event/event-default-icon.svg', // TODO: set default '/resource/img/map/event/iconSF.svg'

      provider        : 'user',
      callToAction    : '',
      information     : '',
      notice          : '',
      recommendation  : "offer:Go to the website of the organizer to buy a ticket to the event. If you have the ticket, proceed to booking your trip",

      dateStart : dateStart,
      dateEnd   : dateEnd,

      tripDateStart : tripDateStart,
      tripDateEnd   : tripDateEnd,
      clearHotelFilter : false,

      venue: {
        name    : '',
        address : '',
        lat     : 0,
        lon     : 0,
        city    : new City({
          id: -100,
          name: '',
          country_code: '',
          population: 0,
          lat: 0,
          lon: 0,
        }),
      },
      organizer: {
        name        : '',
        description : '',
        url         : '',
        buttonText  : ''
      },
      ticket: [],
      setting: {},

      hotels: [],
      poi: [],
    }
  }

  setCity(city) {
    this.venue.city = new City({
      id            : city.id,
      name          : city.name,
      country_code  : city.country_code,
      population    : city.population,
      lat           : city.lat,
      lon           : city.lon,
    });
  }

}
