export default [

  {
    path: '/',
    name: 'offer_mapPoi12122',
    component: () => import('@/page/logic/offerPoi.vue'),
    props: (route) => {}
  },
  {
    path: '/offer/poi',
    name: 'offer_mapPoi',
    component: () => import('@/page/logic/offerPoi.vue'),
    props: (route) => ({
      shareId: route.query.shareId,
      shareType: route.query.shareType,
      showMainEvent: !(route.query.showMainEvent === 'false')
    })
  },
  {
    path: '/share/:type/:id',
    name: 'share',
    component: () => import('@/page/logic/Share.vue'),
    props: (route) => ({id: route.params.id, type: route.params.type})
  },

  {
    path: '/widget-init', // данная страница нужна для iframe, что бы слелать редирект на начальную страницу, согласно настроек
    name: 'system_widgetInit',
    redirect: to => {
      let startPage = to.query.widgetSettings ? JSON.parse(to.query.widgetSettings) : {};
      startPage = startPage.startPage || '';

      switch (startPage) {
        case 'offer_mapPoi'             : break;
        default                         : startPage = 'offer_mapPoi'; break;
      }
      return { name: startPage }
    }
  },
]
